import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { api, createSession } from '../config/api';
import { MenuContext } from './menuContext';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const { setNoNavbar } = useContext(MenuContext);

  useEffect(() => {
    const recoveredUser = sessionStorage.getItem('login');

    if (recoveredUser) {
      setUser(JSON.parse(recoveredUser));
      api.defaults.headers.Authorization = `Bearer ${
        JSON.parse(recoveredUser).bearerToken
      }`;
    }

    setLoading(false);
  }, []);

  const login = async (login, senha) => {
    const response = await createSession(login, senha);

    const loggedUser = response.data;
    sessionStorage.setItem(
      'login',
      JSON.stringify({ ...response.data, role: 'admin' })
    );
    api.defaults.headers.Authorization = `Bearer ${loggedUser.bearerToken}`;

    // Adicione um interceptor para as respostas
    api.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        // Verifique se o erro é relacionado ao token expirado
        if (error.response.status === 403) {
          // Desconecte o usuário ou tome a ação necessária
          logout();
        }
        return Promise.reject(error);
      }
    );
    setUser(loggedUser);
    navigate('/projetos');
    setNoNavbar(false);
  };

  const logout = () => {
    sessionStorage.removeItem('login');
    setUser(null);
    navigate('/login');
  };
  return (
    <AuthContext.Provider
      value={{ authenticated: !!user, user, loading, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};
