import styled from 'styled-components';

export const Container = styled.main`
  height: 100vh;

  main {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 75vh;
  }

  form {
    padding-top: 30px;

    width: 600px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .box {
      display: flex;
      justify-content: space-around;

      width: 550px;
      margin-bottom: 30px;
    }

    .box-bottom {
      width: 87%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      padding-top: 60px;
      margin: 15px;
    }
  }
`;
