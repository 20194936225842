import { Box } from '@material-ui/core';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { Skeleton } from '@mui/material';
import { EmptyData } from 'components/EmptyData';
import { PackageDetails } from 'components/PackageDetails';
import api from 'config/api';
import { MenuContext } from 'context/menuContext';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TimelineCard } from '../../components/TimelineCard';
import { ReactComponent as Logo1 } from './DocAssinado2.svg';
import { ReactComponent as Logo2 } from './DocNaoAssinado2.svg';
import {
  CardsContainer,
  DetailsContainer,
  SkeletonsContainerLeft,
  SkeletonsContainerRight,
  TimelineContainer
} from './styles';

export const Timeline = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  // const [testeList, setTesteList] = useState([]);
  const [project, setProject] = useState({
    id: 0,
    dateCad: '',
    deliveryDate: '',
    description: '',
    endDate: '',
    idContributorCad: 0,
    sankhyaId: '',
    startDate: '',
    externalCode: '',
    dateAlt: '',
    idContributorAlt: 0,
    proposaledited: false,
    proposalvalue: '',
    support: false,
    taxespercent: '',
    clientId: 0,
    contractStatus: 0
  });
  const { packageId, projectId } = useParams();
  const navigate = useNavigate();
  const skeletons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const { setNoFooter, loadingContext } = useContext(MenuContext);
  const size = 10;
  const noPackages = !data.length;

  const getDeliveries = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get(
        `/r2d2Entregas/getAllEntregasTimelineByProjectIdPage?projectId=${projectId}&size=${size}`
      );
      setData(response.data);
      if (parseInt(packageId) === 0) {
        navigate(`/timeline/${projectId}/${response.data[0].r2enCdId}`);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [projectId, packageId, navigate, loadingContext]);

  const findPackageById = useCallback(async () => {
    try {
      const response = await api.get(`/contracts/findByProjectId/${projectId}`);
      setProject(response.data);
    } catch (error) {}
  }, [projectId]);

  // const getListaFileNuvem = useCallback(async () => {
  //   setLoading(true);
  //   try {
  //     const response = await apiGCP.get(`gcp/listFiles?bucketName=neki-cloud&directory=r2d2-api/`);
  //     setTesteList(response.data);
  //   } catch (error) {
  //   } finally {
  //     setLoading(false);
  //   }
  // }, [loadingContext]);

  useEffect(() => {
    setNoFooter(true);
    getDeliveries();
    findPackageById();
    // getListaFileNuvem();
  }, [setNoFooter, getDeliveries, findPackageById]);

  const [opcaoSelecionada, setOpcaoSelecionada] = useState(null);
  const isUltimaOpcaoSelecionada = opcaoSelecionada === null;

  const handleClickOpcao = opcaoId => {
    setOpcaoSelecionada(opcaoId);
  };

  const getBackgroundStyleWith = (
    isParam,
    isUltima,
    isUltimaOpcaoSelecionada,
    isSelecionada
  ) => {
    switch (true) {
      case isParam:
        return { backgroundColor: '#2A8594', color: 'white' };
      case isUltima && isUltimaOpcaoSelecionada:
        return { backgroundColor: '#2A8594', color: 'white' };
      case isSelecionada:
        return { backgroundColor: '#2A8594', color: 'white' };
      default:
        return '';
    }
  };

  const getBorderStyleWith = (term, search, dateDelivery) => {
    const dataAtual = new Date();
    const anoAtual = dataAtual.getFullYear();
    const mesAtual = String(dataAtual.getMonth() + 1).padStart(2, '0');
    const diaAtual = String(dataAtual.getDate()).padStart(2, '0');
    const formattedDate = `${anoAtual}-${mesAtual}-${diaAtual}`;
    switch (true) {
      case term === true &&
        search === 'preenchido' &&
        formattedDate > dateDelivery:
        return '#ccc';
      case formattedDate <= dateDelivery:
        return '#ED6C02';
      case term === false:
        return '#ba2222';
      // return '#FF5E5E';
      // case term === true && search === 'preenchido':
      //   return '#2A8594';
      default:
        return '#CCC';
    }
  };

  function getIconSearchSatisfaction(check, value, isParam, isSelecionada) {
    switch (true) {
      case value === 1 && value <= 1.4:
        return (
          <p title={('Média: ', 'Ruim')}>
            <SentimentVeryDissatisfiedIcon
              color={isParam || isSelecionada ? '' : 'error'}
              style={{ width: '25px', height: '25px' }}
            />
          </p>
        );
      case value >= 1.5 && value < 2.4:
        return (
          <p title={('Média: ', 'Regular')}>
            <SentimentDissatisfiedIcon
              color={isParam || isSelecionada ? '' : 'warning'}
              style={{ width: '25px', height: '25px' }}
            />
          </p>
        );
      case value >= 2.5 && value < 3.4:
        return (
          <p title={('Média: ', 'Bom')}>
            <SentimentSatisfiedIcon
              style={{
                width: '25px',
                height: '25px',
                color: isParam || isSelecionada ? '' : '#faaf00'
              }}
            />
          </p>
        );
      case value >= 3.5 && value < 4.4:
        return (
          <p title={('Média: ', 'Ótimo')}>
            <SentimentSatisfiedAltIcon
              color={isParam || isSelecionada ? '' : 'primary'}
              style={{ width: '25px', height: '25px' }}
            />
          </p>
        );
      case value >= 4.5:
        return (
          <p title={('Média: ', 'Excelente')}>
            <SentimentVerySatisfiedIcon
              color={isParam || isSelecionada ? '' : 'success'}
              style={{ width: '25px', height: '25px' }}
            />
          </p>
        );
      default:
        return (
          <p title={('Média: ', 'Excelente')}>
            <SentimentVerySatisfiedIcon
              style={{ width: '25px', height: '25px', color: 'transparent' }}
            />
          </p>
        );
    }
  }

  function getIconAcceptanceTerm(term, isParam, isSelecionada) {
    if (term === true) {
      return (
        <p title="Termo aceito">
          <Logo2
            style={{ fill: isParam || isSelecionada ? 'white' : '#2e7d32' }}
          />
        </p>
      );
    } else if (term === false) {
      return (
        <p title="Termo não aceito">
          <Logo1 style={{ fill: isParam || isSelecionada ? 'white' : 'red' }} />
        </p>
      );
    } else {
      return null;
    }
  }

  return (
    <div style={{ width: '100%', backgroundColor: '#FFF' }}>
      {loading ? (
        <div style={{ display: 'flex', padding: '30px 40px 30px 40px' }}>
          <SkeletonsContainerLeft>
            {skeletons.map((s, i) => (
              <div key={i} className="skeletons">
                <Skeleton
                  className="skeleton-card"
                  variant="rect"
                  width={30}
                  height={10}
                />
                <Skeleton
                  className="skeleton-card"
                  variant="rect"
                  width={10}
                  height={120}
                />
                <Skeleton
                  className="skeleton-card"
                  variant="rect"
                  width={300}
                  height={120}
                />
              </div>
            ))}
          </SkeletonsContainerLeft>

          <SkeletonsContainerRight>
            <div className="arrows-wrapper">
              <Skeleton variant="circular" width={30} height={30} />
              <Skeleton variant="circular" width={30} height={30} />
            </div>

            <Box sx={{ display: 'flex' }}>
              <Skeleton className="skeleton-section" width={150} height={60} />
              <Skeleton className="skeleton-section" width={150} height={60} />
              <Skeleton className="skeleton-section" width={150} height={60} />
            </Box>

            <Box style={{ display: 'flex', margin: '40px 0px' }}>
              <Box style={{ width: '100%' }}>
                <Skeleton
                  className="skeleton-section"
                  width={150}
                  height={60}
                />
                <Skeleton
                  className="skeleton-section"
                  width={'90%'}
                  height={40}
                />
                <Skeleton
                  className="skeleton-section"
                  width={'90%'}
                  height={40}
                />
                <Skeleton
                  className="skeleton-section"
                  width={'90%'}
                  height={40}
                />
                <Skeleton
                  className="skeleton-section"
                  width={'90%'}
                  height={40}
                />
                <Skeleton
                  className="skeleton-section"
                  width={'30%'}
                  height={40}
                />
              </Box>

              <Box>
                <Skeleton
                  variant="rect"
                  className="skeleton-section"
                  width={400}
                  height={300}
                />
              </Box>
            </Box>

            <Box sx={{ display: 'flex' }}>
              <Skeleton className="skeleton-button" width={150} height={60} />
              <Skeleton className="skeleton-button" width={150} height={60} />
              <Skeleton className="skeleton-button" width={150} height={60} />
            </Box>

            <Box sx={{ display: 'flex', marginTop: 80 }}>
              <Skeleton
                variant="circular"
                className="skeleton-button"
                width={40}
                height={40}
              />
              <Skeleton
                variant="rect"
                className="skeleton-button"
                width={'100%'}
                height={100}
              />
            </Box>
          </SkeletonsContainerRight>
        </div>
      ) : (
        <TimelineContainer>
          <CardsContainer>
            {data.map((each, i) => {
              const isParam = each.r2enCdId === parseInt(packageId);
              const isSelecionada = each.r2enCdId === opcaoSelecionada;
              return (
                <div key={i}>
                  <TimelineCard
                    index={i}
                    id={each.r2enCdId}
                    title={each.r2enTxVersaoEntrega}
                    description={each.r2enTxTituloEntrega}
                    date={each.r2enDtEfetivaEntrega}
                    workerName={each.workerName}
                    // borderRadius={isFirst && '10px 10px 0 0'}
                    // borderRadius={(isFirst && '10px 10px 0 0') || (isLast && '0 0 10px 10px ')}
                    onClick={() => {
                      handleClickOpcao(each.r2enCdId);
                      navigate(`/timeline/${projectId}/${each.r2enCdId}`);
                    }}
                    style={{
                      position: 'relative',
                      backgroundColor: getBackgroundStyleWith(
                        isParam,
                        isUltimaOpcaoSelecionada,
                        isSelecionada
                      ).backgroundColor,
                      color: getBackgroundStyleWith(
                        isParam,
                        isUltimaOpcaoSelecionada,
                        isSelecionada
                      ).color,
                      border: `1px solid ${getBorderStyleWith(
                        each.termoAssinado,
                        each.pesquisaPreenchida,
                        each.r2enDtEfetivaEntrega
                      )}`
                    }}
                    typeIconSearch={getIconSearchSatisfaction(
                      each.pesquisaPreenchida,
                      each.mediaSatisfacao,
                      isParam,
                      isSelecionada
                    )}
                    typeIconTerm={getIconAcceptanceTerm(
                      each.termoAssinado,
                      isParam,
                      isSelecionada
                    )}
                  />
                </div>
              );
            })}
            <TimelineCard
              title="Início"
              description="Início do projeto."
              date={project.dateCad}
              borderRadius={noPackages ? '10px' : '0 0 10px 10px '}
            />
          </CardsContainer>
          <DetailsContainer>
            {noPackages ? (
              <EmptyData
                message="Ainda não há pacotes para o contrato selecionado."
                visible
              />
            ) : (
              <PackageDetails array={data} getDeliveries={getDeliveries} />
            )}
          </DetailsContainer>
        </TimelineContainer>
      )}
    </div>
  );
};
