import React from 'react';
import NoContract from '../../assets/image/cubo.png';
import { EmptyContainer } from './styles';

export const EmptyData = ({ message, visible }) => {
  return (
    <EmptyContainer visible={visible}>
      <img src={NoContract} alt="icone de cubo" id="cubo" />
      <p className="text-alert">{message}</p>
    </EmptyContainer>
  );
};
