import React from 'react';
import * as C from './styles';
import Neki from '../../assets/logo.svg';

export const ContainerBox = ({ onSubmit, title, children }) => {
  return (
    <C.Container>
      <div id="box-container">
        <C.Aside>
          <div>
            <h2>R2D2</h2>
            <p>Fique por dentro das entregas do seus projetos.</p>
          </div>
          <div className="position">
            <img src={Neki} alt="neki" className="neki" />
          </div>
        </C.Aside>
        <C.Form onSubmit={onSubmit}>
          <h2>{title}</h2>
          {children}
        </C.Form>
      </div>
    </C.Container>
  );
};
