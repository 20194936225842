import React from 'react';
import { FaFile } from 'react-icons/fa';
import AndroidIcon from '@mui/icons-material/Android';
import {
  AiFillFileExcel,
  AiFillFileImage,
  AiFillFilePdf,
  AiFillFileWord
} from 'react-icons/ai';
import { ImSvg } from 'react-icons/im';

export function returnIcons(format) {
  switch (format) {
    case 'docx':
      return <AiFillFileWord />;
    case 'apk':
      return <AndroidIcon />;
    case 'pdf':
      return <AiFillFilePdf />;
    case 'xls':
      return <AiFillFileExcel />;
    case 'jpeg':
    case 'jpg':
    case 'psd':
    case 'png':
    case 'gif':
    case 'ico':
    case 'ai':
      return <AiFillFileImage />;
    case 'svg':
      return <ImSvg />;
    default:
      return <FaFile />;
  }
}

export const returnDefaultImage = (image, format) => {
  switch (format) {
    case 'jpeg':
    case 'jpg':
    case 'png':
    case 'gif':
    case 'ico':
      return { img: image, render: true };
    default:
      return { img: 'https://i.imgur.com/VmKzR6T.png', render: true };
  }
};

export const sortByFormat = (a, b) => {
  switch (a.format || b.format) {
    case 'jpeg':
    case 'jpg':
    case 'png':
    case 'gif':
    case 'ico':
      return -1;
    default:
      return 1;
  }
};
