import styled from 'styled-components';

export const HeaderContainer = styled.header`
  position: sticky;
  top: 0px;
  z-index: 5;
  .itemsHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* padding: 10px; */
  }
  .items-menu {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 250px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    transition: 0.3s;
    z-index: 10;
    .menu-icon {
      border: none;
      background: none;
      cursor: pointer;
    }
    .items-container-menu {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
      margin-right: 15px;
    }
    .menu-option {
      list-style: none;
      padding-left: 25px;
      margin-top: 30px;
    }
    .option {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }
    .icone-menu-option {
      margin-right: 10px;
    }
    .option-item {
      display: inline-block;
      font-size: 18px;
      font-family: Montserrat;
    }
  }
  .items-container-oculto {
    width: 0;
    height: 0;
  }
  .iconHeader {
    width: 93px;
    height: 28px;
  }

  .title-logo {
    color: #eeeeee;
    font-size: 18px;
    font-weight: 500;
    font-family: Montserrat;
  }
  .menuContainer {
    display: flex;
    align-items: center;
    margin-left: 25px;
  }

  #botaoMenu {
    font-size: 16px;
    margin-right: 25px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
  }
  .menu-secundario {
    /* width: 244px;
    height: 23px; */
    background: red;
  }
  .item {
    color: #fff;
  }
`;

export const Img = styled.img`
  width: 35px;
  height: 35px;
  /* margin-right: 5px; */
`;

export const ProfileSection = styled.section`
  display: flex;

  .background-icone {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    width: 32px;
    height: 32px;
    background: #fff;
    margin-right: 10px;
  }

  .IconEmpresa {
    width: 20px;
    height: 23px;
  }
`;
