import styled from 'styled-components';

export const CommentContainer = styled.section`
  #info-box {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    .img-background {
      width: 32px;
      height: 32px;
      background: #eee;
      border-radius: 100%;
      border: 1px solid #515151;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;

      img {
        width: 20px;
        height: 23px;
      }
    }
  }

  #comment {
    padding-left: 33px;

    .text-area {
      width: 100%;
      border-radius: 10px;
      resize: none;
      padding: 10px;
      font-family: Montserrat;
      background: none;
    }
  }
`;

export const Title = styled.p`
  font-size: 16px;
  color: #333333;
  font-weight: bold;
`;

export const Data = styled.p`
  font-size: 10px;
  color: #959595;
`;
