import styled from 'styled-components';

export const ContainerModal = styled.div`
  /* width: 350px;
  height: 300px; */
  padding: 20px;
  border-radius: 10px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Title = styled.h4`
  font-size: 16px;
  color: #4e4e4e;
`;

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;
