import React from 'react';

const DEFAULT_VALUE = {
  open: true,
  setOpen: () => {},
  noFooter: true,
  setNoFooter: () => {},
  noNavbar: true,
  setNoNavbar: () => {}
};

export const MenuContext = React.createContext(DEFAULT_VALUE);
