import { Stack } from '@mui/material';
import { CommentPart } from 'components/CommentPart';
import iconNeki from './LogoNeki2.png';

export const Comments = ({ comments }) => {
  return (
    <div>
      <Stack spacing={4}>
        {comments.map((each, i) => (
          <div key={i}>
            <CommentPart
              name={each.userName}
              date={each.r2coDtComentario}
              // image={each.image}
              image={iconNeki}
              comment={each.r2coTxComentario}
            />
          </div>
        ))}
      </Stack>
    </div>
  );
};
