import styled from 'styled-components';

export const ContainerDescription = styled.div`
  /* border: 2px solid red; */

  .images {
    width: 440px;
    @media (max-width: 1050px) {
      padding: 20px;
    }
  }
`;

export const DateTime = styled.p`
  font-size: 14px;
  width: 300px;
`;

export const Description = styled.p`
  max-height: 200px;
  /* border: 1px solid red; */
  color: #686868;
  overflow: auto;
  font-size: 18px;
  display: inline-block;
`;
