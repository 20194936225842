import React from 'react';
import { FooterContainer } from './styles.js';

export const Footer = ({ backgroundColor, fontColor }) => {
  const date = new Date();
  return (
    <FooterContainer backgroundColor={backgroundColor} fontColor={fontColor}>
      <p id="title-footer">Copyright Neki © {date.getFullYear()}</p>
    </FooterContainer>
  );
};
