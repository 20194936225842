import { format } from 'date-fns';

const months = [
  'Jan',
  'Fev',
  'Mar',
  'Abr',
  'Mai',
  'Jun',
  'Jul',
  'Ago',
  'Set',
  'Out',
  'Nov',
  'Dez'
];

export const formatDate1 = date => {
  if (!date) return '';
  const d = new Date(date);
  return d.getUTCDate() + ' ' + months[d.getUTCMonth()];
};

const option = {
  year: 'numeric',
  month: 'long' || 'short' || 'numeric',
  weekday: 'long' || 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric'
};

// Domingo, 24 de janeiro de 2022
export const formatDate2 = date => {
  const d = new Date(date).toLocaleDateString('pt-br', option);
  const capitalized = d.replace(/^./, d[0].toUpperCase());
  return capitalized;
};

export const formatDate3 = date => {
  if (!date) return '';
  return format(new Date(date), 'dd/MM/yyyy HH:mm');
};

export const formatDate4 = date => {
  if (!date) return '';
  return format(new Date(date), 'dd/MM/yyyy');
};

export const formatDateString = dataString => {
  if (!dataString) return '';
  // Criar um objeto Date a partir da string
  // Dividir a string para obter ano, mês e dia
  var partes = dataString.split('-');
  var ano = partes[0];
  var mes = partes[1];
  var dia = partes[2];

  // Formatar a data no formato "dd/MM/yyyy"
  var dataFormatada = dia + '/' + mes + '/' + ano;
  return dataFormatada;
};

export const formatDateOffsetDateTime = date => {
  if (!date) return '';

  return format(new Date(date * 1000), 'dd/MM/yyyy HH:mm');
};
export function dateHourConverter(date) {
  const dateArr = date.split('-');
  const hourArr = date.split(':');
  const year = dateArr[0];
  const month = parseInt(dateArr[1], 10) - 1;

  const dateAtt = new Date();
  dateAtt.setFullYear(parseInt(year, 10));
  dateAtt.setMonth(month);
  dateAtt.setDate(parseInt(dateArr[2], 10));
  dateAtt.setHours(parseInt(hourArr[0], 10));
  dateAtt.setMinutes(parseInt(dateArr[1], 10));
  dateAtt.setSeconds(0);
  dateAtt.setMilliseconds(0);
  return dateAtt;
}

export function getDate(date) {
  return format(new Date(date), 'yyyy-MM-dd');
}

export function getHour(date) {
  return format(new Date(date), 'HH:mm');
}

export const getDateNow = () => {
  return new Date();
};

export const formatDateOnlyNumbers = date => {
  const d = new Date(date);
  return `${d.getDate()}${d.getMonth()}${d.getFullYear()}`;
};
