import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Breadcrumbs as BreadCrumbsMui } from '@mui/material';
import Link from '@mui/material/Link';

export const Breadcrumbs = ({ pathnames }) => {
  if (!pathnames) {
    return null;
  }
  return (
    <div role="presentation">
      <BreadCrumbsMui aria-label="breadcrumb">
        {pathnames.map((each, index) => {
          const isLast = index === pathnames.length - 1;
          if (isLast) {
            return <Typography color="text.primary">{each.name}</Typography>;
          }
          return (
            <Link underline="hover" color="inherit" href={each.path}>
              {each.name}
            </Link>
          );
        })}
      </BreadCrumbsMui>
    </div>
  );
};
