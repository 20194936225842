import { Switch, TableCell, TableRow, Tooltip } from '@material-ui/core';
import EditIcon from '@mui/icons-material/Edit';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

export const Row = props => {
  const { row } = props;
  const [checked, setChecked] = useState(props.row.ativo);
  const navigate = useNavigate();

  const disableEnable = () => {
    MySwal.fire({
      icon: 'warning',
      title: checked ? 'Desativar liderança?' : 'Ativar liderança?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar'
    }).then(result => {
      if (result.value === true) {
        props.handleChangeStatus(row);
        setChecked(!checked);
      }
    });
  };

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {row.nomeColaborador}
      </TableCell>
      <TableCell align="center">{row.nomeLider}</TableCell>
      <TableCell align="center">{row.tipoLideranca}</TableCell>
      <TableCell align="center">
        {row.observacao ? row.observacao : '-'}
      </TableCell>
      <TableCell align="center">{row.dataInicio}</TableCell>
      <TableCell align="center">{row.dataFim ? row.dataFim : '-'}</TableCell>

      <TableCell>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 10,
            justifyContent: 'end'
          }}
        >
          <Tooltip title="editar liderança" arrow>
            <EditIcon
              style={{
                cursor: 'pointer',
                color: 'white',
                backgroundColor: '#2A8594',
                borderRadius: '50%',
                fontSize: 30,
                padding: 5
              }}
              onClick={() => navigate(`/editLiderTutor/${row.idLiderTutor}`)}
            />
          </Tooltip>

          <Tooltip title={checked ? 'desativar' : 'ativar'} arrow>
            <Switch
              color="primary"
              checked={checked}
              onChange={disableEnable}
            />
          </Tooltip>
        </div>
      </TableCell>
    </TableRow>
  );
};
