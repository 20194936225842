import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Tooltip } from '@mui/material';
import SqlImg from '../Form/SQL.png';
// import SqlImg2 from '../Form/sql2.png';

export function SortableItem(props) {
  const { attributes, listeners, setNodeRef, transform } = useSortable({
    id: props?.id
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    cursor: 'move',
    marginBottom: 10
  };

  return (
    <div style={{ position: 'relative' }}>
      <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
        <div
          style={{
            display: 'flex',
            margin: 5,
            alignItems: 'center',
            backgroundColor: 'white',
            border: '1px solid #ccc',
            padding: 10,
            borderRadius: 5,
            height: 62
          }}
        >
          <div style={{ width: '100%' }}>
            <p style={{ fontWeight: 'bold' }}>
              {props.getFolderNameFromPath(props?.id?.r2aeTxNomeArquivo)}
            </p>
            <p>{props?.id?.r2aeTxDescricao}</p>
          </div>
        </div>
      </div>
      <div>
        {props?.id?.sql === 'on' ? (
          <Tooltip title="SQL">
            {/* <SqlImg style={{ cursor: 'pointer', position: 'absolute', top: 20, right: 75 }} /> */}
            <img
              src={SqlImg}
              style={{
                cursor: 'pointer',
                position: 'absolute',
                top: 20,
                right: 75,
                width: 20
              }}
            />
            {/* <img src={SqlImg2} style={{ cursor: 'pointer', position: 'absolute', top: 20, right: 75 }} /> */}
          </Tooltip>
        ) : null}
        {!props.packageId && (
          <Tooltip title="Editar Descrição">
            <EditIcon
              style={{
                cursor: 'pointer',
                position: 'absolute',
                top: 20,
                right: 45
              }}
              onClick={() => props.handleClickEdit()}
            />
          </Tooltip>
        )}
        <Tooltip title="Deletar">
          <DeleteIcon
            style={{
              cursor: 'pointer',
              position: 'absolute',
              top: 20,
              right: 15
            }}
            onClick={() => props.handleClickDelete()}
          />
        </Tooltip>
      </div>
    </div>
  );
}
