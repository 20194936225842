import styled from 'styled-components';

export const CardContainer = styled.section`
  flex-direction: row;
  display: flex;
  align-items: center;
  /* border: 1px solid blue; */

  .card-date-timiline {
    white-space: nowrap;
    min-width: 65px;
    max-width: 65px;
    /* border: 1px solid purple; */
    color: #24292f;
    font-size: 20px;
  }
  .svg {
    background: #cecece;
    margin: 0px 15px;
    min-width: 10px;
    max-width: 10px;
    height: 100%;
  }
`;
