import styled from 'styled-components';

export const Container = styled.main`
  font-family: 'Montserrat';
  padding: 50px 50px 0 50px;
`;

export const Form = styled.form`
  .card-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    margin-top: 30px;
  }
  .red {
    color: #f5190a;
  }

  .subtitle {
    font-size: 18px;
  }

  .box-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    p {
      font-size: 16px;
      color: #a0a0a0;

      span {
        color: #f5190a;
      }
    }
  }

  #button-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .btn {
      width: 120px;
      background-color: #277d8b;
      margin-left: 15px;
    }
  }
`;

export const Card = styled.section`
  background-color: #f5fbff;
  border-radius: 10px;
  margin-top: 40px;
  padding: 50px;
  margin-bottom: 20px;

  .box-question {
    margin-top: 50px;
    margin-bottom: 50px;

    p {
      font-size: 18px;
      text-align: left;
      font-weight: bold;
    }
  }

  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;

    p {
      font-size: 12px;
    }
  }

  .obs-question {
    margin-top: 50px;
    width: 100%;
    min-height: 100px;
    resize: none;
    background: none;
    padding: 5px;
    font-family: 'Montserrat';
    border-radius: 10px;
  }
`;
