import { Chip, Divider } from '@material-ui/core';
import Download from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import GitHubIcon from '@mui/icons-material/GitHub';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { LoadingButton } from '@mui/lab';
import { IconButton, Paper, Stack, Tooltip } from '@mui/material';
import { AlertsSnackBar } from 'components/AlertsSnackbar/index.js';
import { Comments } from 'components/Comments/index.js';
import { CompanyComment } from 'components/CompanyComment/index.js';
import { FilesModal } from 'components/FilesModal/index.js';
import { FtpModal } from 'components/FtpModal/index.js';
import { FullscreenModal } from 'components/FullscreenModal/index.js';
import { IcModal } from 'components/IcModal/index.js';
import api, { apiGCP } from 'config/api';
import { AuthContext } from 'context/auth.jsx';
import { defaultPackageType } from 'default/packages.js';
import { Container } from 'global/styles.js';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { formatDateString } from 'util/dateFormat.js';
import { ContainerDescription, DateTime, Description } from './styles.js';

import { Skeleton } from '@material-ui/lab';
import CloudDownloadSharpIcon from '@mui/icons-material/CloudDownloadSharp';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Axios from 'axios';
export const TabPackageData = ({ model, data }) => {
  const hasFtp =
    model?.r2enTxFtpUrl || model?.r2enTxFtpLogin || model?.r2enTxFtpSenha;
  const hasIc = model?.r2enTxUrlFonte;
  // const multipleImages = true;
  const [packageType, setPackageType] = useState(defaultPackageType);
  const [comments, setComments] = useState([]);
  const [loadingComments, setLoadingComments] = useState(false);
  const [copy, setCopy] = useState(false);
  // const [actualImg, setActualImage] = useState({ path: '', title: '' });
  const [modalFtp, setModalFtp] = useState(false);
  const [modalFiles, setModalFiles] = useState(false);
  const [modalIc, setModalIc] = useState(false);
  const [size, setSize] = useState(6);
  const [modalFullscreen, setModalFullscreen] = useState(false);
  const { packageId } = useParams();
  const { user } = useContext(AuthContext);
  const isAdmin = user.profile === 'CONTRIBUTOR';
  const cantEdit = model.r2enTxStatus === 'C';
  const navigate = useNavigate();
  const [maxSize, setMaxSize] = useState();
  const noMoreComments =
    !comments.length || maxSize <= comments.length ? true : false;
  const [fileList, setFileList] = useState([]);

  const breakWordStyle = {
    padding: '10px',
    wordWrap: 'break-word',
    wordBreak: 'break-word'
  };

  const isValidUrl = url => {
    return url && (url?.includes('http') || url?.includes('www.'));
  };

  const getComments = useCallback(async () => {
    if (!packageId) return;
    setLoadingComments(true);
    try {
      const response = await api.get(
        `/comentario/getAllComentarioEntregaPage?r2enCdId=${packageId}&size=${size}`
      );
      if (response.data.content) {
        setComments(response.data.content);
        setMaxSize(response.data.totalElements);
      } else {
        setComments([]);
      }
    } catch (error) {
    } finally {
      setLoadingComments(false);
    }
  }, [packageId, size]);

  const getPackageType = useCallback(async () => {
    if (!packageId) return;
    try {
      const response = await api.get(
        `/r2d2TipoEntregas/getTipoByEntrega/${packageId}`
      );
      setPackageType(response.data);
    } catch (error) {}
  }, [packageId]);

  const loadMore = () => {
    setSize(s => s + 4);
  };

  const getFiles = useCallback(() => {
    api
      .get(`/r2d2-arquivo-entrega/todos-arquivo-entrega/${packageId}/${true}`)
      .then(response => {
        setFileList(
          response.data.map(item => ({
            img: item.url,
            title: item.r2aeTxNomeArquivo.split('.')[0],
            format: item.r2aeTxNomeArquivo.split('.')[1]
          }))
        );
      });
  }, [packageId]);

  const downloadFile = async file => {
    try {
      const response = await apiGCP.get(
        `gcp/signedUrlForDownload?objectName=r2d2-api/pacote/${file}`
      );
      const url = response.data; // Assuming the response contains the URL.

      //aciona o dowload
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file);
      link.click();
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  useEffect(() => {
    getPackageType();
  }, [packageId, getPackageType]);

  useEffect(() => {
    getComments();
    getFiles();
  }, [packageId, size, getComments, getFiles]);

  const handleClosed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setCopy(false);
  };

  const getEnvironment = value => {
    switch (value) {
      case 'D':
        return 'Desenvolvimento';
      case 'T':
        return 'Teste';
      case 'H':
        return 'Homologação';
      case 'P':
        return 'Produção';
      default:
        return '';
    }
  };

  // const urlOriginal = model?.r2enTxUrlVideoEvidencia;

  function validateUrl(value) {
    const wordToFind = 'watch?v=';
    const wordToReplace = 'embed/';
    if (value.includes(wordToFind)) {
      const valueAfterValidation = value.replace(wordToFind, wordToReplace);
      return valueAfterValidation;
    }
    return value;
  }

  const [imageUrls, setImageUrls] = useState([]);
  const [loadingImage, setLoadingImage] = useState(true);

  useEffect(() => {
    async function fetchImageUrls() {
      const imageUrls = await Promise.all(
        model?.arquivos?.map(async item => {
          if (isImage(item.r2aeTxNomeArquivo)) {
            try {
              const response = await apiGCP.get(
                `/gcp/signedUrlForDownload?objectName=r2d2-api/pacote/${item.r2aeTxNomeArquivo}`
              );
              const url = await Axios.get(response.data);
              return url?.config?.url;
            } catch (error) {
              //TODO
            } finally {
              setLoadingImage(false);
            }
          }
          setLoadingImage(false);
          return null;
        })
      );
      setImageUrls(imageUrls);
    }
    if (model?.arquivos) {
      fetchImageUrls();
    }
    //
  }, [model]);

  function isImage(fileDescription) {
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.svg', '.avif'];
    const fileExtension = fileDescription.substr(
      fileDescription.lastIndexOf('.')
    );
    return imageExtensions.includes(fileExtension.toLowerCase());
  }

  if (data == null) {
    return (
      <>
        <Skeleton height={60}></Skeleton>
        <Skeleton height={60}></Skeleton>
        <Skeleton height={60}></Skeleton>
      </>
    );
  }
  return (
    <div>
      <Paper style={{ padding: '30px 20px', overflowX: 'auto' }}>
        <FullscreenModal
          open={modalFullscreen}
          onClose={() => setModalFullscreen(false)} /*img={actualImg}*/
        />
        <AlertsSnackBar
          open={copy}
          handleClose={handleClosed}
          tipoDeaviso="success"
          mensagem="Copiado para área de transferencia"
          background="#2e7d32"
        />
        <FilesModal
          list={fileList}
          open={modalFiles}
          onClose={() => setModalFiles(false)}
        />
        <FtpModal
          open={modalFtp}
          onClose={() => setModalFtp(false)}
          url={model.r2enTxFtpUrl}
          login={model.r2enTxFtpLogin}
          password={model.r2enTxFtpSenha}
          setFinished={setCopy}
        />
        <IcModal
          open={modalIc}
          onClose={() => setModalIc(false)}
          url={model.r2enTxUrlFonte}
          setFinished={setCopy}
        />

        <ContainerDescription>
          <Stack
            direction="row"
            spacing={2}
            className="text"
            style={{
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              flexWrap: 'nowrap'
            }}
          >
            <Stack spacing={2} className="text">
              {model.r2enTxUrlPacote ? (
                <Stack spacing={2}>
                  <h1 style={{ display: 'flex' }}>
                    {data?.projeto}{' '}
                    {isAdmin ? (
                      <Container justify="flex-end">
                        <Tooltip title="Editar pacote">
                          <IconButton
                            disabled={cantEdit}
                            onClick={() => navigate(`/pacote/${packageId}`)}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </Container>
                    ) : null}
                  </h1>
                  <h2>
                    {data?.contrato} ({data?.cliente})
                  </h2>
                  <h3>URL GitHub</h3>
                  <Chip
                    component={'a'}
                    label={model.r2enTxUrlPacote}
                    color="primary"
                    href={model.r2enTxUrlPacote}
                    target="_blank"
                    style={{ cursor: 'pointer', width: '370px' }}
                    icon={<GitHubIcon />}
                  />
                </Stack>
              ) : null}

              <Stack
                direction="row"
                spacing={2}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <h2>
                  Versão{' '}
                  {model.r2enTxVersaoEntrega ? model.r2enTxVersaoEntrega : '-'}
                </h2>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Chip label={getEnvironment(model.r2enTxAmbiente)} />
                <Chip label={packageType.r2teTxTipo} />
              </Stack>
              <Description style={breakWordStyle}>
                Título da Entrega:{' '}
                {model.r2enTxTituloEntrega ? model.r2enTxTituloEntrega : '-'}
              </Description>
              <DateTime title="Data Efetiva Entrega">
                Data Efetiva da Entrega:
                {model.r2enDtEfetivaEntrega
                  ? ' ' + formatDateString(model.r2enDtEfetivaEntrega)
                  : 'Data indisponível'}
              </DateTime>

              <DateTime title="Data Efetiva Entrega">
                Data Prevista da Entrega:
                {model.r2enDtPrevistaEntrega
                  ? ' ' + formatDateString(model.r2enDtPrevistaEntrega)
                  : 'Data indisponível'}
              </DateTime>
            </Stack>

            <Stack spacing={2}>
              <h3>Arquivos</h3>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 250 }} aria-label="simple table">
                  <TableBody>
                    {model?.arquivos?.length === 0 && loadingImage === true ? (
                      <TableRow>
                        <TableCell component="th" scope="row" colSpan={2}>
                          Sem resultado
                        </TableCell>
                      </TableRow>
                    ) : (
                      model?.arquivos?.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            <Button
                              onClick={() =>
                                downloadFile(item.r2aeTxNomeArquivo)
                              }
                            >
                              <CloudDownloadSharpIcon />
                            </Button>
                          </TableCell>

                          {imageUrls[index] ? (
                            <TableCell>
                              <img
                                style={{ width: '220px', height: '220px' }}
                                src={imageUrls[index]}
                              />
                            </TableCell>
                          ) : (
                            <TableCell>{item.r2aeTxNomeArquivo}</TableCell>
                          )}
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Stack>
        </ContainerDescription>

        <Stack spacing={6}>
          <Stack direction="row" spacing={1} style={{ paddingTop: '10px' }}>
            {isValidUrl(model.r2enTxUrlPlanoImplantacao) ? (
              <Chip
                component={'a'}
                label="Plano Implantação"
                color="primary"
                href={model.r2enTxUrlPlanoImplantacao}
                target="_blank"
                style={{ cursor: 'pointer' }}
                icon={<InsertLinkIcon />}
              />
            ) : null}
            {isValidUrl(model.r2enTxUrlPacote) ? (
              <Chip
                component={'a'}
                label="Pacote"
                color="primary"
                href={model.r2enTxUrlPacote}
                target="_blank"
                style={{ cursor: 'pointer' }}
                icon={<Download />}
              />
            ) : null}
            {hasFtp ? (
              <Chip
                label="Informações FTP"
                color="primary"
                onClick={() => setModalFtp(true)}
                icon={<InsertLinkIcon />}
              />
            ) : null}
            {hasIc ? (
              <Chip
                label="Informações IC"
                color="primary"
                onClick={() => setModalIc(true)}
                icon={<InsertLinkIcon />}
              />
            ) : null}
            {isValidUrl(model.r2enTxUrlAcessoAplicacao) ? (
              <Chip
                label="Acesso Aplicação"
                color="primary"
                href={model.r2enTxUrlAcessoAplicacao}
                target="_blank"
                style={{ cursor: 'pointer' }}
                icon={<InsertLinkIcon />}
              />
            ) : null}
            {isValidUrl(model.r2enTxUrlAcessoAplicacao) ? (
              <Chip
                label="Outros Documentos"
                color="primary"
                href={model.r2enTxUrlAcessoAplicacao}
                target="_blank"
                style={{ cursor: 'pointer' }}
                icon={<InsertLinkIcon />}
              />
            ) : null}
          </Stack>
          {model?.r2enTxUrlVideoEvidencia &&
          model.r2enTxUrlVideoEvidencia.includes(
            'https://www.youtube.com/watch?v='
          ) ? (
            <iframe
              style={{ marginTop: '10px' }}
              width="305"
              height="250"
              src={validateUrl(model?.r2enTxUrlVideoEvidencia)}
              type="video/mp4"
              allow="fullscreen"
              allowFullScreen
            />
          ) : null}
          {model.r2enTxUrlScriptBanco ? (
            <Stack spacing={2} style={breakWordStyle}>
              <h3>Script Banco</h3>
              <Paper style={breakWordStyle}>
                <code
                  style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}
                >
                  {model.r2enTxUrlScriptBanco}
                </code>
              </Paper>
            </Stack>
          ) : null}
        </Stack>
      </Paper>
      <Stack spacing={3}>
        <Divider />
        <CompanyComment handleGetAll={getComments} />
        <Divider />
        <Comments comments={comments} />
        <Container justify="center" padding="0 0 60px 0 ">
          <LoadingButton
            disabled={noMoreComments}
            onClick={loadMore}
            loading={loadingComments}
            loadingIndicator="Carregando..."
            variant="outlined"
          >
            Carregar mais
          </LoadingButton>
        </Container>
      </Stack>
    </div>
  );
};
