import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {},
  iconAside: {}
}));

export const SubMenuOption = ({ onClick, option, key }) => {
  const classes = useStyles();
  return (
    <MenuItem onClick={onClick} className={classes.container} key={key}>
      <div className={classes.iconAside}>{option.icon}</div>
      {option.nome}
    </MenuItem>
  );
};
