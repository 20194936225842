import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '95vh'
  },
  errorNumber: {
    color: '#565656',
    fontSize: '109px',
    fontFamily: 'Montserrat',
    fontWeight: 800,
    lineHeight: 1,
    marginBottom: 30
  },
  message: {
    FontSize: '30px',
    color: '#565656',
    fontFamily: 'Montserrat'
  },
  link: {
    fontSize: '20px',
    color: '#565656',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    textDecoration: 'underline'
  }
}));

const Error404 = () => {
  const classes = useStyles();

  return (
    <main>
      <Box className={classes.root}>
        <Box fontSize={{ xs: 100, sm: 160 }} className={classes.errorNumber}>
          404
        </Box>
        <Box fontSize={{ xs: 16, sm: 24 }} mb={8} className={classes.message}>
          Oops! página não encontrada
        </Box>
        <Box>
          <a className={classes.link} href="/">
            Voltar para home
          </a>
        </Box>
      </Box>
    </main>
  );
};

export default Error404;
