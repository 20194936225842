import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export const LoadingPage = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <CircularProgress size="80px" />
    </div>
  );
};
