import { Backdrop, CircularProgress } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { LoadingButton } from '@mui/lab';
import {
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material';
import { AlertsSnackBar } from 'components/AlertsSnackbar';
import api from 'config/api';
import { AuthContext } from 'context/auth';
import { MenuContext } from 'context/menuContext';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { formatDate3, formatDateString } from 'util/dateFormat';
import * as C from './styles';
import html2pdf from 'html2pdf.js';

const MySwal = withReactContent(Swal);

export const ContratoTermoDeAceite = ({
  modelDetails,
  getDeliveries,
  data
}) => {
  const { packageId } = useParams();
  const { user } = useContext(AuthContext);
  const { setLoadingContext } = useContext(MenuContext);
  const [loadingTermo, setLoadingTermo] = useState(false);
  const [erro, setErro] = useState();
  const [openSnackbar, setOpenSnackbar] = useState();
  const [termExist, setTermExist] = useState(null);

  const [model, setModel] = useState({
    r2taCdId: 0,
    r2enCdId: parseInt(packageId, 10),
    r2taBlStatus: null,
    r2taTxDescObjetivo: '',
    r2taTxDescEntrega: '',
    r2taTxDescQuestoesAberto: '',
    workerId: user.profile === 'CUSTOMER' ? parseInt(user.userId) : null,
    contributorId: user.profile === 'CONTRIBUTOR' ? user.userId : null
  });

  const findTermByProjectId = useCallback(async () => {
    try {
      const response = await api.get(
        `/r2d2TermoAceite/termoByEntrega/${packageId}`
      );

      setModel(response.data);
      setTermExist(response?.data?.status);
      setErro('');
    } catch (error) {
      setErro(error.response.data.message);
    }
  }, [packageId]);

  const sweetAlerts = () => {
    MySwal.fire({
      icon: 'success',
      title: 'Sucesso',
      text: 'Termo de aceite salvo com sucesso! Agradecemos por sua confirmação.',
      timer: 3000
    });
  };

  const handlePost = async () => {
    setLoadingTermo(true);

    const data = model;
    try {
      await api.post('/r2d2TermoAceite/inserirR2d2TermoAceite', data);
      setLoadingContext(true);
      setErro('');
      sweetAlerts();
      findTermByProjectId();
      getDeliveries();
    } catch (error) {
      setOpenSnackbar(true);
      setErro(error.response.data.message);
    } finally {
      setLoadingTermo(false);
    }
  };

  const updateModel = e => {
    setModel({ ...model, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    findTermByProjectId();
  }, [findTermByProjectId]);

  const handleSubmit = () => {
    if (model.r2taBlStatus == null) {
      setErro('Você não selecionou um campo!');
      setOpenSnackbar(true);
      return;
    } else {
      handlePost();
    }
  };

  const validationTypeUserContributor = user?.profile === 'CONTRIBUTOR';

  if (data == null) {
    return (
      <>
        <Skeleton height={60}></Skeleton>
        <Skeleton height={60}></Skeleton>
        <Skeleton height={60}></Skeleton>
      </>
    );
  }

  const generatePDF = () => {
    const element = document.getElementById('export-to-pdf');
    const clone = element.cloneNode(true);

    // Remove elementos com a classe 'no-print'
    const buttons = clone.querySelectorAll('.no-print');
    buttons.forEach(button => button.remove());

    //Ajuste de texto para input
    const textContent = clone.querySelector('.obs-div-input');
    textContent.style.fontSize = '16px';
    textContent.style.boxSizing = 'inherit';
    textContent.style.width = '100%';
    textContent.style.maxWidth = 'none';
    textContent.style.wordWrap = 'break-word';
    textContent.style.whiteSpace = 'pre-wrap';
    textContent.style.minHeight = '50%';
    textContent.style.display = 'block';

    // Ajuste de estilo do clone
    clone.style.margin = '0';
    clone.style.padding = '0';

    // Cria um container temporário para o clone
    const tempContainer = document.createElement('div');
    tempContainer.style.display = 'none';
    tempContainer.appendChild(clone);
    document.body.appendChild(tempContainer);

    // Ajuste das configurações para o PDF
    const opt = {
      margin: [10, 10], // Ajuste as margens conforme necessário
      filename: 'termo_aceite.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 3, useCORS: true },
      jsPDF: { unit: 'mm', format: [290, 290], orientation: 'portrait' }
    };

    setTimeout(() => {
      html2pdf()
        .from(clone)
        .set(opt)
        .save()
        .then(() => {
          document.body.removeChild(tempContainer);
        });
    }, 100);
  };

  return (
    <C.Container id="export-to-pdf">
      <Backdrop style={{ zIndex: 1 }} open={loadingTermo}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <C.InfoHeader>
        <C.Infos>
          <div className="header-position">
            <h2>Informações gerais</h2>
            <p>
              Campos com <span>*</span> são obrigatórios
            </p>
          </div>
          <div className="box-info">
            <p>
              <span>Projeto: </span>
              {data.projeto}
            </p>
            <p>
              <span>Cliente: </span>
              {data?.cliente}
            </p>
            <p>
              <span>Data de Entrega: </span>
              {formatDateString(modelDetails.r2enDtEfetivaEntrega)}
            </p>
            <p>
              <span>Etapa: </span>v{modelDetails.r2enTxVersaoEntrega} -{' '}
              {modelDetails.r2enTxTituloEntrega}
            </p>
          </div>
        </C.Infos>
        <C.Infos>
          <div className="header-position">
            <h2>Aceite da entrega</h2>
          </div>
          <div className="box-info">
            <p>
              <span>Nome: </span>
              {model.r2taCdId !== 0 ? model.workerName : user.username}
            </p>
            <p>
              <span>Função: </span>
              {model.r2taCdId !== 0 ? model.funcao : user.office}
            </p>
            <p>
              <span>Data: </span>
              {model.r2taCdId !== 0
                ? formatDate3(model.dataAceite)
                : formatDate3(new Date())}
            </p>
          </div>
        </C.Infos>
      </C.InfoHeader>
      <C.InfoBody>
        <div>
          <h2>Objetivo do documento</h2>
          <p>
            Este documento formaliza o aceite do marco da entrega e encerramento
            da fase do projeto.
          </p>
        </div>
        <div>
          <h2>Entrega</h2>
          <p>
            Entrega de todos os itens legais levantados previamente, bem como as
            demandas prioritárias definidas e priorizadas pela empresa{' '}
            <span>{user.nomeClient}</span>.
          </p>
        </div>
        <Divider variant="middle" className="divider" />
        <section>
          <div className="select-container">
            <p>
              {model.r2taBlStatus !== false && termExist !== false
                ? 'Concordo '
                : 'Não concordo '}
              com a entrega feita com a Neki do projeto solicitado.{' '}
              {model.r2taBlStatus !== false &&
                termExist !== false &&
                'Está de acordo com o solicitado '}
              {termExist == null ? <span>*</span> : null}
            </p>
            {termExist == null ? (
              <RadioGroup
                row
                aria-label="position"
                name="position"
                defaultValue="top"
                className="box"
              >
                <FormControlLabel
                  value={1}
                  control={<Radio color="primary" />}
                  label={
                    <span style={{ fontFamily: 'MontSerrat' }}>Concordo</span>
                  }
                  labelPlacement="right"
                  onChange={e => setModel({ ...model, [e.target.name]: true })}
                  name="r2taBlStatus"
                  disabled={validationTypeUserContributor}
                />
                <FormControlLabel
                  disabled={validationTypeUserContributor}
                  value={2}
                  control={<Radio color="primary" />}
                  label={
                    <span style={{ fontFamily: 'MontSerrat' }}>
                      Não concordo
                    </span>
                  }
                  labelPlacement="right"
                  onChange={e => setModel({ ...model, [e.target.name]: false })}
                  name="r2taBlStatus"
                />
              </RadioGroup>
            ) : null}
            {model.r2taBlStatus === true ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CheckCircleOutlineIcon
                  color="success"
                  style={{ marginRight: '10px' }}
                />
                <p style={{ fontWeight: '500', margin: 0 }}>
                  A entrega foi aceita.
                </p>
              </div>
            ) : null}
            {model.r2taBlStatus === false ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CancelIcon color="error" style={{ marginRight: '10px' }} />
                <p style={{ fontWeight: '500', margin: 0 }}>
                  A entrega não foi aceita.
                </p>
              </div>
            ) : null}
          </div>
          <TextField
            id="outlined-multiline-static"
            label="Observação"
            multiline
            rows={4}
            value={
              termExist == null
                ? model.r2taTxDescQuestoesAberto
                : model.questoesAbertas
            }
            inputProps={{ maxLength: 300 }}
            onChange={updateModel}
            name="r2taTxDescQuestoesAberto"
            className="obs-input no-print"
            // disabled={termExist}
            disabled={validationTypeUserContributor}
          />
          {/* Essa div esta relacionada com pdf, pois em documentação de html2 existe problema com input que não é mostrado em pdf todos os caracteres, com isso essa div exibe o conteudo em pdf. */}
          <div className="obs-div-input">
            <label>Observação:</label>
            {termExist == null
              ? model.r2taTxDescQuestoesAberto
              : model.questoesAbertas}
          </div>
        </section>
      </C.InfoBody>
      <div className="button-container">
        <AlertsSnackBar
          mensagem={erro}
          open={openSnackbar}
          color="#d32f2f"
          tipoDeaviso="error"
          handleClose={() => setOpenSnackbar(false)}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '50px'
          }}
        >
          <LoadingButton
            onClick={generatePDF}
            variant="contained"
            className="btn no-print"
            loading={loadingTermo}
            loadingIndicator="Enviando..."
          >
            Exportar
          </LoadingButton>
          {termExist == null ? (
            <LoadingButton
              disabled={validationTypeUserContributor}
              onClick={handleSubmit}
              variant="contained"
              className="btn no-print"
              style={
                loadingTermo ? { backgroundColor: 'rgba(0, 0, 0, 0.26)' } : null
              }
              loading={loadingTermo}
              loadingIndicator="Enviando..."
            >
              Enviar
            </LoadingButton>
          ) : null}
        </div>
      </div>
    </C.Container>
  );
};
