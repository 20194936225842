import React from 'react';

import Header from '../../components/Header';
import { Footer } from 'components/Footer';
import Box from '@material-ui/core/Box';
import { FormControl, InputLabel, Select, TextField } from '@mui/material';

import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

import { Container } from './styles';
import LogoDaGs from '../../assets/iconGs.svg';

export const CadastroDeFuncoes = () => {
  const [funcao, setFuncao] = React.useState('');
  const [perfil, setPerfil] = React.useState('');
  const [gestor, setGestor] = React.useState('');

  return (
    <Container>
      <Header
        nomeDaEmpresa="Neki"
        iconeEmpresa={LogoDaGs}
        backgroundHeader="#24292F"
        otherColor="#FFFFFF"
      />
      <main>
        <form>
          <Box className="box">
            <TextField
              id="outlined-basic"
              label="Login"
              variant="outlined"
              style={{ width: 250 }}
            />
            <FormControl sx={{ minWidth: 250 }}>
              <InputLabel id="demo-simple-select-label">Função</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={funcao}
                label="Função"
                onChange={event => setFuncao(event.target.value)}
              >
                <MenuItem value={10}>Arquiteto de software</MenuItem>
                <MenuItem value={20}>Desenvolvedor Back-end</MenuItem>
                <MenuItem value={30}>Desenvolvedor Front-end</MenuItem>
                <MenuItem value={40}>Gestor</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box className="box">
            <FormControl sx={{ minWidth: 250 }}>
              <InputLabel id="demo-simple-select-label">Perfil</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={perfil}
                label="Perfil"
                onChange={event => setPerfil(event.target.value)}
              >
                <MenuItem value={10}>perfil 1</MenuItem>
                <MenuItem value={20}>perfil 2</MenuItem>
                <MenuItem value={30}>perfil 3</MenuItem>
                <MenuItem value={40}>perfil 4</MenuItem>
                <MenuItem value={40}>perfil 5</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 250 }}>
              <InputLabel id="demo-simple-select-label">Gestor</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={gestor}
                label="Gestor"
                onChange={event => setGestor(event.target.value)}
              >
                <MenuItem value={10}>Gestor 1</MenuItem>
                <MenuItem value={20}>Gestor 2</MenuItem>
                <MenuItem value={30}>Gestor 3</MenuItem>
                <MenuItem value={40}>Gestor 4</MenuItem>
                <MenuItem value={50}>Gestor 5</MenuItem>
                <MenuItem value={60}>Gestor 6</MenuItem>
                <MenuItem value={70}>Gestor 7</MenuItem>
                <MenuItem value={80}>Gestor 8</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box className="box-bottom">
            <Button variant="contained">Cadastrar</Button>
          </Box>
        </form>
      </main>
      <Footer backgroundColor="#24292f" fontColor="#eee" />
    </Container>
  );
};
