import { Button } from '@material-ui/core';
import { Stack } from '@mui/material';
import { AlertsModal } from 'components/AlertsModal';
import { Container, InputCopy } from 'global/styles';
import React, { useRef } from 'react';

export const IcModal = ({ open, onClose, url, setFinished }) => {
  const urlRef = useRef(url);

  return (
    <AlertsModal
      open={open}
      onClose={onClose}
      title="Informações de acesso IC"
      buttons={
        <Button variant="contained" color="primary" onClick={onClose}>
          Ok
        </Button>
      }
    >
      <Stack padding="20px">
        <Stack>
          <label style={{ textAlign: 'left' }}>URL</label>
          <Container justify="space-between" w="280px">
            <InputCopy type="text" ref={urlRef} value={url} />
            <Button
              variant="contained"
              onClick={() => {
                urlRef.current.select();
                document.execCommand('copy');
                setFinished(true);
              }}
            >
              Copiar
            </Button>
          </Container>
        </Stack>
      </Stack>
    </AlertsModal>
  );
};
