import styled from 'styled-components';

export const CardContainer = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  background: #fff;
  color: #24292f;
  width: 300px;
  justify-content: space-between;
  box-shadow: 5px 5px 20px #00000029;
  border-radius: 10px;

  &:hover {
    box-shadow: 10px 10px 50px #00000029;
  }

  .card-header {
    font-size: 16px;
    font-family: Montserrat;
    margin-bottom: 10px;
  }

  .card-body {
    text-align: left;
    font-family: Montserrat;
    font-size: 12px;
  }

  .card-footer {
    width: 300px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  .card-button {
    padding: 8px;
    display: flex;
    flex-direction: row;
    width: 70%;
    font-size: 12px;
    margin: 0px;
    border-radius: 0px;
    border-top: 0.5px solid #e2e2e2;
    justify-content: center;
    align-items: center;
  }

  .one {
    border-radius: 0 0 0 10px;
    border-right: 0.5px solid #e2e2e2;
  }

  .two {
    border-radius: 0 0 10px 0px;
  }
`;
