import styled from 'styled-components';

export const Container = styled.main`
  padding: 50px 50px 0 50px;
  font-family: 'Montserrat';

  .button-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    .btn {
      width: 130px;
      background-color: #00868c;
    }

    .btn-pdf {
      background-color: #03a1fc;
    }

    .hidden {
      display: none !important;
    }
  }
`;

export const InfoHeader = styled.section``;

export const Infos = styled.div`
  margin-bottom: 30px;
  .box-info {
    border: 1px solid #a0a0a0;
    padding: 15px;
    border-radius: 10px;
  }
  p {
    font-size: 16px;
    margin-bottom: 7px;
    span {
      color: #333333;
      font-weight: 600;
    }
  }

  .header-position {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    p {
      color: #a0a0a0;
      span {
        color: red;
      }
    }
  }
`;

export const InfoBody = styled.section`
  .radio-option {
    font-family: 'Montserrat';
  }

  .divider {
    margin: 30px 0 30px 0;
  }

  div {
    margin-bottom: 10px;
    h2 {
      margin-bottom: 15px;
    }
    p {
      font-size: 16px;
    }
  }

  section {
    .select-container {
      border: 1px solid #a0a0a0;
      border-radius: 10px;
      padding: 15px;
    }
    p {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 10px;
      span {
        color: #ff0000;
      }
    }

    .obs-input {
      width: 100%;
      margin-left: 1px;
      word-wrap: break-word;
    }
    .obs-div-input {
      display: none;
      padding: 10px;
      margin-top: 10px;
      max-width: 95%;
      border: 1px solid rgba(128, 128, 128, 0.5);
      border-radius: 8px;
      height: 160px;
    }
    .obs-div-input label {
      color: rgba(0, 0, 0, 0.87);
      font-weight: 400;
      font-size: 1rem;
      /* font-weight: bold; */
      display: block;
      margin-bottom: 5px;
    }
  }
`;
