import styled from 'styled-components';

import { Skeleton as MaterialSkeleton } from '@material-ui/lab';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 40px;
  background-color: white;
  min-height: calc(100vh - 164px);
  height: auto;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 600px;
`;

export const Skeleton = styled(MaterialSkeleton)`
  width: 100% !important;
`;

export const ContainerButtons = styled.div`
  display: flex;
  gap: 25px;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 0;

  p {
    color: red;
  }

  button {
    width: 147px;
  }
`;

export const ContainerNovoProjeto = styled.div`
  display: flex;

  @media (max-width: 990px) {
    flex-wrap: wrap;
  }
`;

export const ContainerDatas = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  width: 100%;
`;
