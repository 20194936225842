import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import { Row } from './Row';

import {
  TableHead,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { DocumentTitle } from 'components/DocumentTitle';
import api from 'config/api';
import { useNavigate } from 'react-router-dom';
import { StyledBox } from './styles';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  {
    id: 'nome',
    numeric: false,
    disablePadding: false,
    label: 'Nome'
  },
  {
    id: 'login',
    numeric: true,
    disablePadding: false,
    label: 'Login'
  },
  {
    id: 'funcao',
    numeric: true,
    disablePadding: false,
    label: 'Função'
  },
  {
    id: 'cliente',
    numeric: true,
    disablePadding: false,
    label: 'Cliente'
  }
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ backgroundColor: '#2A8594', color: 'white' }}>
      <TableRow>
        <TableCell />
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ fontWeight: 'bold' }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}

        <TableCell
          align="right"
          sx={{ fontWeight: 'bold' }}
          style={{ paddingRight: 60 }}
        >
          Opções
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

export function Funcionarios() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('nome');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [workers, setWorkers] = React.useState([]);
  const [filtro, setFiltro] = React.useState('');
  const navigate = useNavigate();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getWorkers = React.useCallback(async () => {
    api
      .get(`/workers/search?keyword=${filtro}`)
      .then(response => {
        let workerLista = [];
        response.data.forEach(item => {
          const index = workerLista.findIndex(
            element => element.id === item.workerId
          );

          if (index === -1) {
            workerLista.push({
              id: item.workerId,
              login: item.login,
              nome: item.workerName,
              cliente: item.clientName,
              funcao: item.funcao,
              isActive: item.isActive,
              usuarioId: item.usuarioId,

              contratos: [
                {
                  descricao: item.descricaoContrato,
                  dataInicio: item.dataInicio,
                  dataFim: item.dataFim
                }
              ]
            });
          } else {
            workerLista[index].contratos.push({
              descricao: item.descricaoContrato,
              dataInicio: item.dataInicio,
              dataFim: item.dataFim
            });
          }
        });

        setWorkers(workerLista);
      })
      .catch(error => {
        //TODO: error
      });
  }, [filtro]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - workers.length + 1) : 0;

  const handleDisableWorker = item => {
    let status = item.isActive.toString() === 'false' ? 'true' : 'false';
    api
      .put(
        `/workers/disableEnable`,
        {},
        { params: { workerId: item.id, active: status } }
      )
      .then(
        setWorkers(
          workers.map(element => {
            return element.id === item.id
              ? { ...element, isActive: status }
              : element;
          })
        )
      );
  };

  React.useEffect(() => {
    getWorkers();
  }, [getWorkers]);

  return (
    <StyledBox>
      <DocumentTitle>Funcionários - R2D2</DocumentTitle>

      <Typography variant="h5" gutterBottom>
        Funcionários
      </Typography>

      <div
        style={{
          display: 'flex',
          gap: 20,
          alignItems: 'center',
          justifyContent: 'space-between',
          margin: '20px 0px 10px 0px'
        }}
      >
        <Tooltip title="Cadastrar funcionário" arrow>
          <AddCircleIcon
            onClick={() => navigate('/funcionario')}
            sx={{ color: '#2A8594', fontSize: 50, cursor: 'pointer' }}
          />
        </Tooltip>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            onChange={e => setFiltro(e.target.value)}
            name="filtro"
            id="filtro"
            style={{ width: '100%', backgroundColor: '#fff' }}
            label="Palavra chave"
            variant="outlined"
            type="text"
            inputProps={{ maxLength: 100 }}
            size="small"
          />

          <Button
            variant="contained"
            onClick={getWorkers}
            sx={{ backgroundColor: '#2D8DA0' }}
          >
            Filtrar
          </Button>
        </div>
      </div>

      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={workers.length}
            />

            <TableBody>
              {stableSort(workers, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <Row
                    key={row.login}
                    row={row}
                    handleDisableWorker={handleDisableWorker}
                  />
                ))}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={workers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Linhas por página"
          labelDisplayedRows={({ from, to, count }) => {
            return '' + from + '-' + to + ' de ' + count;
          }}
        />
      </Paper>
    </StyledBox>
  );
}
