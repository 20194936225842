import styled from 'styled-components';

export const TimelineContainer = styled.div`
  display: flex;
  @media (max-width: 1050px) {
    flex-direction: column;
  }
`;

export const CardsContainer = styled.aside`
  display: flex;
  overflow: auto;
  flex-direction: column;
  padding: 20px 30px;
  min-width: 460px;
  background: #fff;
  @media (max-width: 1050px) {
    /* order: 2; */
    display: none;
  }

  @media (min-width: 1050px) {
    max-height: 91vh;
  }
`;

export const DetailsContainer = styled.main`
  display: flex;
  flex-direction: column;
  overflow: auto;
  background: #ffffff;
  width: 100%;

  /* max-width: 100vh; */

  @media (min-width: 850px) {
    height: 91vh;
  }
`;

export const SkeletonsContainerLeft = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 70px;

  .skeletons {
    display: flex;
    align-items: center;

    .skeleton-card {
      border-radius: 10px;
      margin: 10px;
    }
  }
`;

export const SkeletonsContainerRight = styled.div`
  width: 100%;

  .skeleton-section {
    margin: 0px 10px;
  }

  .arrows-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 30px 0px;
  }

  .skeleton-button {
    border-radius: 20px;
    margin: 10px;
  }
`;
