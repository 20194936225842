import { useContext, useEffect, useState } from 'react';
import * as C from './styles';

import api from 'config/api';

import { Divider } from '@mui/material';
import TextField from '@mui/material/TextField';
import { AuthContext } from 'context/auth';
import { useParams } from 'react-router-dom';

import { AlertsSnackBar } from 'components/AlertsSnackbar';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { Backdrop, CircularProgress } from '@material-ui/core';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { LoadingButton } from '@mui/lab';
import Rating from '@mui/material/Rating';
import { styled } from '@mui/material/styles';
import { MenuContext } from 'context/menuContext';
import PropTypes from 'prop-types';

const MySwal = withReactContent(Swal);

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled
  }
}));

const customIcons = {
  1: {
    id: 1,
    icon: (
      <SentimentVeryDissatisfiedIcon
        color="error"
        style={{ width: '40px', height: '40px', marginRight: '30px' }}
      />
    ),
    label: 'Ruim'
  },
  2: {
    id: 2,
    icon: (
      <SentimentDissatisfiedIcon
        color="warning"
        style={{ width: '40px', height: '40px', marginRight: '30px' }}
      />
    ),
    label: 'Regular'
  },
  3: {
    id: 3,
    icon: (
      <SentimentSatisfiedIcon
        style={{ width: '40px', height: '40px', marginRight: '30px' }}
      />
    ),
    label: 'Bom'
  },
  4: {
    id: 4,
    icon: (
      <SentimentSatisfiedAltIcon
        color="primary"
        style={{ width: '40px', height: '40px', marginRight: '30px' }}
      />
    ),
    label: 'Ótimo'
  },
  5: {
    id: 5,
    icon: (
      <SentimentVerySatisfiedIcon
        color="success"
        style={{ width: '40px', height: '40px', marginRight: '30px' }}
      />
    ),
    label: 'Excelente'
  }
};

function IconContainer(props) {
  const { value, ...other } = props;
  return (
    <p title={customIcons[value].label}>
      <span {...other}>{customIcons[value].icon}</span>
    </p>
  );
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired
};

export const PesquisaAvaliacao = ({ getDeliveries }) => {
  const [valores, setValores] = useState({
    valorQualidade: 0,
    valorPrazo: 0,
    valorSatisfacao: 0,
    valorAtendimento: 0,
    valorDuvidas: 0,
    valorIndicacao: 0
  });
  const { packageId } = useParams();
  const { user } = useContext(AuthContext);
  const [observacao, setObservacao] = useState();
  const [loading, setLoading] = useState(false);
  const [erro, setErro] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [searchResult, setSearchResult] = useState(null);
  const { setLoadingContext } = useContext(MenuContext);

  const sweetAlerts = () => {
    MySwal.fire({
      icon: 'success',
      title: 'Sucesso',
      text: 'Agradecemos pela avaliação! Seu feedback é fundamental para nós.',
      timer: 5000
    });
  };

  const handlePostRating = async () => {
    setLoading(true);
    const data = {
      r2psNuQualidade: valores.valorQualidade,
      r2psNuPrazo: valores.valorPrazo,
      r2psNuSatisfacao: valores.valorSatisfacao,
      r2psNuAtendimento: valores.valorAtendimento,
      r2psNuDuvidas: valores.valorDuvidas,
      r2psNuIndicacao: valores.valorIndicacao,
      r2psTxDscObservacao: observacao,
      r2enCdId: packageId,
      workerId: user.profile === 'CUSTOMER' ? parseInt(user.userId) : null,
      contributorId: user.profile === 'CONTRIBUTOR' ? user.userId : null
    };
    try {
      await api.post('/pesquisaSatisfacao/inserirPesquisaSatisfacao', data);
      setLoadingContext(true);
      setErro('');
      sweetAlerts();
      setSearchResult('');
      await findByIdSatisfaction();
      // window.location.reload();
      getDeliveries();
    } catch (error) {
      setOpenSnackbar(true);
      setErro(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  function handleSubmitRating(e) {
    e.preventDefault();
    if (
      valores.valorQualidade === 0 ||
      valores.valorQualidade === undefined ||
      valores.valorSatisfacao === 0 ||
      valores.valorSatisfacao === undefined ||
      valores.valorPrazo === 0 ||
      valores.valorPrazo === undefined ||
      valores.valorDuvidas === 0 ||
      valores.valorDuvidas === undefined ||
      valores.valorAtendimento === 0 ||
      valores.valorAtendimento === undefined
    ) {
      setErro('Você não selecionou todos os campos!');
      setOpenSnackbar(true);
      return;
    } else {
      setErro('');
      handlePostRating();
    }
  }

  const findByIdSatisfaction = async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `/pesquisaSatisfacao/byIdPacote/${packageId}`
      );
      setSearchResult(response.data);
      setObservacao(response.data?.r2psTxDscObservacao);
    } catch (error) {
      // TODO
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    findByIdSatisfaction();
  }, [packageId]);

  useEffect(() => {
    setValores(prevState => {
      return {
        ...prevState,
        valorQualidade: searchResult?.r2psNuQualidade,
        valorPrazo: searchResult?.r2psNuPrazo,
        valorSatisfacao: searchResult?.r2psNuSatisfacao,
        valorAtendimento: searchResult?.r2psNuAtendimento,
        valorDuvidas: searchResult?.r2psNuDuvidas,
        valorIndicacao: searchResult?.r2psNuIndicacao
      };
    });
  }, [searchResult]);

  if (loading) {
    return (
      <>
        <Backdrop style={{ zIndex: 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }

  const validationTypeUserContributor = user?.profile === 'CONTRIBUTOR';

  return (
    <C.Container>
      <Backdrop style={{ zIndex: 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <C.Form onSubmit={handleSubmitRating}>
        <div className="box-body">
          <h2>Avalie sua experiência</h2>
          <p>
            Campos com <span>*</span> são obrigatórios.
          </p>
        </div>
        <p className="subtitle">
          Sua avaliação é muito importante para o desenvolvimento do projeto.
        </p>
        <C.Card>
          <div className="box-question">
            <p>
              Como avalia a qualidade do nosso atendimento ?
              <span className="red"> *</span>
            </p>
            <div className="card-container">
              <StyledRating
                name="highlight-selected-only"
                readOnly={
                  searchResult !== null || validationTypeUserContributor
                }
                value={valores.valorQualidade}
                IconContainerComponent={IconContainer}
                getLabelText={value => customIcons[value]?.label}
                highlightSelectedOnly
                onChange={(e, newValue) => {
                  setValores(prevState => ({
                    ...prevState,
                    valorQualidade: newValue
                  }));
                }}
              />
            </div>
          </div>
          <Divider variant="middle" className="divider" />
          <div className="box-question">
            <p>
              O tempo de execução dessa entrega foi satisfatório ?
              <span className="red"> *</span>
            </p>
            <div className="card-container">
              <StyledRating
                name="highlight-selected-only"
                readOnly={
                  searchResult !== null || validationTypeUserContributor
                }
                defaultValue={valores?.valorPrazo}
                IconContainerComponent={IconContainer}
                getLabelText={value => customIcons[value]?.label}
                highlightSelectedOnly
                onChange={(e, newValue) => {
                  setValores(prevState => ({
                    ...prevState,
                    valorPrazo: newValue
                  }));
                }}
              />
            </div>
          </div>
          <Divider variant="middle" />
          <div className="box-question">
            <p>
              Qual o nível de satisfação com o resultado final entregue pela
              Neki ?<span className="red"> *</span>
            </p>
            <div className="card-container">
              <StyledRating
                name="highlight-selected-only"
                readOnly={
                  searchResult !== null || validationTypeUserContributor
                }
                defaultValue={valores?.valorSatisfacao}
                IconContainerComponent={IconContainer}
                getLabelText={value => customIcons[value]?.label}
                highlightSelectedOnly
                onChange={(e, newValue) => {
                  setValores(prevState => ({
                    ...prevState,
                    valorSatisfacao: newValue
                  }));
                }}
              />
            </div>
          </div>
          <Divider variant="middle" />
          <div className="box-question">
            <p>
              Nossa equipe atendeu da forma esperada ?
              <span className="red"> *</span>
            </p>
            <div className="card-container">
              <StyledRating
                name="highlight-selected-only"
                readOnly={
                  searchResult !== null || validationTypeUserContributor
                }
                defaultValue={valores?.valorAtendimento}
                IconContainerComponent={IconContainer}
                getLabelText={value => customIcons[value]?.label}
                highlightSelectedOnly
                onChange={(e, newValue) => {
                  setValores(prevState => ({
                    ...prevState,
                    valorAtendimento: newValue
                  }));
                }}
              />
            </div>
          </div>
          <Divider variant="middle" />
          <div className="box-question">
            <p>
              Este modelo está contribuindo para melhoria da comunicação e
              processo de entrega ?<span className="red"> *</span>
            </p>
            <div className="card-container">
              {/* <Tooltip title="ttttt"> */}
              <StyledRating
                name="highlight-selected-only"
                readOnly={
                  searchResult !== null || validationTypeUserContributor
                }
                defaultValue={valores?.valorDuvidas}
                IconContainerComponent={IconContainer}
                getLabelText={value => customIcons[value]?.label}
                highlightSelectedOnly
                onChange={(e, newValue) => {
                  setValores(prevState => ({
                    ...prevState,
                    valorDuvidas: newValue
                  }));
                }}
              />
              {/* </Tooltip> */}
            </div>
          </div>
          <Divider variant="middle" />
          <div className="box-question">
            <p>Indicaria os serviços da Neki para algum amigo ou conhecido ?</p>
            <div className="card-container">
              <StyledRating
                name="highlight-selected-only"
                readOnly={
                  searchResult !== null || validationTypeUserContributor
                }
                defaultValue={valores?.valorIndicacao}
                IconContainerComponent={IconContainer}
                getLabelText={value => customIcons[value]?.label}
                highlightSelectedOnly
                onChange={(e, newValue) => {
                  setValores(prevState => ({
                    ...prevState,
                    valorIndicacao: newValue
                  }));
                }}
              />
            </div>
          </div>
          <Divider variant="middle" />
          <div className="box-question">
            <p>Deixe abaixo suas sugestões de melhoria e críticas:</p>
            {searchResult === null ? (
              <TextField
                className="obs-question"
                value={observacao}
                name="r2enTxUrlPlanoImplantacao"
                id="r2enTxUrlPlanoImplantacao"
                variant="outlined"
                label="Observação"
                onChange={e => {
                  setObservacao(e.target.value);
                }}
                multiline
                rows={4}
                inputProps={{ maxLength: 300 }}
                disabled={validationTypeUserContributor}
              />
            ) : (
              <TextField
                className="obs-question"
                value={observacao}
                name="r2enTxUrlPlanoImplantacao"
                id="r2enTxUrlPlanoImplantacao"
                variant="outlined"
                InputLabelProps={{
                  shrink: searchResult !== null ? true : null
                }}
                label="Observação"
                onChange={e => {
                  setObservacao(e.target.value);
                }}
                multiline
                rows={4}
                disabled={true}
                inputProps={{ maxLength: 300 }}
              />
            )}
          </div>
        </C.Card>
        <div id="button-container">
          <AlertsSnackBar
            mensagem={erro}
            open={openSnackbar}
            color="#d32f2f"
            tipoDeaviso="error"
            handleClose={() => setOpenSnackbar(false)}
          />
          {searchResult === null && (
            <p
              title={
                validationTypeUserContributor &&
                'Somente Worker consegue enviar formulário'
              }
            >
              <LoadingButton
                disabled={validationTypeUserContributor}
                type="submit"
                variant="contained"
                color="primary"
                className="btn"
                style={
                  loading ? { backgroundColor: 'rgba(0, 0, 0, 0.26)' } : null
                }
                loading={loading}
                loadingIndicator="Enviando..."
              >
                Enviar
              </LoadingButton>
            </p>
          )}
        </div>
      </C.Form>
    </C.Container>
  );
};
