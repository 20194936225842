import styled from 'styled-components';

export const ContainerComment = styled.section`
  display: flex;
  padding-bottom: 14px;
  height: 100%;
  .comment-input {
    width: 100%;
  }
`;

export const ContainerImage = styled.div`
  background: #fff;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;

  img {
    width: 24px;
    height: 24px;
  }
`;
