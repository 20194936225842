import styled from 'styled-components';

export const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  color: #515151;
  align-items: center;
`;

export const TabsContainer = styled.section`
  margin-left: 60px;
`;
