import styled from 'styled-components';

export const Container = styled.section`
  h2 {
    display: inline-block;
    font-size: 28px;
    border-bottom: 4px solid rgb(1, 146, 156);
    padding-bottom: 10px;
  }
  p {
    margin-top: 20px;
  }
  #card-box {
    display: flex;
    margin-top: 20px;
    margin-bottom: 35px;
    .card {
      width: 100px;
      height: 80px;
      border-radius: 10px;
      border: 1px solid rgb(1, 146, 156);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: rgb(1, 146, 156);
      margin-right: 40px;
      cursor: pointer;
      .icon {
        width: 40px;
        height: 30px;
      }
      p {
        margin-top: 10px;
        font-size: 14px;
      }
    }
    .card-ativo {
      width: 100px;
      height: 80px;
      border-radius: 10px;
      border: 1px solid rgb(1, 146, 156);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #fff;
      margin-right: 40px;
      background-color: rgb(1, 146, 156);
      cursor: pointer;
      .icon {
        width: 40px;
        height: 30px;
      }
      p {
        margin-top: 10px;
        font-size: 14px;
      }
    }
  }
  #form-box {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    width: 90%;
    p {
      margin-top: 2px;
    }
    .input {
      margin-bottom: 30px;
    }
    .btn {
      background-color: rgb(1, 146, 156);
      margin-bottom: 10px;
    }
  }

  .loading-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25rem;

    .loading {
      color: rgb(1, 146, 156);
    }
  }
`;
