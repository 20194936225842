export const defaultPackage = {
  r2enTxAmbiente: '',
  r2enTxStatus: 'A',
  r2enTxVersaoEntrega: '',
  r2enDtPrevistaEntrega: '',
  // r2enDtEfetivaEntrega: '',
  // r2enNmDiasIntervalo: 0,
  r2enTxUrlPlanoImplantacao: '',
  r2enTxUrlFonte: '',
  r2enTxUrlPacote: '',
  r2enTxUrlAcessoAplicacao: '',
  r2enTxUrlScriptBanco: '',
  r2enTxUrlVideoEvidencia: '',
  // r2enTxOutrosDocumentos: '',
  r2enTxObservacaoImplantacao: '',
  // r2enDtLeituraCliente: '',
  // r2enDtPesquisaSatisfacao: '',
  // r2enNmPesquisaSatisfacao: 0,
  r2enBlEnviaNotificacoes: false,
  r2enTxEmails: '',
  contrato: 0,
  r2teCd: '',
  r2enTxTituloEntrega: '',
  r2enTxFtpUrl: '',
  r2enTxFtpSenha: '',
  r2enTxFtpLogin: '',
  r2enBlEnviarEmailCliente: false
};

export const defaultPackageType = {
  r2teCdId: 0,
  r2teDtCadastro: '',
  r2teTxTipo: '',
  r2teTxImagem: ''
};

export const defaultOrder = 1;
export const defaultOrderHome = 1;

export const orders = [
  { value: 1, name: 'Crescente' },
  { value: 2, name: 'Decrescente' }
];
export const ordersHome = [
  { id: 1, name: 'Crescente' },
  { id: 2, name: 'Decrescente' }
];

export const defaultProjectFilter = 'projectname';
export const defaultProjectFilterHome = 1;

export const projectFilters = [
  { value: 'projectname', name: 'Projeto' },
  { value: 'clientname', name: 'Cliente' },
  { value: 'ultimaentrega', name: 'Data Ultima Entrega' }
];

export const projectFiltersHome = [
  { id: 1, name: 'Descrição' },
  { id: 2, name: 'Data de Cadastro' },
  { id: 3, name: 'Data de Alteração' }
];

export const defaultPackageFilter = 4;

export const packageFilters = [
  { id: 4, name: 'Descrição' },
  { id: 5, name: 'Data de Entrega' }
];
