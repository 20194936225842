import styled from 'styled-components';
import logo from '../../assets/Logo-Branca-R2D2-2024.svg';

export const Container = styled.main`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5fbff;

  background-size: cover;
  #box-container {
    display: flex;
    width: 60rem;
    height: 35rem;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #253f57;
    background-image: url(${logo});
    background-repeat: no-repeat;
    background-size: 30vh;
    background-position: 7%;
    box-shadow: 5px 5px 5px #00000029;
  }

  @media (max-width: 990px) {
    #box-container {
      width: 30rem;
      height: 35rem;
      background: none;
    }
  }
`;

export const Aside = styled.aside`
  width: 40%;
  background-color: #253f57;
  padding: 15px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-position: center center;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .position {
    display: flex;
    justify-content: flex-end;

    .neki {
      width: 60px;
    }
  }

  h2 {
    font-size: 28px;
  }
  @media (max-width: 990px) {
    p {
      font-size: 12px;
    }
  }
`;

export const Form = styled.form`
  padding: 15px;
  padding-left: 40px;
  width: 60%;

  h2 {
    display: inline-block;
    font-size: 28px;
    border-bottom: 4px solid rgb(1, 146, 156);
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
`;
