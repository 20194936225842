import EditIcon from '@mui/icons-material/Edit';
import TimelineIcon from '@mui/icons-material/Timeline';
import { Button, Divider, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { CardContainer } from './styles.js';

export const CardPacote = ({
  id,
  title,
  description,
  onClickTimeline,
  onClickEdit,
  i,
  typeUser
}) => {
  const { index } = useParams();

  return (
    <CardContainer key={id} border={index && `${i}` && index === `${i}`}>
      <Stack>
        <Stack spacing={1} padding="16px">
          <div className="card-header">
            <h4>{title ? title : 'Sem título'}</h4>
          </div>
          <div className="card-body">
            {description ? (
              <p>
                {description.length >= 50
                  ? description.slice(0, 50).trim() + '...'
                  : description}
              </p>
            ) : (
              'Sem descrição'
            )}
          </div>
        </Stack>
        <div className="card-footer">
          <Button
            size="small"
            className="card-button one"
            onClick={onClickTimeline}
            endIcon={<TimelineIcon fontSize="small" />}
          >
            <p>Ver Timeline</p>
          </Button>
          <Divider orientation="vertical" />
          <Button
            size="small"
            className="card-button two"
            onClick={onClickEdit}
            disabled={typeUser !== 'CONTRIBUTOR'}
            endIcon={<EditIcon fontSize="small" />}
          >
            <p>Editar</p>
          </Button>
        </div>
      </Stack>
    </CardContainer>
  );
};
