import {
  TableHead,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, Skeleton, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { visuallyHidden } from '@mui/utils';
import api from 'config/api';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Row } from './Row';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy] || b[orderBy] === null) {
    return -1;
  }
  if (b[orderBy] > a[orderBy] || a[orderBy] === null) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  {
    id: 'nomeColaborador',
    numeric: false,
    disablePadding: false,
    label: 'Colaborador'
  },
  {
    id: 'nomeLider',
    numeric: true,
    disablePadding: false,
    label: 'Líder'
  },
  {
    id: 'tipoLideranca',
    numeric: true,
    disablePadding: false,
    label: 'Tipo de liderança'
  },
  {
    id: 'observacao',
    numeric: true,
    disablePadding: false,
    label: 'Observação'
  },
  {
    id: 'dataInicio',
    numeric: true,
    disablePadding: false,
    label: 'Data de Início'
  },
  {
    id: 'dataFim',
    numeric: true,
    disablePadding: false,
    label: 'Data de fim'
  }
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ backgroundColor: '#2A8594', color: 'white' }}>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ fontWeight: 'bold' }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}

        <TableCell
          align="right"
          sx={{ fontWeight: 'bold' }}
          style={{ paddingRight: 40 }}
        >
          Opções
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

export function LiderTutor() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('nomeColaborador');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [loading, setLoading] = React.useState(false);
  const [liderTutor, setLiderTutor] = React.useState([]);
  const [filtro, setFiltro] = React.useState('');
  const navigate = useNavigate();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - liderTutor.length + 1)
      : 0;

  const handleChangeStatus = row => {
    const dataInicio = row.dataInicio.split('/');
    const dataFim = row.dataFim ? row.dataFim.split('/') : null;

    api.put(`/liderTutor/${row.idLiderTutor}`, {
      idContributor: row.idColaborador,
      idLider: row.idLider,
      tipoLider: row.tipoLideranca,
      observacao: row.observacao,
      dataInicio: `${dataInicio[2]}-${dataInicio[1]}-${dataInicio[0]}T03:00:00.000Z`,
      dataFim: `${dataFim[2]}-${dataFim[1]}-${dataFim[0]}T03:00:00.000Z`,
      ativo: !row.ativo
    });
  };

  const getLiderTutor = React.useCallback(async () => {
    setLoading(true);

    api
      .get('/liderTutor/search', { params: { keyword: filtro } })
      .then(response => {
        setLiderTutor(response.data);
      })
      .catch(error => {
        //TODO: error
      })
      .finally(() => {
        setLoading(false);
      });
  }, [filtro]);

  React.useEffect(() => {
    getLiderTutor();
  }, [getLiderTutor]);

  return (
    <Box style={{ margin: 60 }}>
      <Typography variant="h5" gutterBottom>
        Liderança e tutoria
      </Typography>

      <div
        style={{
          display: 'flex',
          gap: 20,
          alignItems: 'center',
          justifyContent: 'space-between',
          margin: '20px 0px 10px 0px'
        }}
      >
        <Tooltip title="Cadastrar liderança" arrow>
          <AddCircleIcon
            onClick={() => navigate('/cadastroLiderTutor')}
            sx={{ color: '#2A8594', fontSize: 50, cursor: 'pointer' }}
          />
        </Tooltip>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            onChange={e => setFiltro(e.target.value)}
            name="filtro"
            id="filtro"
            style={{ width: '100%', backgroundColor: '#fff' }}
            label="Palavra chave"
            variant="outlined"
            type="text"
            inputProps={{ maxLength: 100 }}
            size="small"
          />
          <Button variant="contained" onClick={getLiderTutor}>
            Filtrar
          </Button>
        </div>
      </div>

      {loading ? (
        <Stack spacing={2}>
          <Skeleton variant="rectangular" height={60} />
          <Skeleton variant="rectangular" height={60} />
          <Skeleton variant="rectangular" height={60} />
          <Skeleton variant="rectangular" height={60} />
          <Skeleton variant="rectangular" height={60} />
        </Stack>
      ) : (
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={'medium'}
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={liderTutor.length}
              />

              <TableBody>
                {stableSort(liderTutor, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <Row
                      key={row.id}
                      row={row}
                      handleChangeStatus={handleChangeStatus}
                    />
                  ))}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={liderTutor.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por página"
            labelDisplayedRows={({ from, to, count }) => {
              return '' + from + '-' + to + ' de ' + count;
            }}
          />
        </Paper>
      )}
    </Box>
  );
}
