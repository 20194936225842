import { CadastroDeFuncoes } from 'Pages/CadastroDeFuncoes';
import { CadastroFuncionario } from 'Pages/CadastroFuncionario';
import { CadastroLiderTutor } from 'Pages/CadastroLiderTutor';
import { Contratos } from 'Pages/Contratos/List';
import { Funcionarios } from 'Pages/Funcionarios';
import { LiderTutor } from 'Pages/LiderTutor';
import { LoadingPage } from 'Pages/LoadingPage';
import { EntregaPacote } from 'Pages/Pacotes/Form';
import Header from 'components/Header';
import { AuthContext, AuthProvider } from 'context/auth';
import { MenuContext } from 'context/menuContext';
import { useContext, useState } from 'react';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes
} from 'react-router-dom';
import Error404 from '../Pages/404';
// import { Home } from '../Pages/Home';
import { Login } from '../Pages/Login';
import { Pacotes } from '../Pages/Pacotes/List';
import { RedefinirSenha } from '../Pages/RedefinirSenha';
import { Timeline } from '../Pages/Timeline';
import { Home } from 'Pages/Home';

const AppRoutes = () => {
  const [noNavbar, setNoNavbar] = useState(false);
  const [noFooter, setNoFooter] = useState(false);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState('');
  const [loadingContext, setLoadingContext] = useState(false);
  const Private = ({ children }) => {
    const { authenticated, loading } = useContext(AuthContext);

    if (loading) {
      return <LoadingPage />;
    }

    if (!authenticated) {
      return <Navigate to="/login" />;
    }

    return children;
  };

  return (
    <MenuContext.Provider
      value={{
        open,
        setOpen,
        page,
        setPage,
        noFooter,
        setNoFooter,
        noNavbar,
        setNoNavbar,
        loadingContext,
        setLoadingContext
      }}
    >
      <Router>
        <AuthProvider>
          <Header>
            <Routes>
              <Route exact path="/login" element={<Login />} />
              <Route
                exact
                path="/"
                element={
                  <Private>
                    <Home />
                  </Private>
                }
              />
              <Route
                exact
                path="/pacote"
                element={
                  <Private>
                    <EntregaPacote />
                  </Private>
                }
              />
              <Route
                exact
                path="/pacote/:id"
                element={
                  <Private>
                    <EntregaPacote />
                  </Private>
                }
              />
              <Route
                exact
                path="/redefinir-senha"
                element={<RedefinirSenha />}
              />
              <Route
                exact
                path="/redefinir-senha/:redirectEmail/:redirectToken"
                element={<RedefinirSenha />}
              />
              <Route
                exact
                path="/projetos"
                element={
                  <Private>
                    <Contratos />
                  </Private>
                }
              />
              <Route
                exact
                path="/timeline/:projectId/:packageId"
                element={
                  <Private>
                    <Timeline />
                  </Private>
                }
              />
              <Route
                exact
                path="/pacotes/:filtro"
                element={
                  <Private>
                    <Pacotes />
                  </Private>
                }
              />
              <Route
                exact
                path="/pacotes"
                element={
                  <Private>
                    <Pacotes />
                  </Private>
                }
              />
              <Route
                exact
                path="/cadastroDeFuncoes"
                element={
                  <Private>
                    <CadastroDeFuncoes />
                  </Private>
                }
              />
              <Route
                exact
                path="/funcionario"
                element={
                  <Private>
                    <CadastroFuncionario />
                  </Private>
                }
              />
              <Route
                exact
                path="/edit/:mode/:id"
                element={
                  <Private>
                    <CadastroFuncionario />
                  </Private>
                }
              />
              <Route
                exact
                path="/funcionarios"
                element={
                  <Private>
                    <Funcionarios />
                  </Private>
                }
              />
              <Route
                exact
                path="/liderTutor"
                element={
                  <Private>
                    <LiderTutor />
                  </Private>
                }
              />
              <Route
                exact
                path="/cadastroLiderTutor"
                element={
                  <Private>
                    <CadastroLiderTutor />
                  </Private>
                }
              />
              <Route
                exact
                path="/editLiderTutor/:id"
                element={
                  <Private>
                    <CadastroLiderTutor />
                  </Private>
                }
              />
              <Route
                path="*"
                element={
                  <Private>
                    <Error404 />
                  </Private>
                }
              />
            </Routes>
          </Header>
        </AuthProvider>
      </Router>
    </MenuContext.Provider>
  );
};

export default AppRoutes;
