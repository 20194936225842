import { yupResolver } from '@hookform/resolvers/yup';
import {
  Backdrop,
  Box,
  CircularProgress,
  FormHelperText,
  makeStyles,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
  Typography
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import { Collapse, List } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { DocumentTitle } from 'components/DocumentTitle';
import api from 'config/api';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as yup from 'yup';
import {
  Container,
  ContainerButtons,
  ContainerDatas,
  ContainerNovoProjeto,
  Wrapper,
  Skeleton
} from './styles';

const workerValidation = yup.object().shape({
  clientId: yup.string().required('Campo obrigatório!'),
  funcao: yup.string().required('Campo obrigatório!'),
  workerName: yup.string().required('Campo obrigatório!'),
  login: yup.string().required('Campo obrigatório!'),
  email: yup
    .string()
    .email('Insira um endereço de e-mail válido (email@exemplo.com)')
    .required('Campo obrigatório!'),
  password: yup
    .string()
    .required('Campo obrigatório!')
    .min(8, 'A senha deve ter pelo menos 8 caracteres.')
    .max(50, 'A senha não pode ter mais de 50 caracteres.')
    .oneOf([yup.ref('confirmarSenha'), null], 'As senhas precisam ser iguais'),
  confirmarSenha: yup
    .string()
    .required('Campo obrigatório!')
    .min(8, 'A senha deve ter pelo menos 8 caracteres.')
    .max(50, 'A senha não pode ter mais de 50 caracteres.')
    .oneOf([yup.ref('password'), null], 'As senhas precisam ser iguais')
});

const contractsValidation = yup.object().shape({
  contracts: yup.array().of(
    yup.object().shape({
      contractId: yup.string().required('Campo obrigatório!'),
      dataInicio: yup.string().required('Campo obrigatório')
    })
  )
});

const MySwal = withReactContent(Swal);

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      // width: '25ch',
      color: 'red'
    }
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  }
}));

const steps = ['Funcionário', 'Contratos'];

export const CadastroFuncionario = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [loadingGet, setLoadingGet] = useState(false);
  const [clients, setClients] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [workerContracts, setWorkerContracts] = useState([]);
  const [selectedContracts, setSelectedContracts] = useState([]);
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const { id, mode } = useParams();

  const [errorMessage, setErrorMessage] = useState({});

  const [model, setModel] = useState({
    clientId: '',
    workerName: '',
    funcao: '',
    login: '',
    email: '',
    password: ''
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(workerValidation)
  });

  const {
    register: registerContracts,
    handleSubmit: handleSubmitContracts,
    setValue: setContractsValue,
    clearErrors: clearContractsErrors,
    formState: { errors: contractsErrors }
  } = useForm({
    resolver: yupResolver(contractsValidation)
  });

  const handleSubmitForm = () => {
    if ((!id || mode === 'contratos') && !dateValid()) {
      return;
    }

    if (id) {
      if (mode === 'funcionario') {
        handleUpdateWorker();
      } else if (mode === 'contratos') {
        handleUpdateContracts();
      }
    } else {
      if (step === 0) {
        setStep(1);
      } else {
        handlePostWorker();
      }
    }
  };

  const handleUpdateWorker = () => {
    setLoading(true);

    api
      .put(`workers/${id}`, {
        clientId: model.clientId,
        funcao: model.funcao,
        workerName: model.workerName,
        login: model.login,
        email: model.email,
        password: model.password
      })
      .then(resp => {
        MySwal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Funcionário atualizado com sucesso'
        }).then(() => {
          navigate('/funcionarios');
        });
      })
      .catch(err => {
        MySwal.fire({
          icon: 'error',
          title: 'Erro ao cadastrar funcionário',
          text: err.response.data.message
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUpdateContracts = () => {
    setLoading(true);
    let delContracts = [...workerContracts];
    let putFailed = false; // Variável para rastrear falhas de PUT

    selectedContracts.forEach(selected => {
      let isPresent = false;

      workerContracts.forEach((old, index) => {
        if (selected?.contractId === old.contractId) {
          //put
          delContracts.splice(index, 1);
          isPresent = true;

          api
            .put(`/workerContract/${old.id}`, {
              contractId: selected.contractId,
              workerId: model.workerId,
              startDate: selected.startDate + 'T03:00:00.000Z',
              endDate: selected.endDate
                ? selected.endDate + 'T03:00:00.000Z'
                : null
            })
            .then(resp => {
              MySwal.fire({
                icon: 'success',
                title: 'Sucesso',
                text: 'Funcionário atualizado com sucesso'
              }).then(() => {
                navigate('/funcionarios');
              });
              setLoading(false);
            })
            .catch(error => {
              putFailed = true; // Marcar falha no PUT
              setErrorMessage({ login: error.response.data.message });
              MySwal.fire({
                icon: 'error',
                title: 'Erro',
                text: error.response.data.message
              });
              setLoading(false);
            });
        }
      });

      if (!isPresent && !putFailed) {
        api.post(`/workerContract/inserirWorkerContract`, {
          contractId: selected.contractId,
          workerId: model.workerId,
          startDate: selected.startDate + 'T03:00:00.000Z',
          endDate: selected.endDate ? selected.endDate + 'T03:00:00.000Z' : null
        });
      }
    });

    delContracts.forEach(item => {
      api
        .delete(`/workerContract/${item.id}`)
        .then(resp => {
          MySwal.fire({
            icon: 'success',
            title: 'Sucesso',
            text: 'Funcionário deletado com sucesso'
          });
          navigate('/funcionarios');
        })
        .catch(error => {
          setErrorMessage({ login: error.response.data.message });
          MySwal.fire({
            icon: 'error',
            title: 'Erro',
            text: `Erro ao deletar contrato`
          });
        });
    });
  };

  const handlePostWorker = () => {
    setLoading(true);
    api
      .post('/workers', model)
      .then(response => {
        handlePostContracts(response.data.id);
        setStep(1);

        MySwal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Funcionário criado com sucesso'
        }).then(() => {
          navigate('/funcionarios');
        });
      })
      .catch(error => {
        setErrorMessage({ login: error.response.data.message });
        setStep(0);
        MySwal.fire({
          icon: 'error',
          title: 'Erro',
          text: error.response.data.message
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePostContracts = id => {
    setLoading(true);

    selectedContracts.forEach(item => {
      api
        .post('/workerContract/inserirWorkerContract', {
          contractId: item.contractId,
          workerId: id,
          startDate: item.startDate + 'T03:00:00.000Z',
          endDate: item.endDate ? item.endDate + 'T03:00:00.000Z' : null
        })
        .then(response => {})
        .catch(error => {
          //TODO: error
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const dateValid = () => {
    let isValid = true;
    setSelectedContracts(
      selectedContracts.map(item => {
        if (
          item.startDate < item.minDate ||
          new Date(item.startDate.split('.')[0]) > new Date()
        ) {
          isValid = false;
          return { ...item, error: true };
        }

        return { ...item, error: false };
      })
    );
    return isValid;
  };

  const getClients = async () => {
    try {
      const response = await api.get('/clients');
      setClients(response.data);
    } catch (error) {
      //TODO: error
    }
  };

  const getContractsByClientId = async id => {
    try {
      const response = await api.get(
        `/contracts/getAllByClientIdDropDown/${id}`
      );
      setContracts(response.data);
    } catch (error) {
      //TODO: error
    }
  };

  const updateModel = e => {
    setModel({ ...model, [e.target.name]: e.target.value });
  };

  const setContractDate = (e, index) => {
    setSelectedContracts(
      selectedContracts.map((element, i) => {
        if (i === index) {
          return {
            ...element,
            [e.target.id]: e.target.value
          };
        }

        return element;
      })
    );
  };

  const handleRemoveItem = index => {
    let lista = selectedContracts;
    lista.splice(index, 1);
    setSelectedContracts([...lista]);

    clearContractsErrors();
    setContractsValue(`contracts`, []);
  };

  const handleChangeDropDown = (e, index) => {
    api.get(`/contracts/${e.target.value}`).then(response => {
      setSelectedContracts(
        selectedContracts.map((element, i) => {
          if (i === index) {
            return {
              ...element,
              contractId: e.target.value,
              minDate: response.data.dateCad.split('T')[0]
            };
          }
          return element;
        })
      );
    });
  };

  const getWorkers = useCallback(async () => {
    setLoadingGet(true);
    try {
      const workerResponse = await api.get(`/workers/${id}`);
      setModel({
        login: workerResponse.data.login,
        clientId: workerResponse.data.clientId,
        workerName: workerResponse.data.workerName,
        funcao: workerResponse.data.funcao,
        email: workerResponse.data.email,
        workerId: workerResponse.data.workerId
      });

      await getContractsByClientId(workerResponse.data.clientId);

      const contractsResp = await api.get(
        `/workerContract/listarTodosPorWorker/${workerResponse.data.id}`
      );
      setSelectedContracts(
        contractsResp.data.map(item => {
          return {
            id: item.id,
            contractId: item.contractId,
            startDate: item.startDate,
            endDate: item.endDate
          };
        })
      );

      setWorkerContracts([...contractsResp.data]);

      setValue('clientId', workerResponse.data.clientId);
      setValue('funcao', workerResponse.data.funcao);
      setValue('workerName', workerResponse.data.workerName);
      setValue('login', workerResponse.data.login);
      setValue('email', workerResponse.data.email);
    } catch (error) {
      console.error('Erro ao obter trabalhadores:', error);
    } finally {
      setLoadingGet(false);
    }
  }, [id, setValue]);

  useEffect(() => {
    getClients();

    if (id) {
      getWorkers();
    }
  }, [id, getWorkers]);

  return (
    <Container
      style={{
        height: 'auto',
        minHeight: 'calc(100vh - 164px)',
        paddingTop: '0px',
        padding: '30px'
      }}
    >
      <Backdrop style={{ zIndex: 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <DocumentTitle>
        {id
          ? mode === 'funcionario'
            ? 'Edição de funcionários - R2D2'
            : 'Edição de projetos do funcionário - R2D2'
          : 'Cadastro de funcionário - R2D2'}
      </DocumentTitle>

      <Wrapper>
        <Typography variant="h5" gutterBottom>
          {id
            ? mode === 'funcionario'
              ? 'Editar funcionário'
              : 'Editar contratos'
            : 'Cadastrar funcionário'}
        </Typography>

        {!id && (
          <Box sx={{ width: '100%' }}>
            <Stepper
              activeStep={step}
              alternativeLabel
              style={{ backgroundColor: 'inherit' }}
            >
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        )}

        {((!id && step === 0) || mode === 'funcionario') && (
          <form
            className={classes.root}
            onSubmit={handleSubmit(handleSubmitForm)}
            style={loadingGet ? { width: '100%' } : {}}
          >
            {loadingGet ? (
              <div style={{ width: '100%' }}>
                <Skeleton height={100}></Skeleton>
                <Skeleton height={100}></Skeleton>
                <Skeleton height={100}></Skeleton>
                <Skeleton height={100}></Skeleton>
                <Skeleton height={100}></Skeleton>
                <div style={{ flexDirection: 'row', display: 'flex', gap: 10 }}>
                  <Skeleton height={100}></Skeleton>
                  <Skeleton height={100}></Skeleton>
                </div>
              </div>
            ) : (
              <>
                <FormControl
                  className={classes.formControl}
                  variant="outlined"
                  fullWidth
                  style={{ backgroundColor: '#fff' }}
                >
                  <InputLabel id="cliente-label">Cliente</InputLabel>
                  <Select
                    labelId="cliente-label"
                    id="cliente"
                    value={model.clientId}
                    name="clientId"
                    label="Cliente"
                    disabled={id ? true : false}
                    {...register('clientId')}
                    error={errors.clientId?.message ? true : false}
                    onChange={e => {
                      updateModel(e);
                      getContractsByClientId(e.target.value);
                      setSelectedContracts([]);
                    }}
                  >
                    {clients.map(each => (
                      <MenuItem key={each.id} value={each.id}>
                        {each.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText style={{ color: '#d32f2f' }}>
                    {errors.clientId?.message}
                  </FormHelperText>
                </FormControl>

                <TextField
                  name="funcao"
                  id="funcao"
                  style={{ width: '100%', backgroundColor: '#fff' }}
                  label="Função"
                  variant="outlined"
                  type="text"
                  inputProps={{ maxLength: 100 }}
                  value={model.funcao}
                  {...register('funcao')}
                  helperText={errors.funcao?.message}
                  error={errors.funcao?.message ? true : false}
                  onChange={updateModel}
                />

                <TextField
                  name="workerName"
                  id="workerName"
                  style={{ width: '100%', backgroundColor: '#fff' }}
                  label="Nome"
                  variant="outlined"
                  type="text"
                  inputProps={{ maxLength: 100 }}
                  value={model.workerName}
                  {...register('workerName')}
                  helperText={errors.workerName?.message}
                  error={errors.workerName?.message ? true : false}
                  onChange={updateModel}
                />

                <TextField
                  name="login"
                  id="login"
                  placeholder="email@exemplo.com"
                  style={{ width: '100%', backgroundColor: '#fff' }}
                  label="Login"
                  variant="outlined"
                  type="text"
                  inputProps={{ maxLength: 50 }}
                  value={model.login}
                  {...register('login')}
                  helperText={errors.login?.message || errorMessage.login}
                  error={
                    errors.login?.message || errorMessage.login ? true : false
                  }
                  onChange={updateModel}
                />

                <TextField
                  name="email"
                  id="email"
                  placeholder="email@exemplo.com"
                  style={{ width: '100%', backgroundColor: '#fff' }}
                  label="Email"
                  variant="outlined"
                  type="text"
                  inputProps={{ maxLength: 50 }}
                  value={model.email}
                  {...register('email')}
                  helperText={errors.email?.message}
                  error={errors.email?.message ? true : false}
                  onChange={updateModel}
                />

                <div style={{ display: 'flex', gap: 10 }}>
                  <FormControl fullWidth>
                    <TextField
                      name="password"
                      id="password"
                      style={{ width: '100%', backgroundColor: '#fff' }}
                      label="Senha"
                      variant="outlined"
                      type="password"
                      inputProps={{ maxLength: 50 }}
                      {...register('password')}
                      helperText={errors.password?.message}
                      error={errors.password?.message ? true : false}
                      onChange={updateModel}
                    />
                  </FormControl>

                  <FormControl fullWidth>
                    <TextField
                      name="confirmarSenha"
                      id="confirmarSenha"
                      style={{ width: '100%', backgroundColor: '#fff' }}
                      label="Confirmar senha"
                      variant="outlined"
                      type="password"
                      inputProps={{ maxLength: 50 }}
                      {...register('confirmarSenha')}
                      helperText={errors.confirmarSenha?.message}
                      error={errors.confirmarSenha?.message ? true : false}
                    />
                  </FormControl>
                </div>

                <ContainerButtons>
                  <Button
                    sx={{ backgroundColor: '#2D8DA0' }}
                    variant="contained"
                    onClick={() => navigate('/funcionarios')}
                  >
                    Cancelar
                  </Button>

                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={loading}
                    sx={{ backgroundColor: '#2D8DA0' }}
                  >
                    {id ? 'Confirmar' : 'Avançar'}
                  </LoadingButton>
                </ContainerButtons>
              </>
            )}
          </form>
        )}

        {((!id && step === 1) || mode === 'contratos') && (
          <React.Fragment>
            <form onSubmit={handleSubmitContracts(handleSubmitForm)}>
              <Button
                variant="contained"
                style={{ width: 250, height: 50, margin: '40px 0px' }}
                disabled={selectedContracts.length >= contracts.length}
                onClick={() =>
                  setSelectedContracts([
                    ...selectedContracts,
                    { contractId: '', startDate: '' }
                  ])
                }
              >
                Vincular novo projeto
              </Button>

              <Box sx={{ mt: 1 }}>
                <List>
                  <TransitionGroup>
                    {selectedContracts.map((item, index) => (
                      <Collapse key={index}>
                        <ContainerNovoProjeto>
                          <FormControl
                            className={classes.formControl}
                            variant="outlined"
                            fullWidth
                            style={{
                              backgroundColor: '#fff',
                              minHeight: '5rem',
                              minWidth: 250
                            }}
                          >
                            <InputLabel id="projeto-label">Projeto</InputLabel>
                            <Select
                              labelId="projeto-label"
                              id="projeto"
                              name={`contractId`}
                              label="Projeto"
                              value={item.contractId}
                              {...registerContracts(
                                `contracts[${index}].contractId`
                              )}
                              error={
                                contractsErrors.contracts &&
                                contractsErrors.contracts[index]?.contractId
                                  ? true
                                  : false
                              }
                              onChange={e => {
                                handleChangeDropDown(e, index);
                              }}
                            >
                              {contracts
                                .filter(element =>
                                  selectedContracts.find(
                                    each =>
                                      each.contractId === element.id &&
                                      element.id !== item.contractId
                                  )
                                    ? false
                                    : true
                                )
                                .map(each => (
                                  <MenuItem key={each.id} value={each.id}>
                                    {each.descricao}
                                  </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText style={{ color: '#d32f2f' }}>
                              {contractsErrors.contracts &&
                                contractsErrors.contracts[index]?.contractId
                                  ?.message}
                            </FormHelperText>
                          </FormControl>

                          <ContainerDatas>
                            <FormControl fullWidth>
                              <TextField
                                name="startDate"
                                id="startDate"
                                style={{
                                  width: '100%',
                                  backgroundColor: '#fff',
                                  minHeight: '5rem'
                                }}
                                variant="outlined"
                                type="date"
                                value={item.startDate}
                                label={'Data de início'}
                                InputLabelProps={{
                                  shrink: true
                                }}
                                {...registerContracts(
                                  `contracts[${index}].dataInicio`
                                )}
                                helperText={
                                  (contractsErrors.contracts &&
                                    contractsErrors.contracts[index]?.dataInicio
                                      ?.message) ||
                                  (item.error && 'Data inválida')
                                }
                                error={
                                  (contractsErrors.contracts &&
                                  contractsErrors.contracts[index]?.dataInicio
                                    ? true
                                    : false) || item.error
                                }
                                onChange={e => setContractDate(e, index)}
                              />
                            </FormControl>
                            <FormControl fullWidth>
                              <TextField
                                onChange={e => setContractDate(e, index)}
                                name="endDate"
                                id="endDate"
                                style={{
                                  width: '100%',
                                  backgroundColor: '#fff',
                                  minHeight: '5rem'
                                }}
                                label="Data de fim"
                                type="date"
                                value={item.endDate}
                                InputLabelProps={{
                                  shrink: true
                                }}
                                error={false}
                                helperText={''}
                              />
                            </FormControl>

                            <Tooltip
                              title="deletar"
                              arrow
                              style={{ marginLeft: 15 }}
                            >
                              <DeleteIcon
                                onClick={() => handleRemoveItem(index)}
                                sx={{ cursor: 'pointer', color: 'gray' }}
                              />
                            </Tooltip>
                          </ContainerDatas>
                        </ContainerNovoProjeto>
                      </Collapse>
                    ))}
                  </TransitionGroup>
                </List>
              </Box>

              <ContainerButtons>
                {selectedContracts.find(item => item.error === true) && (
                  <p>
                    A data de início deve ser posterior a data de criação do
                    contrato e anterior a data de hoje
                  </p>
                )}

                <Button
                  sx={{ backgroundColor: '#2D8DA0', color: '#FFF ' }}
                  type="button"
                  color="primary"
                  onClick={() => (id ? navigate('/funcionarios') : setStep(0))}
                >
                  {id ? 'Cancelar' : 'Voltar'}
                </Button>

                <LoadingButton
                  sx={{
                    backgroundColor: '#2D8DA0',
                    color: '#FFF'
                  }}
                  type="submit"
                  loading={loading}
                >
                  Confirmar
                </LoadingButton>
              </ContainerButtons>
            </form>
          </React.Fragment>
        )}
      </Wrapper>
    </Container>
  );
};
