import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import { useParams } from 'react-router-dom';
import { formatDateString } from 'util/dateFormat.js';
import { CardContainer } from './styles.js';

export const CardSalvo = ({
  id,
  title,
  description,
  onClick,
  i,
  style,
  date,
  typeIconSearch,
  typeIconTerm,
  workerName
}) => {
  const { packageId } = useParams();

  return (
    <CardContainer
      key={id}
      border={packageId === id}
      onClick={onClick}
      style={style}
    >
      <div onClick={onClick}>
        <div className="card-icon">
          {typeIconSearch}
          <div style={{ marginLeft: '3px' }}>{typeIconTerm}</div>
        </div>
        <CmtCardHeader className="card-header">
          <h4 title="Versão da entrega">{title ? title : 'Sem título'}</h4>
        </CmtCardHeader>
        <CmtCardContent className="card-body">
          {description ? (
            <>
              <p title="Título da entrega">
                {description.length >= 50
                  ? description.slice(0, 50).trim() + '...'
                  : description}
              </p>
              <p title="Data que a entrega foi cadastrada">
                {date ? formatDateString(date) : '-'}
              </p>
              <p title="Usuário que cadastrou entrega">{workerName}</p>
            </>
          ) : (
            'Sem descrição'
          )}
        </CmtCardContent>
      </div>
    </CardContainer>
  );
};
