import React, { useState } from 'react';
import { Container } from 'global/styles';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { IconButton, Modal } from '@material-ui/core';
import { ContainerModal } from './styles';
import CloseIcon from '@mui/icons-material/Close';
import { returnDefaultImage } from 'util/imageUtils';
import { FullscreenModal } from 'components/FullscreenModal';

export const FilesModal = ({ list, open, onClose }) => {
  const [fullscreen, setFullscreen] = useState(false);
  const openFullscreen = () => setFullscreen(true);
  const closeFullscreen = () => setFullscreen(false);
  const [actualImg, setActualImage] = useState({
    path: '',
    title: '',
    format: ''
  });

  return (
    <>
      <FullscreenModal
        open={fullscreen}
        onClose={closeFullscreen}
        img={actualImg}
      />
      <Modal
        open={open}
        onClose={onClose}
        BackdropProps={{
          timeout: 500
        }}
      >
        <ContainerModal>
          <Container justify="flex-end" padding="0 0 10px 0">
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Container>
          <ImageList sx={{ width: 800, height: '70vh' }}>
            {list.map(item => {
              const { img, render } = returnDefaultImage(item.img, item.format);
              return (
                <ImageListItem key={item.img}>
                  <img
                    src={`${img}?w=248&fit=crop&auto=format`}
                    srcSet={`${img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.title}
                    loading="lazy"
                    onClick={() => {
                      if (!render) return;
                      setActualImage({
                        path: img,
                        title: item.title,
                        format: item.format
                      });
                      openFullscreen();
                    }}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = 'https://i.imgur.com/VmKzR6T.png';
                    }}
                  />
                  <ImageListItemBar
                    title={item.title}
                    subtitle={<span>Arquivo {item.format}</span>}
                    position="below"
                  />
                </ImageListItem>
              );
            })}
          </ImageList>
        </ContainerModal>
      </Modal>
    </>
  );
};
