import styled from 'styled-components';

export const SalvosContainer = styled.main`
  background: #f6f8fa;
  .salvos {
    display: flex;
    flex-direction: column;
    padding: 0 10%;
  }
  .container-filtro {
    display: flex;
    justify-content: space-between;
    padding: 40px 0;
  }
  .card-container {
    display: flexbox;
    flex-wrap: wrap;
  }
  .skeleton-card {
    margin: 0 30px 30px 0;
    border-radius: 10px;
  }
`;

export const Container = styled.main`
  display: flex;
  justify-content: center;
  padding: 40px 0%;
  background-color: white;
  .upload {
    margin: 15px 5px;
    background: #22c55e;
  }

  .upload:hover {
    background: #16a34a;
  }

  .text-area {
    min-height: 80px;
    min-width: 614px;
    max-height: 300px;
    max-width: 614px;
    margin: 8px;
    font-size: 14px;
    border: 1px solid #cccccc;
    outline-color: #0369a1;
    border-radius: 3px;
    padding: 12px;
  }

  .text-area {
    font-family: Montserrat, 'Times New Roman';
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;
`;

export const Counter = styled.div`
  padding: 0px 10px;
  display: flex;
  justify-content: flex-end;
`;
