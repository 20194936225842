import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: ${props => (props.justify ? props.justify : 'flex-start')};
  align-items: ${props => (props.align ? props.align : '')};
  flex-direction: ${props => (props.flex ? props.flex : 'row')};
  margin: ${props => (props.margin ? props.margin : '')};
  padding: ${props => (props.padding ? props.padding : '')};
  width: ${props => (props.w ? props.w : '')};
  height: ${props => (props.h ? props.h : '')};
`;

export const ContainerCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  min-height: 37vh;

  .cardProjeto {
    display: flex;
    gap: 20px;
    background-color: white;
    border: 1px solid #ccc;
    height: 210px;
    width: 425px;
    cursor: pointer;
    box-shadow: 4px 0px 4px -3px #00000029;
    transition: 0.3s ease-in-out;
  }

  .cardProjeto:hover {
    transform: scale(1.02);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }

  .containerHeaderCard {
    display: flex;
    flex-direction: row;
  }

  .containerDescriptionAll {
    font-size: 12px;
    background: white;
    width: 100%;
    flex-wrap: wrap;
    padding: 15px;
    height: 150px;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .containerRow {
    width: 100%;
    display: flex;
    height: 15px;
  }

  .containerDescription {
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
    height: 40px;
    /* min-width: 150px; */
    margin-left: -15px;
    gap: 5px;
  }

  .container {
    display: flex;
  }

  .column {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 46%;
    justify-content: center;
    align-items: flex-start;
  }

  .skeleton-card {
    margin: 0 30px 30px 0;
    border-radius: 10px;
  }
`;

export const ContainerCardContrato = styled.div`
  display: flex;
  /* width: 900px; */
  margin: 40px 0px;
  /* margin-bottom: 100px; */
  overflow: auto;

  ::-webkit-scrollbar {
    background: #e3e3e3;
    height: 10px;
  }
  ::-webkit-scrollbar-button {
    width: 10px;
  }
`;

export const InputCopy = styled.input`
  padding: 6px;
  margin: 6px 0;
  border-radius: 4px;
  border: 1px solid gray;
`;

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;
`;

export const Counter = styled.div`
  padding: 0px 10px;
  display: flex;
  justify-content: flex-end;
`;
