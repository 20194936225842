import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;

  width: 100%;
  max-width: 600px;
  padding: 20px;
`;

export const ContainerDatas = styled.div`
  display: flex;
  gap: 10px;
`;

export const ContainerButtons = styled.div`
  display: flex;
  gap: 20px;
  justify-content: flex-end;

  button {
    width: 100px;
  }
`;
