import React from 'react';
import { CommentContainer, Data, Title } from './styles';
import Box from '@mui/material/Box';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { formatDate2 } from 'util/dateFormat.js';
import { Avatar } from '@material-ui/core';

export const CommentPart = ({ name, date, image, comment }) => {
  function extrairIniciaisDoisPrimeirosNomes(nomeCompleto) {
    // Verificar se o nome é uma string
    if (typeof nomeCompleto === 'string') {
      // Dividir o nome completo em palavras
      var palavras = nomeCompleto.split(' ');

      // Extrair a primeira letra de cada palavra dos dois primeiros nomes
      var iniciais = palavras.slice(0, 2).map(function (palavra) {
        return palavra.charAt(0).toUpperCase(); // Convertendo para maiúscula
      });

      // Juntar as iniciais em uma única string
      return iniciais.join('');
    } else {
      // Caso o nome não seja uma string
      console.error('Nome inválido. Certifique-se de que é uma string.');
      return null;
    }
  }

  return (
    <CommentContainer>
      <Box id="info-box">
        <div className="background-icone">
          <Avatar
            style={{
              background: '#2D8DA0',
              width: '25px',
              height: '25px',
              fontSize: '10px',
              marginRight: '10px'
            }}
          >
            {extrairIniciaisDoisPrimeirosNomes(name)}
          </Avatar>
        </div>
        <div>
          <Title>{name}</Title>
          <Data>{formatDate2(date)}</Data>
        </div>
      </Box>
      <Box id="comment">
        <TextareaAutosize className="text-area" disabled value={comment} />
      </Box>
    </CommentContainer>
  );
};
