import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LogoutIcon from '@mui/icons-material/Logout';
import { Avatar } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { alpha, styled } from '@mui/material/styles';
import { AuthContext } from 'context/auth';
import { useContext, useState } from 'react';

const StyledMenu = styled(props => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        )
      }
    }
  }
}));

export function SubMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { logout } = useContext(AuthContext);
  const { user } = useContext(AuthContext);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function extrairIniciaisDoisPrimeirosNomes(nomeCompleto) {
    // Verificar se o nome é uma string
    if (typeof nomeCompleto === 'string') {
      // Dividir o nome completo em palavras
      var palavras = nomeCompleto.split(' ');

      // Extrair a primeira letra de cada palavra dos dois primeiros nomes
      var iniciais = palavras.slice(0, 2).map(function (palavra) {
        return palavra.charAt(0).toUpperCase(); // Convertendo para maiúscula
      });

      // Juntar as iniciais em uma única string
      return iniciais.join('');
    } else {
      // Caso o nome não seja uma string
      console.error('Nome inválido. Certifique-se de que é uma string.');
      return null;
    }
  }

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        style={{ color: 'white' }}
        disableElevation
        onClick={handleClick}
        startIcon={
          <div className="background-icone">
            <Avatar sx={{ bgcolor: '#2D8DA0' }}>
              {extrairIniciaisDoisPrimeirosNomes(user?.username)}
            </Avatar>
          </div>
        }
        endIcon={<KeyboardArrowDownIcon />}
      >
        {user?.username}
        <br></br>
        {user?.office}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {/* <MenuItem onClick={handleClose} disableRipple>
          <AccountBoxIcon />
          Perfil
        </MenuItem>
        <Divider sx={{ my: 0.5 }} /> */}
        <MenuItem onClick={logout} disableRipple>
          <LogoutIcon />
          Sair
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
