import { Avatar, Backdrop, CircularProgress } from '@material-ui/core';
import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import api from 'config/api';
import { AuthContext } from 'context/auth';
import { Container } from 'global/styles';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ContainerComment, ContainerImage } from './styles';

export const CompanyComment = ({ handleGetAll }) => {
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);
  const { packageId } = useParams();
  const { user } = useContext(AuthContext);

  function extrairIniciaisDoisPrimeirosNomes(nomeCompleto) {
    // Verificar se o nome é uma string
    if (typeof nomeCompleto === 'string') {
      // Dividir o nome completo em palavras
      var palavras = nomeCompleto.split(' ');

      // Extrair a primeira letra de cada palavra dos dois primeiros nomes
      var iniciais = palavras.slice(0, 2).map(function (palavra) {
        return palavra.charAt(0).toUpperCase(); // Convertendo para maiúscula
      });

      // Juntar as iniciais em uma única string
      return iniciais.join('');
    } else {
      // Caso o nome não seja uma string
      console.error('Nome inválido. Certifique-se de que é uma string.');
      return null;
    }
  }

  const handlePost = async e => {
    e.preventDefault();
    setLoading(true);

    const data = {
      r2enCdId: packageId,
      r2coDtComentario: new Date().toISOString(),
      r2coTxComentario: comment,
      workerId: user.profile === 'CUSTOMER' ? parseInt(user.userId) : null,
      contributorId: user.profile === 'CONTRIBUTOR' ? user.userId : null
    };
    try {
      await api.post('/comentario/inserirComentario', data);
      setComment('');
      handleGetAll();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handlePost}>
      <Backdrop style={{ zIndex: 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <ContainerComment>
        <ContainerImage>
          <Avatar
            style={{
              background: '#2D8DA0',
              width: '25px',
              height: '25px',
              fontSize: '10px'
            }}
          >
            {extrairIniciaisDoisPrimeirosNomes(user?.username)}
          </Avatar>
        </ContainerImage>
        <TextField
          id="outlined-multiline-static"
          label="Comentário"
          className="comment-input"
          onChange={e => setComment(e.target.value)}
          value={comment}
          multiline
          rows={4}
          inputProps={{ maxLength: 300 }}
        />
      </ContainerComment>
      <Container justify="flex-end">
        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          loading={loading}
          loadingIndicator="Salvando..."
          disabled={!comment.trim()}
        >
          Comentar
        </LoadingButton>
      </Container>
    </form>
  );
};
