import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles, TextField } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box, Button, IconButton, InputAdornment } from '@mui/material';
import { AlertsSnackBar } from 'components/AlertsSnackbar';
import { ContainerBox } from 'components/ContainerBox/index.jsx';
import { DocumentTitle } from 'components/DocumentTitle/index.js';
import { AuthContext } from 'context/auth.jsx';
import { MenuContext } from 'context/menuContext.js';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import * as C from './styles.js';

const validationLogin = yup.object().shape({
  email: yup.string().required('O campo de e-mail é obrigatório!'),
  password: yup
    .string()
    .required('O campo de senha é obrigatório!')
    .min(8, 'A senha deve ter pelo menos 8 caracteres.')
    .max(50, 'A senha não pode ter mais de 50 caracteres.')
});

const useHelperTextStyles = makeStyles(() => ({
  root: {
    color: 'red',
    marginLeft: '0'
  }
}));

export const Login = () => {
  const [visible, setVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useContext(AuthContext);
  const { setNoNavbar } = useContext(MenuContext);
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [erro, setErro] = useState('');

  const handleLogin = async e => {
    setLoading(true);
    try {
      await login(email, password);
    } catch (error) {
      setErro('Credenciais inválidas!');
      setOpenSnackbar(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    setNoNavbar(true);
  }, [setNoNavbar]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationLogin)
  });

  useEffect(() => {
    setValue('password', password);
  }, [password]);

  function handleVisible() {
    setVisible(!visible);
  }
  const helperTextStyles = useHelperTextStyles();

  return (
    <ContainerBox title="Login" onSubmit={handleSubmit(handleLogin)}>
      <DocumentTitle>Login - R2D2</DocumentTitle>

      <C.Container>
        {loading === true ? (
          <div className="loading-box">
            <CircularProgress className="loading" size="70px" />
          </div>
        ) : (
          <Box id="form-box">
            <TextField
              className="input"
              label="E-mail"
              variant="outlined"
              type="text"
              helperText={errors.email?.message}
              name="email"
              FormHelperTextProps={{
                classes: {
                  root: helperTextStyles.root
                }
              }}
              {...register('email')}
              onChange={e => setEmail(e.target.value)}
              id="inputEmailLogin"
            />
            <TextField
              className="input"
              label="Senha"
              FormHelperTextProps={{
                classes: {
                  root: helperTextStyles.root
                }
              }}
              type={visible ? 'text' : 'password'}
              helperText={errors.password?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleVisible}
                      id="iconShowHidePassword"
                      edge="end"
                    >
                      {visible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              variant="outlined"
              {...register('password')}
              onChange={e => setPassword(e.target.value)}
              id="inputPasswordLogin"
            />
            <Button
              variant="contained"
              className="btn"
              type="submit"
              id="buttonSubmitLogin"
            >
              Entrar
            </Button>
            <p>
              Esqueceu sua senha?
              <Link
                to="/redefinir-senha"
                style={{ color: ' #277d8b', marginLeft: '5px' }}
                id="linkForgotPasswordLogin"
              >
                Clique aqui.
              </Link>
            </p>
          </Box>
        )}
        <AlertsSnackBar
          mensagem={erro}
          open={openSnackbar}
          color="#d32f2f"
          tipoDeaviso="error"
          handleClose={() => setOpenSnackbar(false)}
        />
      </C.Container>
    </ContainerBox>
  );
};
