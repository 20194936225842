import styled from 'styled-components';

export const StyledBox = styled.div`
  box-sizing: border-box;
  background-color: white;
  min-height: calc(100vh - 164px);
  height: auto;
  padding: 20px;
`;

export const CardTextTitleQuantity = styled.p`
  font-size: 9px;
  color: '#253F57';
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;

  .textQuantity {
    margin-left: 1px;
    text-decoration: underline;
    margin-right: 5px;
  }
`;

export const CardTextQuantity = styled.p`
  margin-left: 3px;
  text-decoration: underline;
  margin-right: 3px;
`;

export const ContainerImageCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  gap: 5px;
  width: 30%;
  .nameImage {
    font-size: 12px;
    color: #2d8da0;
  }
`;
