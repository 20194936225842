import { Stack, Typography } from '@mui/material';
import { DocumentTitle } from 'components/DocumentTitle';
import { StyledBox } from './styles.js';

export const Home = () => {
  return (
    <StyledBox>
      <DocumentTitle>Home - R2D2</DocumentTitle>

      <Stack padding="40px 10%" spacing={4}>
        <Typography variant="h5" gutterBottom>
          Seja bem vindo!
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </Typography>
      </Stack>
    </StyledBox>
  );
};
