import IconButton from '@material-ui/core/IconButton';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Alert, Snackbar, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import { Breadcrumbs } from 'components/Breadcrumb';
import { ContratoTermoDeAceite } from 'components/ContratoTermoDeAceite';
import { DocumentTitle } from 'components/DocumentTitle';
import { TabPackageData } from 'components/TabPackageData';
import api from 'config/api';
import { AuthContext } from 'context/auth.jsx';
import { snackbarOptionsDefault } from 'default/feedback';
import { defaultPackage } from 'default/packages';
import { Container } from 'global/styles.js';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PesquisaAvaliacao } from '../PesquisaAvaliacao';
import { ContainerHeader } from './styles';
import { Skeleton } from '@material-ui/lab';

export const PackageDetails = ({ array, getDeliveries }) => {
  const [value, setValue] = useState('1');
  const [snackbarOptions, setSnackbarOptions] = useState(
    snackbarOptionsDefault
  );
  const navigate = useNavigate();
  const { projectId, packageId } = useParams();
  const { user } = useContext(AuthContext);
  const isAdmin = user.role === 'admin';
  const [model, setModel] = useState(defaultPackage);
  const [data, setData] = useState(null);

  const noNotify =
    model.r2enBlEnviarEmailCliente === false && model.r2enTxStatus !== 'C';
  const pathnames = [
    { name: 'Home', path: '/projetos' },
    { name: 'Projetos', path: '/projetos' },
    { name: 'Timeline', path: '' }
  ];

  const findPackageById = useCallback(async () => {
    if (!packageId) return;
    try {
      const { data } = await api.get(`/r2d2Entregas/${packageId}`);
      setModel(data.entrega);
      setData(data);
    } catch (error) {
      //TODO
    }
  }, [packageId]);

  console.log('data==>', data);

  useEffect(() => {
    findPackageById();
  }, [packageId, findPackageById]);

  const findNextId = () => {
    const listOfIds = array.map(e => e.r2enCdId);
    const indexOfId = listOfIds.indexOf(parseInt(packageId, 10));
    const nextP =
      indexOfId === listOfIds.length - 1
        ? listOfIds[0]
        : listOfIds[indexOfId + 1];
    navigate(`/timeline/${projectId}/${nextP}`);
  };

  const findPrevId = () => {
    const listOfIds = array.map(e => e.r2enCdId);
    const indexOfId = listOfIds.indexOf(parseInt(packageId, 10));
    const nextP =
      indexOfId === 0
        ? listOfIds[listOfIds.length - 1]
        : listOfIds[indexOfId - 1];
    navigate(`/timeline/${projectId}/${nextP}`);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOptions(snackbarOptionsDefault);
  };

  return (
    <Container padding="30px" flex="column">
      <title>Pacote versão {model.r2enTxVersaoEntrega}</title>
      <Snackbar
        open={snackbarOptions.visible}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarOptions.severity}
        >
          {snackbarOptions.message}
        </Alert>
      </Snackbar>
      <Stack spacing={2}>
        <Breadcrumbs pathnames={pathnames} />
        {isAdmin && noNotify ? (
          <>
            {data !== null ? (
              <Alert severity="info">
                <Stack spacing={6} direction="row">
                  <p>Este pacote não foi notificado ao cliente.</p>
                </Stack>
              </Alert>
            ) : (
              <Skeleton height={60}></Skeleton>
            )}
          </>
        ) : null}

        <ContainerHeader>
          <Tooltip title="Pacote anterior">
            <IconButton onClick={findPrevId}>
              <ArrowCircleLeftOutlinedIcon fontSize="large" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Próximo pacote">
            <IconButton onClick={findNextId}>
              <ArrowCircleRightOutlinedIcon fontSize="large" />
            </IconButton>
          </Tooltip>
        </ContainerHeader>
      </Stack>
      <TabContext value={value}>
        <Box pl="22px">
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="DADOS DO PACOTE" value="1" />
            <Tab label="TERMO DE ACEITE" value="2" />
            <Tab
              label="AVALIAÇÃO"
              value="3" /*onFocus={() => findByIdSatisfaction()} */
            />
          </TabList>
        </Box>
        <TabPanel value="1">
          <DocumentTitle>Pacote - Dados - R2D2</DocumentTitle>
          <TabPackageData model={model} data={data} />
        </TabPanel>
        <TabPanel value="2">
          <DocumentTitle>Pacote - Termo de Aceite - R2D2</DocumentTitle>
          <ContratoTermoDeAceite
            data={data}
            modelDetails={model}
            getDeliveries={getDeliveries}
          />
        </TabPanel>
        <TabPanel value="3">
          <DocumentTitle>Pacote - Avaliação - R2D2</DocumentTitle>
          <PesquisaAvaliacao getDeliveries={getDeliveries} />
        </TabPanel>
      </TabContext>
    </Container>
  );
};
