import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    // position: 'relative',
    // '& .MuiTextField-root': {
    //   margin: theme.spacing(1),
    //   width: '25ch',
    // },
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      background: '#fff'
    },
    '& .MuiSelect-root': {
      background: '#fff'
    }

    // MUDAR PARA ABSOLUTE
    // position: 'absolute',
  },
  primary: {
    50: '#dffafe',
    100: '#bde9f0',
    200: '#9ad9e4',
    300: '#74c9d8',
    400: '#50bacb',
    500: '#38a1b2',
    600: '#277d8b',
    700: '#185964',
    800: '#05373e',
    900: '#001418'
  },
  // formControl: {
  //   margin: theme.spacing(1),
  //   minWidth: 120,
  // },
  formControl: {
    margin: theme.spacing(1),
    width: '300px'
  },
  checkbox: {
    margin: '0 -3px'
    // minWidth: 120,
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: theme.spacing(4)
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    backgroundColor: '#fff',
    borderRight: '1px solid rgba(0, 0, 0, 0.12)'
  },
  drawerPaper: {
    width: drawerWidth,
    position: 'absolute'
  },
  drawerHeader: {
    display: 'flex',
    // height: '50vh',
    alignItems: 'center',
    // marginTop: '60px',
    // padding: theme.spacing(0, 2),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'space-between'
  },
  content: {
    flexGrow: 1,
    // height: '100%',
    // padding: theme.spacing(6),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  }
}));
