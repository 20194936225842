import React from 'react';
import Modal from '@mui/material/Modal';
import { Container } from 'global/styles';
import { ContainerButtons, ContainerModal, Title } from './styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export const AlertsModal = ({ title, open, onClose, buttons, children }) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        BackdropProps={{
          timeout: 500
        }}
      >
        <ContainerModal>
          <Container align="center" justify="space-between">
            <Title>{title || ''}</Title>
            <IconButton
              onClick={onClose}
              style={{ padding: '0px', margin: '0px' }}
            >
              <CloseIcon />
            </IconButton>
          </Container>
          <Container flex="column" align="center" justify="center">
            <Container>{children}</Container>
          </Container>
          <ContainerButtons>{buttons}</ContainerButtons>
        </ContainerModal>
      </Modal>
    </div>
  );
};
