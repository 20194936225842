import { DndContext, closestCenter } from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { yupResolver } from '@hookform/resolvers/yup';
import { Backdrop, CircularProgress, Modal } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import InfoIcon from '@mui/icons-material/Info';
import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormHelperText,
  Grow,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Axios from 'axios';
import { Breadcrumbs } from 'components/Breadcrumb';
import { DocumentTitle } from 'components/DocumentTitle';
import api, { apiGCP } from 'config/api';
import { ENVIRONMENT_TYPES } from 'constants/tipoAmbiente';
import { AuthContext } from 'context/auth';
import { defaultPackage } from 'default/packages';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as yup from 'yup';
import { SortableItem } from '../Form/SortableItem';
import { Container, ContainerButtons, Counter } from '../styles';
import './styles.css';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      background: '#fff'
    },
    '& .MuiSelect-root': {
      background: '#fff'
    },
    '& .Mui-error': {
      background: 'transparent'
    },
    '& .MuiFormHelperText-root': {
      color: 'red'
    }
  },
  formControl: {
    margin: theme.spacing(1),
    width: '300px'
  },
  checkbox: {
    margin: '0 -3px'
  },
  switch: {
    margin: theme.spacing(1)
  },
  multiline: {
    width: '614px'
  }
}));

const requiredField = 'Campo obrigatório!';
const urlGitHub = 'https://github.com/';
const campoHttp = 'É necessário que coloque http ou https';
const formatoData = 'Formato de data inválido (DD-MM-YYYY)';

const validation = yup.object().shape({
  r2enTxTituloEntrega: yup.string().required(requiredField).label('Descrição'),
  client: yup.number().positive(requiredField).required(requiredField),
  contrato: yup.number().positive(requiredField).required(requiredField),
  projectId: yup.string().required(requiredField),
  r2enTxVersaoEntrega: yup.string().required(requiredField),
  r2enDtPrevistaEntrega: yup
    .string()
    .required(requiredField)
    .matches(/^\d{4}-\d{2}-\d{2}$/, formatoData),
  r2enDtEfetivaEntrega: yup
    .string()
    .required(requiredField)
    .matches(/^\d{4}-\d{2}-\d{2}$/, formatoData),
  r2teCd: yup.string().required(requiredField),
  r2enTxAmbiente: yup.string().required(requiredField),
  r2enTxUrlVideoEvidencia: yup.string(),
  r2enTxUrlPlanoImplantacao: yup.string().url(campoHttp),
  r2enTxUrlPacote: yup.string(),
  r2enTxUrlScriptBanco: yup.string(),
  r2enTxObservacaoImplantacao: yup.string(),
  r2enTxEmails: yup.string()
});
const allowedExtensions = [
  'pdf',
  'jpg',
  'png',
  'jpeg',
  'doc',
  'xml',
  'xls',
  'xlsx',
  'mp4',
  'avi',
  'txt'
];
const MAX_FILE_SIZE_MB = 200; // Tamanho máximo do arquivo em MB

const fileValidation = yup.object().shape({
  // file: yup.mixed().required(requiredField),
  file: yup
    .mixed()
    .test(
      'fileType',
      'Apenas arquivos com as seguintes extensões são permitidos: txt, pdf, jpg, png, jpeg, doc, xml, xls, xlsx, mp4, avi.',
      value => {
        const fileExtension =
          (value && value.name.split('.').pop().toLowerCase()) ||
          value?.split('.').pop().toLowerCase();
        return fileExtension && allowedExtensions.includes(fileExtension);
      }
    )
    .test(
      'fileSize',
      `O tamanho máximo do arquivo é ${MAX_FILE_SIZE_MB} MB.`,
      value => {
        const fileSizeInMB = value && value.size / (1024 * 1024);
        return fileSizeInMB && fileSizeInMB <= MAX_FILE_SIZE_MB;
      }
    ),
  fileDesc: yup.string().required(requiredField)
});

export const EntregaPacote = () => {
  const classes = useStyles();
  const [uploadArquivo, setUploadArquivo] = useState(false);
  const [enderecoFtp, setEnderecoFtp] = useState(false);
  const [enderecoIc, setEnderecoIc] = useState(false);
  const [client, setClient] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [loadingSaving, setLoadingSaving] = useState(false);
  const [contracts, setContracts] = useState([]);
  const [projects, setProjects] = useState([]);

  const [deliveriesTypes, setDeliveriesTypes] = useState([]);
  const [clients, setClients] = useState([]);
  const { id } = useParams();

  const navigate = useNavigate();
  const [model, setModel] = useState(defaultPackage);
  const hasFtp =
    enderecoFtp ||
    (id &&
      (model?.r2enTxFtpUrl || model?.r2enTxFtpLogin || model?.r2enTxFtpSenha));
  const hasIc = enderecoIc || (id && model?.r2enTxUrlFonte);
  const noContracts = !client || !contracts.length;
  const noProjects = !projects.length;

  const [openModal, setOpenModal] = useState(false);
  const [file, setFile] = useState('');
  const [fileSQL, setFileSQL] = useState(null);
  const [ready, setReady] = useState(false);

  const [fileDesc, setFileDesc] = useState('');
  const [files, setFiles] = useState([]);
  const [originalFiles, setOriginalFiles] = useState([]);
  const [editFileIndex, setEditFileIndex] = useState(-1);
  const { user } = useContext(AuthContext);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validation)
  });

  const {
    register: fileRegister,
    handleSubmit: handleSubmitFile,
    setValue: setFileValue,
    clearErrors: clearFileErrors,
    formState: { errors: fileErrors }
  } = useForm({
    resolver: yupResolver(fileValidation)
  });

  useEffect(() => {
    if (model.r2enTxUrlPacote) {
      const newUrl = model.r2enTxUrlPacote.replace('https://github.com/', '');
      model.r2enTxUrlPacote = newUrl;
    }
  }, [model.r2enTxUrlPacote]);

  const MySwal = withReactContent(Swal);
  const pageName = id ? 'Editar Entrega' : 'Cadastrar Entrega';
  const pathnames = [
    { name: 'Home', path: '/' },
    { name: pageName, path: '/pacote' }
  ];

  const handlePost = async () => {
    setLoadingSaving(true);
    try {
      const filesPost = await handlePostFiles();
      const originalDate = new Date();
      const year = originalDate.getFullYear();
      const month = String(originalDate.getMonth() + 1).padStart(2, '0');
      const day = String(originalDate.getDate()).padStart(2, '0');
      const hours = String(originalDate.getHours()).padStart(2, '0');
      const minutes = String(originalDate.getMinutes()).padStart(2, '0');
      const seconds = String(originalDate.getSeconds()).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;

      const r2d2Arquivos = [];
      for (const file of filesPost) {
        const r2d2Arquivo = {
          r2aeTxNomeArquivo: file.nomeArquivoFinal,
          r2aeTxDescricao: file.fileDesc,
          usuaCdIdUltAlt: user?.userId,
          usuaCdIdCriacao: user?.userId
        };

        r2d2Arquivos.push(r2d2Arquivo);
      }

      const response = await api.post('/r2d2Entregas', {
        ...model,
        r2enNmDiasIntervalo: 0,
        r2enTxOutrosDocumentos: '',
        r2enTxEmails: model.r2enTxEmails.split(';'),
        usuaCdIdCriacao: user?.userId,
        usuaCdIdUltAlt: user?.userId,
        r2aeDtUltAlt: formattedDate,
        r2enTxUrlPacote: urlPacoteGitHub,
        arquivos: r2d2Arquivos
      });

      MySwal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: 'Pacote entregue com sucesso!'
      }).then(() => navigate(`/timeline/${model.projectId}/${response.data}`));
    } catch (error) {
      MySwal.fire({
        icon: 'error',
        title: 'Erro',
        text: error?.response?.data?.message
      });
    } finally {
      setLoadingSaving(false);
    }
  };

  const [urlPacoteGitHub, setUrlPacoteGitHub] = useState();

  useEffect(() => {
    setUrlPacoteGitHub(urlGitHub + model.r2enTxUrlPacote);
  }, [model.r2enTxUrlPacote]);

  const padraoUrlGithub = /https:\/\/github\.com\//;

  function filterObjects(arrayStrings, arrayObjetos) {
    const stringsFormatadas = arrayStrings.map(arr =>
      arr.map(str => (typeof str === 'string' ? str.substring(14) : str))
    );

    return arrayObjetos.filter(objeto => {
      const nomeArquivo = objeto.r2aeTxNomeArquivo;
      return !stringsFormatadas.some(arr =>
        arr.some(str => nomeArquivo === str)
      );
    });
  }

  const handlePut = async () => {
    setLoadingSaving(true);
    const correspondencias = urlPacoteGitHub?.match(padraoUrlGithub);
    if (correspondencias && correspondencias.length > 1) {
      const urlPacoteGitHubCorrect = urlPacoteGitHub?.replace(
        padraoUrlGithub,
        ''
      );
      setUrlPacoteGitHub(urlPacoteGitHubCorrect);
    }
    const filesAfterUpload = await handlePostFiles();

    let r2d2Arquivos = [];

    for (const file of filesAfterUpload) {
      const r2d2Arquivo = {
        r2aeTxNomeArquivo: file.nomeArquivoFinal,
        r2aeTxDescricao: file.fileDesc,
        usuaCdIdUltAlt: user?.userId,
        usuaCdIdCriacao: user?.userId
      };

      r2d2Arquivos.push(r2d2Arquivo);
    }

    const arrayUpdated = [];
    arrayUpdated?.push(files);
    arrayUpdated[0]?.push(...r2d2Arquivos);

    const repeatedString = [];
    repeatedString.push(r2d2Arquivos.map(e => e.r2aeTxNomeArquivo));

    const result = filterObjects(repeatedString, arrayUpdated[0]);
    setFiles(result);
    try {
      await api.put(`/r2d2Entregas/${id}`, {
        ...model,
        r2enTxUrlPacote: urlPacoteGitHub,
        r2enTxEmails: model.r2enTxEmails.split(';'),
        arquivos: result
      });
      handleUpdateFiles();

      MySwal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: 'Pacote editado com sucesso!'
      }).then(() => navigate(`/timeline/${model.projectId}/${id}`));
    } catch (error) {
      MySwal.fire({
        icon: 'error',
        title: 'Erro',
        text: error?.response?.data?.message
      });
    } finally {
      setLoadingSaving(false);
    }
  };

  async function handlePostFiles() {
    const objectsName = [];

    for (const item of filesGCP) {
      const formData = new FormData();
      formData.append('file', item?.file);

      const dataAtual = new Date();
      const ano = dataAtual.getFullYear();
      const mes = String(dataAtual.getMonth() + 1).padStart(2, '0');
      const dia = String(dataAtual.getDate()).padStart(2, '0');
      const hora = String(dataAtual.getHours()).padStart(2, '0');
      const minuto = String(dataAtual.getMinutes()).padStart(2, '0');
      const segundo = String(dataAtual.getSeconds()).padStart(2, '0');
      const nomeArquivo = item?.file?.name;
      const nomeArquivoFinal =
        ano + mes + dia + hora + minuto + segundo + nomeArquivo;
      const objectName = `r2d2-api/pacote/${nomeArquivoFinal}`;

      try {
        const result = await apiGCP.get(
          `/gcp/signedUrlForUpload?objectName=${objectName}`,
          formData
        );

        await Axios.put(result.data, item?.file);
        objectsName.push({
          objectName,
          nomeArquivoFinal,
          fileDesc: item.fileDesc
        });
      } catch (error) {
        console.error(error);
      }
    }

    return objectsName;
  }

  const handleUpdateFiles = () => {
    originalFiles.forEach(originalFile => {
      files.forEach(file => {
        if (originalFile.r2aeCdId === file.r2aeCdId) {
          //update
          api.put(
            `/r2d2-arquivo-entrega/update-descricao-arquivo/${file.r2aeCdId}`,
            {},
            { params: { descricao: file.r2aeTxDescricao } }
          );
        }
      });
    });

    files
      .filter(item => item.r2aeCdId === undefined)
      .forEach(item => {
        const formData = new FormData();
        formData.append('file', item.file);
        formData.append(
          'arquivoEntrega',
          new Blob(
            [
              JSON.stringify({
                entregaId: id,
                r2aeTxDescricao: item.descricao,
                usuaCdIdUltAlt: user.workerId,
                usuaCdIdCriacao: user.workerId
              })
            ],
            { type: 'application/json' }
          )
        );
      });
  };

  const onSubmit = async e => {
    if (id) {
      handlePut();
    } else {
      handlePost();
    }
  };

  const getClients = async () => {
    try {
      const response = await api.get('/clients');
      setClients(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (model.projectId) {
      getEmail(model.projectId);
    }
  }, [model.projectId]);

  function joinEmails(emails) {
    return emails.join(';');
  }

  const getEmail = async projectId => {
    try {
      const response = await api.get('/r2d2Entregas/emailsPadroesPorProjeto', {
        params: {
          projectId: projectId
        }
      });
      const emailsString = joinEmails(response.data);
      setModel({ ...model, r2enTxEmails: emailsString });
    } catch (error) {
      console.error(error);
    }
  };

  const getDeliveriesTypes = async () => {
    try {
      const response = await api.get('/r2d2TipoEntregas');
      setDeliveriesTypes(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const findContracts = async clientId => {
    setClient(clientId);
    try {
      const response = await api.get(
        `/contracts/getAllByClientIdDropDown/${clientId}`
      );
      setContracts(response.data);
    } catch (error) {}
  };

  const findProjects = async e => {
    try {
      updateModel(e);
      const response = await api.get(
        `/contracts/buscarProjetosDoContrato/${e.target.value}`
      );
      setProjects(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    getClients();
    getDeliveriesTypes();
  }, []);

  const findPackageById = useCallback(async () => {
    if (!id) {
      return;
    }
    try {
      const { data } = await api.get(`/r2d2Entregas/${id}`);
      findContracts(data.clientId);
      setClient(data.clientId);
      const response = await api.get(
        `/contracts/buscarProjetosDoContrato/${data.entrega.contrato}`
      );
      setProjects(response.data);
      if (
        data.entrega.r2enTxFtpUrl ||
        data.entrega.r2enTxFtpLogin ||
        data.entrega.r2enTxFtpSenha
      ) {
        setEnderecoFtp(true);
      }
      if (data.entrega.r2enTxUrlFonte) {
        setEnderecoIc(true);
      }
      setValue('contrato', data.entrega.contrato);
      setValue('r2enTxTituloEntrega', data.entrega.r2enTxTituloEntrega);
      setValue('client', data.clientId);
      setValue('r2enTxAmbiente', data.entrega.r2enTxAmbiente);
      setValue('r2enTxVersaoEntrega', data.entrega.r2enTxVersaoEntrega);
      setValue('r2teCd', data.entrega.r2teCd);
      setValue('projectId', data.entrega.projectId);
      setValue('r2enDtPrevistaEntrega');
      setValue('r2enTxTituloEntrega', data.entrega.r2enTxTituloEntrega);
      setValue('client', data.clientId);
      setValue('contrato', data.entrega.contrato);
      setValue('r2enTxVersaoEntrega', data.entrega.r2enTxVersaoEntrega);
      setValue('r2teCd', data.entrega.r2teCd);
      setValue('r2enTxAmbiente', data.entrega.r2enTxAmbiente);

      setModel({
        ...data.entrega
      });
    } catch (error) {
      console.error(error);
    }
  }, [id, setValue]);

  const getFiles = useCallback(async () => {
    if (!id) {
      return;
    }

    try {
      const response = await api.get(
        `/r2d2-arquivo-entrega/todos-arquivo-entrega/${id}/${true}`
      );
      setOriginalFiles(response.data);

      if (response.data.length > 0) {
        setUploadArquivo(true);
      }
    } catch (error) {
      console.error(error);
    }
  }, [id]);

  async function loadData() {
    await findPackageById();
    await getFiles();
    setReady(true);
  }

  useEffect(() => {
    loadData();
  }, [id, getFiles]);

  const updateModel = e => {
    setModel({ ...model, [e.target.name]: e.target.value });
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const goTimeline = () => {
    navigate(`/timeline/${model.contrato}/0`);
  };

  const backToList = () => {
    navigate('/pacotes');
  };

  const handleCloseFileModal = () => {
    clearFileErrors();
    setEditFileIndex(-1);
    setFile('');
    setFileDesc('');
    setFileSQL('');
    setOpenModal(false);
  };
  const [filesGCP, setFilesGCP] = useState([]);
  const handleConfirmFileModal = () => {
    if (editFileIndex === -1) {
      setFilesGCP([
        ...filesGCP,
        { file: file, fileDesc: fileDesc, sql: fileSQL }
      ]);
      setFiles([
        ...files,
        {
          r2aeTxNomeArquivo: file.name,
          r2aeTxDescricao: fileDesc,
          sql: fileSQL
        }
      ]);
    } else {
      setFiles(
        files.map((item, index) => {
          return index === editFileIndex
            ? { ...item, r2aeTxDescricao: fileDesc }
            : item;
        })
      );
      setFilesGCP(
        filesGCP.map((item, index) => {
          return index === editFileIndex
            ? { ...item, fileDesc: fileDesc }
            : item;
        })
      );
    }

    handleCloseFileModal();
  };
  function getFolderNameFromPath(filePath) {
    let fileFolder = filePath;
    if (typeof filePath !== 'string') {
      fileFolder = filePath?.r2aeTxNomeArquivo;
    }
    const lastSlashIndex = fileFolder?.lastIndexOf('/');
    if (lastSlashIndex === -1) {
      return fileFolder;
    } else {
      return fileFolder?.substring(lastSlashIndex + 1);
    }
  }

  async function handleRemoveFile(item, index) {
    const fileDelete = await getFolderNameFromPath(item);
    await MySwal.fire({
      icon: 'warning',
      title: 'Deletar arquivo?',
      text: `O arquivo ${fileDelete} será removido`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar'
    }).then(async result => {
      if (result.value === true) {
        setLoadingSaving(true);
        try {
          if (
            id !== undefined &&
            model.arquivos.length > 0 &&
            item.r2aeCdId !== undefined
          ) {
            try {
              await api
                .delete(
                  `/r2d2-arquivo-entrega/delete-arquivo-entrega/${item.r2aeCdId}/`
                )
                .then(async () => {
                  await apiGCP
                    .get(
                      `/gcp/signedUrlForDelete?objectName=r2d2-api/pacote/${item.r2aeTxNomeArquivo}`
                    )
                    .then(async response => {
                      await Axios.delete(response?.data);
                      setFiles(files.filter((fileItem, i) => i !== index));
                      setFilesGCP(
                        filesGCP.filter((fileItem, i) => i !== index)
                      );
                    });
                });
            } catch (error) {
              MySwal.fire({
                icon: 'warning',
                title: 'Erro ao deletar arquivo?',
                text: `O arquivo ${fileDelete} não foi removido`,
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Confirmar'
              });
            } finally {
              setLoadingSaving(false);
            }
          } else {
            setFiles(files.filter((fileItem, i) => i !== index));
            setFilesGCP(filesGCP.filter((fileItem, i) => i !== index));
          }
        } catch (error) {
          MySwal.fire({
            icon: 'warning',
            title: 'Erro ao deletar arquivo?',
            text: `O arquivo ${fileDelete} não foi removido`,
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar'
          });
        } finally {
          setLoadingSaving(false);
        }
      }
    });
  }

  const handleEditFile = index => {
    setFileValue('file', { name: files[index].r2aeTxNomeArquivo, size: 10 });
    setEditFileIndex(index);
    setFileDesc(files[index].r2aeTxDescricao);
    setFileValue('fileDesc', files[index].r2aeTxDescricao);
    setOpenModal(true);
  };

  async function getFilesGCP() {
    await api
      .get(`/r2d2-arquivo-entrega/todos-arquivo-entrega/${id}/${true}`)
      .then(r => {
        setFiles(r.data);
        setUploadArquivo(true);
      });
  }

  useEffect(() => {
    if (id) {
      getFilesGCP();
    }
  }, [id]);

  if (!ready) {
    return (
      <div
        style={{
          padding: 50,
          paddingLeft: 200,
          paddingRight: 200,
          backgroundColor: '#FFF',
          height: '100vh'
        }}
      >
        <Skeleton height={60}></Skeleton>
        <Skeleton height={60}></Skeleton>
        <Skeleton height={60}></Skeleton>
        <Skeleton height={60}></Skeleton>
        <Skeleton height={60}></Skeleton>
        <Skeleton height={60}></Skeleton>
        <Skeleton height={60}></Skeleton>
        <Skeleton height={60}></Skeleton>
      </div>
    );
  }

  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={files} strategy={verticalListSortingStrategy}>
        <Container>
          <Backdrop style={{ zIndex: 1 }} open={loadingSaving}>
            <CircularProgress color="inherit" />
          </Backdrop>

          <DocumentTitle>
            {id ? 'Editar pacote - R2D2' : 'Entregar pacote - R2D2'}
          </DocumentTitle>

          <Dialog
            open={openDialog}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title"></DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description"></DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={backToList}>Ir para lista</Button>
              <Button onClick={goTimeline} autoFocus>
                Ir para timeline
              </Button>
            </DialogActions>
          </Dialog>
          <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={4}>
              <Breadcrumbs pathnames={pathnames} />
              <Typography variant="h5" gutterBottom>
                {pageName}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="flex-end"
              alignItems="center"
            >
              <FormControlLabel
                className={classes.switch}
                control={
                  <Switch
                    checked={model.r2enBlEnviarEmailCliente}
                    onChange={e =>
                      setModel({ ...model, [e.target.name]: e.target.checked })
                    }
                  />
                }
                name="r2enBlEnviarEmailCliente"
                label="Notificar cliente"
              />
              <Tooltip title="Caso a opção não seja marcada o cliente não será notificado, é necessário que você notifique o cliente posteriormente.">
                <InfoIcon sx={{ color: '#2D8DA0' }} fontSize="small" />
              </Tooltip>
            </Stack>
            <Stack>
              <TextField
                {...register('r2enTxTituloEntrega')}
                helperText={errors.r2enTxTituloEntrega?.message}
                error={errors.r2enTxTituloEntrega?.message}
                id="r2enTxTituloEntrega"
                label="Descrição"
                style={{ width: '614px' }}
                onChange={updateModel}
                value={model.r2enTxTituloEntrega}
                name="r2enTxTituloEntrega"
                multiline
                rows={4}
                inputProps={{ maxLength: 300 }}
              />
              <Counter>
                {model.r2enTxTituloEntrega
                  ? model.r2enTxTituloEntrega.length
                  : 0}
                /300
              </Counter>
            </Stack>
            <div>
              <FormControl className={classes.formControl} variant="outlined">
                <InputLabel id="client-label">Cliente</InputLabel>
                <Select
                  labelId="client-label"
                  {...register('client')}
                  error={errors.client?.message}
                  helperText={errors.client?.message}
                  id="client"
                  value={client}
                  onChange={e => findContracts(e.target.value)}
                  name="client"
                  label="Cliente"
                >
                  {clients.map(each => (
                    <MenuItem value={each.id}>{each.name}</MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors.client?.message}</FormHelperText>
              </FormControl>

              <FormControl className={classes.formControl} variant="outlined">
                <InputLabel id="contrato">Contrato</InputLabel>
                <Select
                  {...register('contrato')}
                  error={errors.contrato?.message}
                  helperText={errors.contrato?.message}
                  disabled={noContracts}
                  id="contrato"
                  value={model.contrato}
                  onChange={e => findProjects(e)}
                  name="contrato"
                  label="Contrato"
                  labelId="contrato"
                >
                  {contracts.map(each => (
                    <MenuItem value={each.id}>{each.descricao}</MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors.contrato?.message}</FormHelperText>
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl} variant="outlined">
                <InputLabel id="project-label">Projeto</InputLabel>
                <Select
                  labelId="project-label"
                  {...register('projectId')}
                  error={errors.projectId?.message}
                  helperText={errors.projectId?.message}
                  id="projectId"
                  value={model.projectId}
                  onChange={updateModel}
                  name="projectId"
                  label="Projeto"
                  disabled={noProjects}
                >
                  {projects.map(each => (
                    <MenuItem value={each.id}>{each.description}</MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors.projectId?.message}</FormHelperText>
              </FormControl>

              <TextField
                {...register('r2enTxVersaoEntrega')}
                error={errors.r2enTxVersaoEntrega?.message}
                helperText={errors.r2enTxVersaoEntrega?.message}
                style={{ width: '300px' }}
                className={classes.formControl}
                onChange={updateModel}
                name="r2enTxVersaoEntrega"
                value={model.r2enTxVersaoEntrega}
                id="r2enTxVersaoEntrega"
                label="Versão"
                variant="outlined"
                type="text"
              />
            </div>
            <div>
              <TextField
                {...register('r2enDtPrevistaEntrega')}
                error={errors.r2enDtPrevistaEntrega?.message}
                helperText={errors.r2enDtPrevistaEntrega?.message}
                style={{ width: '300px' }}
                className={classes.formControl}
                onChange={updateModel}
                name="r2enDtPrevistaEntrega"
                id="r2enDtPrevistaEntrega"
                label="Data prevista de entrega"
                value={model.r2enDtPrevistaEntrega}
                type="date"
                defaultValue="date"
                InputLabelProps={{
                  shrink: true
                }}
              />

              <TextField
                {...register('r2enDtEfetivaEntrega')}
                error={errors.r2enDtEfetivaEntrega?.message}
                helperText={errors.r2enDtEfetivaEntrega?.message}
                style={{ width: '300px' }}
                className={classes.formControl}
                onChange={updateModel}
                name="r2enDtEfetivaEntrega"
                id="r2enDtEfetivaEntrega"
                label="Data efetiva de entrega"
                value={model.r2enDtEfetivaEntrega}
                type="date"
                defaultValue="date"
                InputLabelProps={{
                  shrink: true
                }}
              />
            </div>
            <div>
              <FormControl className={classes.formControl} variant="outlined">
                <InputLabel id="r2teCd">Tipo de entrega</InputLabel>
                <Select
                  {...register('r2teCd')}
                  error={errors.r2teCd?.message}
                  helperText={errors.r2teCd?.message}
                  onChange={updateModel}
                  name="r2teCd"
                  labelId="r2teCd"
                  id="r2teCd"
                  value={model.r2teCd}
                  label="Tipo de entrega"
                >
                  {deliveriesTypes.map(each => (
                    <MenuItem value={each.r2teCdId}>{each.r2teTxTipo}</MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors.r2teCd?.message}</FormHelperText>
              </FormControl>
              <FormControl className={classes.formControl} variant="outlined">
                <InputLabel id="r2enTxAmbiente">Tipo de ambiente</InputLabel>
                <Select
                  {...register('r2enTxAmbiente')}
                  error={errors.r2enTxAmbiente?.message}
                  helperText={errors.r2enTxAmbiente?.message}
                  onChange={updateModel}
                  name="r2enTxAmbiente"
                  labelId="r2enTxAmbiente"
                  id="r2enTxAmbiente"
                  value={model.r2enTxAmbiente}
                  label="Tipo de entrega"
                >
                  {ENVIRONMENT_TYPES.map(each => (
                    <MenuItem value={each.value}>{each.label}</MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {errors.r2enTxAmbiente?.message}
                </FormHelperText>
              </FormControl>
            </div>
            <div>
              <TextField
                {...register('r2enTxUrlVideoEvidencia')}
                error={errors.r2enTxUrlVideoEvidencia?.message}
                helperText={errors.r2enTxUrlVideoEvidencia?.message}
                style={{ width: '614px' }}
                className={classes.formControl}
                onChange={updateModel}
                value={model.r2enTxUrlVideoEvidencia}
                name="r2enTxUrlVideoEvidencia"
                id="r2enTxUrlVideoEvidencia"
                label="Video evidência"
                variant="outlined"
                type="text"
              />
            </div>
            <div>
              <TextField
                {...register('r2enTxUrlPlanoImplantacao')}
                error={errors.r2enTxUrlPlanoImplantacao?.message}
                helperText={errors.r2enTxUrlPlanoImplantacao?.message}
                style={{ width: '614px' }}
                className={classes.formControl}
                onChange={updateModel}
                value={model.r2enTxUrlPlanoImplantacao}
                name="r2enTxUrlPlanoImplantacao"
                id="r2enTxUrlPlanoImplantacao"
                label="URL Plano Implantação"
                variant="outlined"
                type="text"
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: '7px'
              }}
            >
              <div
                style={{
                  // borderTopLeftRadius: '4px',
                  // borderBottomLeftRadius: '4px',
                  backgroundColor: '#FFFFFF',
                  border: '1px solid #BDBDBD',
                  padding: '17px',
                  marginRight: '-7px',
                  borderRadius: '4px'
                  // zIndex: '1',
                }}
              >
                {urlGitHub}
              </div>
              <TextField
                {...register('r2enTxUrlPacote')}
                error={errors.r2enTxUrlPacote?.message}
                helperText={errors.r2enTxUrlPacote?.message}
                style={{
                  width: '445px',
                  borderRadius: '0px',
                  borderBottomLeftRadius: '0px',
                  marginLeft: '-4px'
                  // zIndex: '1',
                }}
                className={classes.formControl}
                onChange={updateModel}
                value={model.r2enTxUrlPacote}
                name="r2enTxUrlPacote"
                id="r2enTxUrlPacote"
                label="URL Pacote"
                variant="outlined"
                type="text"
              />
            </div>
            <Stack>
              <TextField
                {...register('r2enTxUrlScriptBanco')}
                error={errors.r2enTxUrlScriptBanco?.message}
                helperText={errors.r2enTxUrlScriptBanco?.message}
                id="r2enTxUrlScriptBanco"
                style={{ width: '614px' }}
                label="Script Banco"
                onChange={updateModel}
                value={model.r2enTxUrlScriptBanco}
                name="r2enTxUrlScriptBanco"
                multiline
                rows={4}
                inputProps={{ maxLength: 500 }}
              />
              <Counter>
                {model.r2enTxUrlScriptBanco
                  ? model.r2enTxUrlScriptBanco.length
                  : 0}
                /500
              </Counter>
            </Stack>
            <Stack>
              <TextField
                {...register('r2enTxObservacaoImplantacao')}
                error={errors.r2enTxObservacaoImplantacao?.message}
                helperText={errors.r2enTxObservacaoImplantacao?.message}
                style={{ width: '614px' }}
                id="r2enTxObservacaoImplantacao"
                label="Observação"
                onChange={updateModel}
                value={model.r2enTxObservacaoImplantacao}
                name="r2enTxObservacaoImplantacao"
                multiline
                rows={4}
                inputProps={{ maxLength: 2000 }}
              />
              <Counter>
                {model.r2enTxObservacaoImplantacao
                  ? model.r2enTxObservacaoImplantacao.length
                  : 0}
                /2000
              </Counter>
            </Stack>
            <div>
              <FormControlLabel
                className={classes.checkbox}
                control={
                  <Checkbox
                    checked={uploadArquivo}
                    onChange={e => {
                      setUploadArquivo(e.target.checked);

                      if (!e.target.checked) {
                        setFiles([]);
                      }
                    }}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Upload de Arquivo"
              />
            </div>

            {uploadArquivo ? (
              <Grow
                in={uploadArquivo}
                style={{ transformOrigin: '0 0 0' }}
                {...(hasFtp ? { timeout: 500 } : {})}
              >
                <div>
                  <Button
                    accept=""
                    variant="contained"
                    component="span"
                    className="upload"
                    onClick={() => {
                      clearFileErrors();
                      setFileValue('file', null);
                      setFileValue('fileDesc', '');
                      setOpenModal(true);
                    }}
                  >
                    Adicionar arquivo
                  </Button>
                  {files.map((item, index) => (
                    <SortableItem
                      packageId={id}
                      getFolderNameFromPath={getFolderNameFromPath}
                      key={item}
                      id={item}
                      handleClickEdit={() => handleEditFile(index)}
                      handleClickDelete={() => handleRemoveFile(item, index)}
                    />
                  ))}
                </div>
              </Grow>
            ) : null}
            <div>
              <FormControlLabel
                className={classes.checkbox}
                control={
                  <Checkbox
                    checked={enderecoFtp}
                    onChange={e => {
                      setEnderecoFtp(e.target.checked);
                    }}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Endereço FTP"
              />
            </div>
            {hasFtp ? (
              <Grow
                in={hasFtp}
                style={{ transformOrigin: '0 0 0' }}
                {...(hasFtp ? { timeout: 500 } : {})}
              >
                <div>
                  <div>
                    <TextField
                      className={classes.formControl}
                      value={model.r2enTxFtpUrl}
                      onChange={updateModel}
                      name="r2enTxFtpUrl"
                      id="outlined-basic"
                      label="URL"
                      variant="outlined"
                      type="text"
                    />
                  </div>
                  <div>
                    <TextField
                      className={classes.formControl}
                      onChange={updateModel}
                      name="r2enTxFtpLogin"
                      value={model.r2enTxFtpLogin}
                      id="outlined-basic"
                      label="Login"
                      variant="outlined"
                      type="text"
                    />
                    <TextField
                      className={classes.formControl}
                      onChange={updateModel}
                      name="r2enTxFtpSenha"
                      value={model.r2enTxFtpSenha}
                      id="outlined-basic"
                      label="Senha"
                      variant="outlined"
                      type="text"
                    />
                  </div>
                </div>
              </Grow>
            ) : null}
            <FormControlLabel
              className={classes.checkbox}
              control={
                <Checkbox
                  checked={enderecoIc}
                  onChange={e => {
                    setEnderecoIc(e.target.checked);
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Endereço IC"
            />
            <Stack spacing={4}>
              {hasIc ? (
                <Grow
                  in={hasIc}
                  style={{ transformOrigin: '0 0 0' }}
                  {...(hasIc ? { timeout: 500 } : {})}
                >
                  <Stack>
                    <TextField
                      style={{ width: '614px' }}
                      id="outlined-multiline-static"
                      label="Endereço IC"
                      onChange={updateModel}
                      value={model.r2enTxUrlFonte}
                      name="r2enTxUrlFonte"
                      multiline
                      rows={4}
                      inputProps={{ maxLength: 2000 }}
                    />
                    <Counter>
                      {model.r2enTxUrlFonte ? model.r2enTxUrlFonte.length : 0}
                      /2000
                    </Counter>
                  </Stack>
                </Grow>
              ) : null}
              <Divider />
              <Tooltip title={`Separe os e-mails por ";" (ponto e vírgula).`}>
                <Stack>
                  <TextField
                    {...register('r2enTxEmails')}
                    error={errors.r2enTxEmails?.message}
                    helperText={errors.r2enTxEmails?.message}
                    id="r2enTxEmails"
                    label="Enviar para"
                    style={{ width: '614px' }}
                    onChange={updateModel}
                    value={model.r2enTxEmails}
                    name="r2enTxEmails"
                    multiline
                    rows={4}
                    inputProps={{ maxLength: 1000 }}
                  />
                  <Counter>
                    {model.r2enTxEmails ? model.r2enTxEmails.length : 0}/1000
                  </Counter>
                </Stack>
              </Tooltip>
            </Stack>
            <ContainerButtons>
              <LoadingButton
                loading={loadingSaving}
                loadingIndicator="Salvando..."
                variant="contained"
                type="submit"
                sx={{ backgroundColor: '#2D8DA0' }}
              >
                Salvar
              </LoadingButton>
            </ContainerButtons>
          </form>

          <Modal
            open={openModal}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <form
              onSubmit={handleSubmitFile(handleConfirmFileModal)}
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 30,
                backgroundColor: '#fff',
                padding: 20,
                borderRadius: 5
              }}
            >
              <div
                style={{
                  display: 'flex'
                }}
              >
                <label
                  htmlFor="contained-button-file"
                  style={{ display: 'flex', alignItems: 'center', gap: 10 }}
                >
                  <TextField
                    disabled={editFileIndex !== -1}
                    {...fileRegister('file')}
                    accept=".txt,.pdf,.jpg,.png,.jpeg,.doc,.xml,.xls,.xlsx,.mp4,.avi"
                    id="contained-button-file"
                    type="file"
                    onChange={e => {
                      setFile(e.target.files[0]);
                      setFileValue('file', e.target.files[0]);
                    }}
                    style={{ display: 'none' }}
                  />
                  {file.name ? (
                    <Tooltip title={file.name}>
                      <Button
                        variant="contained"
                        component="span"
                        className="upload upload-button"
                        disabled={editFileIndex !== -1}
                      >
                        {/* {file?.name || 'Escolher arquivo'} */}
                        {file?.name
                          ? file.name.substring(0, 15) +
                            (file.name.length > 15 ? '...' : '')
                          : 'Escolher arquivo'}
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      variant="contained"
                      component="span"
                      className="upload upload-button"
                      disabled={editFileIndex !== -1}
                    >
                      {/* {file?.name || 'Escolher arquivo'} */}
                      {file?.name
                        ? file.name.substring(0, 15) +
                          (file.name.length > 15 ? '...' : '')
                        : 'Escolher arquivo'}
                    </Button>
                  )}
                </label>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <FormControlLabel
                    className={classes.switch}
                    control={<Switch />}
                    name="r2enBlEnviaNotificacoes"
                    onChange={e => setFileSQL(e.target.value)}
                    label="Arquivo SQL"
                  />
                </Stack>
              </div>
              <p
                style={{
                  color: '#d32f2f',
                  maxWidth: '605px',
                  marginTop: '-30px'
                }}
              >
                {fileErrors.file?.message}
              </p>
              <Stack>
                <TextField
                  {...fileRegister('fileDesc')}
                  error={fileErrors.fileDesc?.message}
                  helperText={fileErrors.fileDesc?.message}
                  style={{ width: '614px' }}
                  label="Descrição"
                  onChange={e => setFileDesc(e.target.value)}
                  value={fileDesc}
                  multiline
                  rows={4}
                  inputProps={{ maxLength: 500 }}
                />
                <Counter>{fileDesc ? fileDesc.length : 0}/500</Counter>
              </Stack>

              <div style={{ display: 'flex', gap: 10, justifyContent: 'end' }}>
                <Button variant="contained" onClick={handleCloseFileModal}>
                  Cancelar
                </Button>
                <Button variant="contained" type="submit">
                  Confirmar
                </Button>
              </div>
            </form>
          </Modal>
        </Container>
      </SortableContext>
    </DndContext>
  );

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active?.id !== over?.id) {
      setFiles(items => {
        const activeIndex = items.indexOf(active?.id);
        const overIndex = items.indexOf(over?.id);

        return arrayMove(items, activeIndex, overIndex);
      });
    }
  }
};
