import styled from 'styled-components';

export const Img = styled.img`
  max-height: 70vh;
`;

export const ContainerModal = styled.div`
  padding: 20px;
  border-radius: 10px;
  background: #eeeeee;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
