import styled from 'styled-components';

export const EmptyContainer = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
  display: ${props => (props.visible ? 'flex' : 'none')};

  #cubo {
    margin-bottom: 40px;
  }

  .text-alert {
    font-size: 24px;
    color: #565656;
    font-family: Montserrat;
  }
`;
