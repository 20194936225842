import styled from 'styled-components';

export const FooterContainer = styled.footer`
  background: ${props => props.backgroundColor};
  padding: 30px;
  color: ${props => props.fontColor};
  display: flex;
  align-items: center;
  justify-content: space-around;
  .box-container {
    display: flex;
    align-items: center;
  }

  .logo-footer {
    width: 150px;
    height: 40px;
  }
`;
