import { Box } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import Grow from '@material-ui/core/Grow';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';
import { Breadcrumbs } from 'components/Breadcrumb';
import { CardPacote } from 'components/CardPacote';
import { DocumentTitle } from 'components/DocumentTitle';
import { EmptyData } from 'components/EmptyData';
import api from 'config/api';
import { AuthContext } from 'context/auth';
import {
  defaultOrder,
  defaultPackageFilter,
  orders,
  packageFilters
} from 'default/packages';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, ContainerCard } from '../../../global/styles';

export const Pacotes = () => {
  const [loading, setLoading] = useState(true);
  const [loadingMore] = useState(false);
  const [data, setData] = useState([]);
  const skeletons = [1, 2, 3, 4, 5, 6];
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [filter, setFilter] = useState(defaultPackageFilter);
  const [order, setOrder] = useState(defaultOrder);
  const [size, setSize] = useState(10);
  const [maxSize, setMaxSize] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const semPacotes = !data.length;
  const noMoreContent = maxSize <= data.length;
  const pathnames = [
    { name: 'Home', path: '/' },
    { name: 'Pacotes', path: '/pacotes' }
  ];

  const getDeliveries = useCallback(async () => {
    setLoading(true);
    try {
      const userId =
        user.profile === 'CUSTOMER'
          ? `workerId=${parseInt(user.userId)}`
          : `contributorId=${user.userId}`;
      const response = await api.get(
        `/r2d2Entregas/getAllByUserId?${userId}&filtro=${filter}&ordem=${order}&size=${size}&keyword=${searchValue}&page=0`
      );
      setMaxSize(response.data.totalElements);
      setData(response.data.content);
    } catch (error) {
      setData([]);
    } finally {
      setLoading(false);
    }
  }, [filter, order, searchValue, size, user]);

  useEffect(() => {
    getDeliveries();
  }, [searchValue, filter, size, order, getDeliveries]);

  const loadMore = () => {
    setSize(data.length + 5);
  };

  return (
    <Box>
      <DocumentTitle>Pacotes - R2D2</DocumentTitle>

      <Stack padding="20px" spacing={4}>
        <Breadcrumbs pathnames={pathnames} />
        <Typography variant="h5" gutterBottom>
          Pacotes
        </Typography>
        <Container>
          <Container align="center">
            <TextField
              id="outlined-basic"
              style={{ width: '300px', backgroundColor: '#fff' }}
              label="Pesquisar"
              variant="outlined"
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
            />
            {user.profile !== 'CUSTOMER' && (
              <Container margin="0 18px">
                <Fab
                  size="medium"
                  color="primary"
                  aria-label="add"
                  onClick={() => navigate('/pacote')}
                >
                  <AddIcon />
                </Fab>
              </Container>
            )}
          </Container>

          <Stack direction="row" spacing={4} alignItems="center">
            <FormControl variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">
                Ordernar por
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={filter}
                onChange={e => setFilter(e.target.value)}
                style={{ width: '300px', backgroundColor: '#fff' }}
                label="Ordernar por"
              >
                {packageFilters.map((each, i) => (
                  <MenuItem value={each.id}>
                    {i === 0 ? <em>{each.name} (Padrão)</em> : <>{each.name}</>}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">
                Crescente ou Decrescente
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={order}
                onChange={e => setOrder(e.target.value)}
                style={{ width: '300px', backgroundColor: '#fff' }}
                label="Crescente ou Decrescente"
              >
                {orders.map((each, i) => (
                  <MenuItem value={each.value}>
                    {i === 0 ? <em>{each.name} (Padrão)</em> : <>{each.name}</>}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Container>

        <EmptyData visible={semPacotes && !loading} message="Sem resultados." />

        {loading ? (
          <ContainerCard>
            {skeletons.map((s, i) => (
              <Skeleton
                key={i}
                className="skeleton-card"
                variant="rect"
                width={350}
                height={150}
              />
            ))}
          </ContainerCard>
        ) : null}

        {!semPacotes && !loading ? (
          <>
            <ContainerCard>
              {data.map((each, index) => {
                return (
                  <Grow
                    in={!loading}
                    style={{ transformOrigin: '0 0 0' }}
                    {...(!loading ? { timeout: 1000 } : {})}
                  >
                    <div className="item">
                      <CardPacote
                        onClickEdit={() => navigate(`/pacote/${each.r2enCdId}`)}
                        onClickTimeline={() => {
                          navigate(
                            `/timeline/${each.projectId}/${each.r2enCdId}`
                          );
                        }}
                        id={each.r2enCdId}
                        title={each.r2enTxVersaoEntrega}
                        description={each.r2enTxTituloEntrega}
                        date={each.r2enDtEfetivaEntrega}
                        typeUser={user?.profile}
                      />
                    </div>
                  </Grow>
                );
              })}
            </ContainerCard>
            <Container justify="center" padding="40px 0px">
              <LoadingButton
                onClick={loadMore}
                loading={loadingMore}
                loadingIndicator="Carregando..."
                variant="outlined"
                disabled={noMoreContent}
              >
                Carregar mais
              </LoadingButton>
            </Container>
          </>
        ) : null}
      </Stack>
    </Box>
  );
};
