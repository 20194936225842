import styled from 'styled-components';

export const CardContainer = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  background: ${props => (props.border ? '#277D8B' : '#fff')};
  color: ${props => (props.border ? '#fff' : '#24292f')};
  width: 300px;
  padding: 16px;
  justify-content: space-between;
  /* box-shadow: 5px 5px 20px #00000029; */
  transition: 0.3s ease-in-out;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    transform: scale(1.02);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }

  .card-header {
    position: relative;
    padding: 0;
    font-size: 16px;
    font-family: Montserrat;
    margin-bottom: 10px;
  }

  .card-icon {
    display: flex;
    flex-direction: column;
    position: absolute;
    gap: 35px;
    right: 15px;
    top: 15px;
  }

  .card-icon:last-child {
    margin-left: 50px;
  }

  .card-body {
    padding: 2px;
    padding-left: 10px;
    text-align: left;
    font-family: Montserrat;
    font-size: 12px;
  }

  .icons-card {
    margin: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .icon-save {
    border: none;
    background: none;
    cursor: pointer;
    color: #6e6e6e;
  }

  .donwload {
    color: #59c959;
  }
`;
