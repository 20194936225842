import styled from 'styled-components';

export const Container = styled.section`
  margin-top: 100px;

  #step-box {
    margin-top: px;
    width: 100%;
    margin-bottom: 20px;
  }

  #form-box {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;

    .info {
      width: 75%;
      margin-bottom: 25px;
      background: #e3e3e3;
      padding: 5px;
      border-radius: 5px;
    }

    .input {
      width: 75%;
    }

    .input-password {
      width: 75%;
      margin-bottom: 4px;
      margin-top: 1.5rem;
    }

    .submit {
      margin-top: 15px;
      width: 120px;
    }

    .cancel {
      margin-top: 15px;
      width: 120px;
      background-color: #be3636;
    }

    .button-container {
      display: flex;
      justify-content: center; /* Centraliza os botões horizontalmente */
      gap: 50px; /* Espaçamento entre os botões */
    }
  }
`;
