import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const AlertsSnackBar = ({
  open,
  handleClose,
  tipoDeaviso,
  mensagem,
  color,
  background
}) => {
  return (
    <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
      <Alert
        onClose={handleClose}
        severity={tipoDeaviso}
        style={{
          width: '100%',
          color: '#fff' || color,
          background: background
        }}
      >
        {mensagem}
      </Alert>
    </Snackbar>
  );
};

//tipos de aviso: error, warning, info, success
//cores: #d32f2f, #ed6c02,#0288d1,#2e7d32
