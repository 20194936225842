import axios from 'axios';
import { encodePassword } from 'services/Login';

const apiLogin = axios.create({
  baseURL: 'https://homologacao.produtos.neki-it.com.br/product-login'
});

export const api = axios.create({
  baseURL: 'https://homologacao.produtos.neki-it.com.br/api'
});

export const apiGCP = axios.create({
  baseURL: 'https://homologacao.produtos.neki-it.com.br/arquivo-nuvem'
});
export const createSession = async (login, password) => {
  const passwordEncode = await encodePassword(password);
  return apiLogin.post('/auth/login', { login, password: passwordEncode });
};

export default api;
