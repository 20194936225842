import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useTheme } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AddIcon from '@mui/icons-material/Add';
import ArticleIcon from '@mui/icons-material/Article';
import GroupsIcon from '@mui/icons-material/Groups';
import HomeIcon from '@mui/icons-material/Home';
// import Inventory2Icon from '@mui/icons-material/Inventory2';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import clsx from 'clsx';
import { Footer } from 'components/Footer';
import { MenuContext } from 'context/menuContext';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useStyles } from 'theme/style';
import { SubMenu } from '../SubMenu';
import { SubMenuOption } from '../SubMenu/SubMenuOption';
import { ProfileSection } from './styles';

export default function Header({ children }) {
  const [openProfile, setOpenProfile] = React.useState(false);
  const navigate = useNavigate();
  const classes = useStyles();
  const theme = useTheme();
  const { open, setOpen, noNavbar } = React.useContext(MenuContext);

  if (noNavbar) {
    return children;
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        style={{ background: '#253F57' }}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Box>
            <ProfileSection>
              <SubMenu
                anchorEl={null}
                open={openProfile}
                handleClose={() => setOpenProfile(false)}
                option={[
                  {
                    id: 1,
                    nome: 'Logout',
                    path: '',
                    icon: <HomeIcon />
                  }
                ].map((option, i) => (
                  <SubMenuOption key={i} option={option} />
                ))}
              />
            </ProfileSection>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <Box className={classes.drawerHeader}>
          <h1 style={{ padding: '10px', color: '#757575' }}>R2D2</h1>

          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </Box>
        <Divider />
        <List>
          {opcaoMenu.map((each, index) =>
            each.permission?.includes(
              JSON.parse(sessionStorage.getItem('login'))?.profile
            ) === true ? (
              <ListItem
                button
                key={each.id}
                onClick={() => navigate(`/${each.path}`)}
              >
                <ListItemIcon>{each.icon}</ListItemIcon>
                <ListItemText primary={each.nome} />
              </ListItem>
            ) : (
              <></>
            )
          )}
        </List>
      </Drawer>
      <Box
        component="main"
        // style={{ height: '100%', justifyContent: 'space-between' }}
        className={clsx(classes.content, {
          [classes.contentShift]: open
        })}
      >
        <Box className={classes.drawerHeader} />
        {children}
        {/* <Box style={{ height: '89vh', overflow: 'auto' }}>{children}</Box> */}

        <Footer backgroundColor="#253F57" fontColor="#eeee" />
      </Box>
    </Box>
  );
}

export const opcaoMenu = [
  {
    id: 1,
    nome: 'Home',
    path: '',
    icon: <HomeIcon />,
    permission: ['CUSTOMER', 'CONTRIBUTOR']
  },
  {
    id: 2,
    nome: 'Cadastrar Entrega',
    path: 'pacote',
    icon: <AddIcon />,
    permission: ['CONTRIBUTOR']
  },
  {
    id: 3,
    nome: 'Meus Projetos',
    path: 'projetos',
    icon: <ArticleIcon />,
    permission: ['CUSTOMER', 'CONTRIBUTOR']
  },
  // {
  //   id: 4,
  //   nome: 'Pacotes',
  //   path: 'pacotes',
  //   icon: <Inventory2Icon />,
  //   permission: ['CUSTOMER', 'CONTRIBUTOR']
  // },
  {
    id: 5,
    nome: 'Funcionários',
    path: 'funcionarios',
    icon: <GroupsIcon />,
    permission: ['CONTRIBUTOR']
  },
  {
    id: 6,
    nome: 'Reembolso',
    path: '/reembolso',
    icon: <GroupsIcon />
  }
];
