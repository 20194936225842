import { Tooltip } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { LoadingButton } from '@mui/lab';
import { Box, Stack, Typography } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Breadcrumbs } from 'components/Breadcrumb';
import { DocumentTitle } from 'components/DocumentTitle';
import { EmptyData } from 'components/EmptyData';
import api from 'config/api';
import { AuthContext } from 'context/auth';
import {
  defaultOrder,
  defaultProjectFilter,
  orders,
  projectFilters
} from 'default/packages';
import { Container, ContainerCard } from 'global/styles';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CardTextTitleQuantity, ContainerImageCard, StyledBox } from './styles';
import TaskIcon from '@mui/icons-material/Task';
import './styles.css';
import { formatDate4 } from 'util/dateFormat';

export const Contratos = () => {
  const [loading, setLoading] = useState(true);
  const skeletons = [1, 2, 3, 4, 5, 6];
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [filter, setFilter] = useState(defaultProjectFilter);
  const [users, setUsers] = useState([]);
  const [userChecked, setUserChecked] = useState(null);
  const [order, setOrder] = useState(defaultOrder);
  const [size, setSize] = useState(10);
  // const [maxSize, setMaxSize] = useState(0);
  const { user } = useContext(AuthContext);
  const noProjects = !data.length;
  // const noMoreContent = maxSize <= data.length;
  const ready = !noProjects && !loading;
  const pathnames = [
    { name: 'Home', path: '/' },
    { name: 'Projetos', path: '/projetos' }
  ];
  const navigate = useNavigate();

  const getClients = async () => {
    try {
      await api.get(`/clients`).then(response => {
        const novoObjeto = { id: '', name: 'Todos (Padrão)' };
        setUsers([novoObjeto, ...response.data]);
      });
    } catch (error) {
      //TODO: error
    }
  };

  useEffect(() => {
    getClients();
    // getClients2();
  }, []);

  const getContracts = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.post(
        `/contracts/view-lista-indicadores-contrato-versao`,
        {
          clientId: userChecked,
          dateCad: null,
          dateAlt: null,
          workerId: user.profile === 'CUSTOMER' ? parseInt(user.userId) : null,
          contributorId: user.profile === 'CONTRIBUTOR' ? user.userId : null,
          description: searchValue,
          ordenarProjeto: filter === 'projectname' ? order : null,
          ordenarCliente: filter === 'clientname' ? order : null,
          ordenarDataUltEntrega: filter === 'ultimaentrega' ? order : null
        }
      );
      // setMaxSize(data.totalElements);
      const array = data.map(each => {
        return {
          ...each,
          image:
            'https://img.freepik.com/fotos-gratis/mapa-na-mesa-de-madeira_53876-95324.jpg'
        };
      });

      setData(array);
    } catch (error) {
      //TODO: error
      setData([]);
    } finally {
      setLoading(false);
    }
  }, [
    searchValue,
    filter,
    size,
    order,
    user.profile,
    user.userId,
    /*selectedUserIds,*/ userChecked
  ]);

  useEffect(() => {
    getContracts();
  }, [
    searchValue,
    filter,
    size,
    order,
    /*selectedUserIds,*/ userChecked,
    getContracts
  ]);

  const loadMore = () => {
    setSize(data.length + 5);
  };

  function levelOfSatisfaction(valor) {
    switch (true) {
      case valor <= 1 && valor <= 1.4:
        return (
          <SentimentVeryDissatisfiedIcon
            color="error"
            style={{
              width: '20px',
              height: '20px',
              marginTop: '-1px',
              marginLeft: '-3px'
            }}
          />
        );
      case valor >= 1.5 && valor < 2.4:
        return (
          <SentimentDissatisfiedIcon
            color="warning"
            style={{
              width: '20px',
              height: '20px',
              marginTop: '-1px',
              marginLeft: '-3px'
            }}
          />
        );
      case valor >= 2.5 && valor < 3.4:
        return (
          <SentimentSatisfiedIcon
            color="info"
            style={{
              width: '20px',
              height: '20px',
              marginTop: '-1px',
              marginLeft: '-3px'
            }}
          />
        );
      case valor >= 3.5 && valor < 4.4:
        return (
          <SentimentSatisfiedAltIcon
            color="primary"
            style={{
              width: '20px',
              height: '20px',
              marginTop: '-1px',
              marginLeft: '-3px'
            }}
          />
        );
      case valor >= 4.5:
        return (
          <SentimentVerySatisfiedIcon
            color="success"
            style={{
              width: '20px',
              height: '20px',
              marginTop: '-1px',
              marginLeft: '-3px'
            }}
          />
        );
      default:
        return null;
    }
  }

  const handleChange = event => {
    const {
      target: { value }
    } = event;
    setUserChecked(value);
  };

  function calcularTotalMeses(primeiraEntrega, ultimaEntrega) {
    const primeiraData = new Date(primeiraEntrega);
    const ultimaData = new Date(ultimaEntrega);

    const diffAnos = ultimaData.getFullYear() - primeiraData.getFullYear();
    const diffMeses = ultimaData.getMonth() - primeiraData.getMonth();

    let mes = diffAnos * 12 + diffMeses > 1 ? 'meses' : 'mês';

    return diffAnos * 12 + diffMeses + ' ' + mes;
  }

  const customerName = user.customerName;

  return (
    <StyledBox>
      <DocumentTitle>Projetos - R2D2</DocumentTitle>

      <Stack spacing={4}>
        <Breadcrumbs pathnames={pathnames} />
        <Typography variant="h5" gutterBottom>
          Projetos
        </Typography>
        <Container align="center">
          <Tooltip title={`Nome do projeto`}>
            {/* <Stack> */}
            <Container align="center">
              <TextField
                id="outlined-basic"
                style={{
                  width: '300px',
                  backgroundColor: '#fff',
                  marginLeft: 0
                }}
                label="Projeto"
                variant="outlined"
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
                // disabled
              />
            </Container>
            {/* </Stack> */}
          </Tooltip>

          <Stack direction="row" spacing={1}>
            {user.profile === 'CONTRIBUTOR' ? (
              <FormControl variant="outlined" sx={{ m: 1, width: 300 }}>
                {userChecked?.length === 0 ||
                userChecked === '' ||
                userChecked === null ? (
                  <InputLabel id="demo-multiple-checkbox-label">
                    Clientes
                  </InputLabel>
                ) : null}
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  // multiple
                  value={userChecked}
                  onChange={handleChange}
                  input={<OutlinedInput />}
                  // label={validateLabel()}
                  // renderValue={(selected) => selected.join(', ')}
                  // renderValue={(selected) => users.find((user) => user.id === selected)}
                  // renderValue={validateLabel()}
                  // MenuProps={{
                  //   getContentAnchorEl: () => null,
                  // }}
                  // style={{ width: '250px', backgroundColor: '#fff' }}
                  className="dropdown"
                >
                  {users?.map(each => (
                    <MenuItem key={each.id} value={each.id}>
                      {/* <Checkbox checked={userChecked?.indexOf(each.name) > -1} /> */}
                      {/* <ListItemText primary={each.name} /> */}
                      <em>{each.name}</em>
                      {/* {<>{each.name}</>} */}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}
            <FormControl variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">
                Ordenar por
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={filter}
                onChange={e => setFilter(e.target.value)}
                className="dropdown"
                label="Ordernar por"
              >
                {projectFilters.map((each, i) => (
                  <MenuItem value={each.value}>
                    {i === 0 ? <em>{each.name} (Padrão)</em> : <>{each.name}</>}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">
                Crescente ou Decrescente
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={order}
                onChange={e => setOrder(e.target.value)}
                className="dropdown"
                label="Crescente ou Decrescente"
              >
                {orders.map((each, i) => (
                  <MenuItem value={each.value}>
                    {i === 0 ? <em>{each.name} (Padrão)</em> : <>{each.name}</>}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Container>

        {ready ? (
          <>
            <ContainerCard>
              {data
                .filter(
                  contrato =>
                    user.profile === 'CONTRIBUTOR' ||
                    contrato.clientname === customerName
                )

                .map((contrato, index) => (
                  <>
                    <section
                      onClick={() =>
                        navigate(`/timeline/${contrato.projectId}/0`)
                      }
                      className="cardProjeto"
                    >
                      <div className="containerDescriptionAll">
                        <div style={{ width: '65%' }}>
                          <h2
                            title="Projeto"
                            style={{
                              color: '#253F57',
                              textTransform: 'uppercase'
                            }}
                          >
                            {contrato?.projectName}
                          </h2>
                          <h3
                            title="Cliente/Contrato"
                            style={{
                              fontSize: '10px',
                              color: '#2D8DA0',
                              marginTop: '4px'
                            }}
                          >
                            {contrato?.description} ({contrato?.clientname})
                          </h3>
                          <h3
                            title="Cliente/Contrato"
                            style={{
                              fontSize: '10px',
                              color: '#2D8DA0',
                              marginTop: '4px'
                            }}
                          >
                            De {formatDate4(contrato?.primeiraentrega)} à{' '}
                            {formatDate4(contrato?.ultimaentrega)} (
                            {calcularTotalMeses(
                              contrato?.primeiraentrega,
                              contrato?.ultimaentrega
                            )}
                            )
                          </h3>

                          <br></br>
                          <div
                            style={{
                              marginLeft: '14px'
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: '10px'
                              }}
                            >
                              <h3
                                title="Entregas"
                                style={{
                                  fontSize: '18px',
                                  color: '#253F57',
                                  fontWeight: 'bold'
                                }}
                              >
                                {contrato?.quantidadeversoes} Pacotes
                              </h3>
                              {levelOfSatisfaction(
                                contrato?.mediamovelentregasultimosseismeses
                              )}
                            </div>
                            <p style={{ color: '#00000' }}>
                              {contrato?.primeiraentrega
                                ? `(1º: ${formatDate4(
                                    contrato?.primeiraentrega
                                  )} Últ.: ${formatDate4(
                                    contrato?.ultimaentrega
                                  )})`
                                : 'Não existente'}
                            </p>
                            <br></br>

                            <CardTextTitleQuantity>
                              <TaskIcon
                                color="success"
                                style={{
                                  width: '12px',
                                  height: '12px',
                                  marginRight: '10px'
                                }}
                              />
                              <b>Não lidas:</b>
                              <p className="textQuantity">
                                {contrato?.quantidadeversoes -
                                  contrato?.qtdversoessim +
                                  contrato?.quantidaderecusas}
                              </p>
                              <b>Aceitas:</b>
                              <p className="textQuantity">
                                {contrato?.qtdversoessim}
                              </p>
                              <b>Rejeitado:</b>
                              <p className="textQuantity">
                                {contrato?.quantidaderecusas}
                              </p>
                            </CardTextTitleQuantity>

                            <CardTextTitleQuantity>
                              <SentimentSatisfiedAltIcon
                                color="primary"
                                style={{
                                  width: '12px',
                                  height: '12px',
                                  marginRight: '10px'
                                }}
                              />
                              <b>Não lidas:</b>
                              <p className="textQuantity">
                                {contrato?.quantidadeversoes -
                                  (contrato?.quantidadeentregascompesquisacompleta +
                                    contrato?.quantidadepesquisasincompletas) -
                                  contrato?.quantidadeentregassempesquisa}
                              </p>
                              <b>Respondida:</b>
                              <p className="textQuantity">
                                {contrato?.quantidadeentregascompesquisacompleta +
                                  contrato?.quantidadepesquisasincompletas}
                              </p>
                              <b>Sem resposta:</b>{' '}
                              <p className="textQuantity">
                                {contrato?.quantidadeentregassempesquisa}
                              </p>
                            </CardTextTitleQuantity>
                          </div>
                        </div>
                        <ContainerImageCard>
                          <Box
                            width={80}
                            borderRadius={20}
                            sx={{ border: '2px solid #2D8DA0' }}
                            padding={0.1}
                            component="img"
                            src={contrato?.avatar}
                          />
                          <h3 className={'nameImage'}>{contrato?.leadName}</h3>
                        </ContainerImageCard>
                      </div>
                    </section>
                  </>
                ))}
            </ContainerCard>
            <Container justify="center">
              <LoadingButton
                onClick={loadMore}
                loading={false}
                loadingIndicator="Carregando..."
                variant="outlined"
                // disabled={noMoreContent}
                disabled={true}
              >
                Carregar mais
              </LoadingButton>
            </Container>
          </>
        ) : null}

        <EmptyData visible={noProjects && !loading} message="Sem contratos." />

        {loading ? (
          <ContainerCard>
            {skeletons.map((s, i) => (
              <Skeleton
                key={i}
                className="skeleton-card"
                variant="rect"
                width={350}
                height={150}
              />
            ))}
          </ContainerCard>
        ) : null}
      </Stack>
    </StyledBox>
  );
};
