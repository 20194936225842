import CircleIcon from '@mui/icons-material/Circle';
import { CardSalvo } from 'components/CardSalvo';
import { formatDate1 } from 'util/dateFormat.js';
import { CardContainer } from './styles.js';

export const TimelineCard = ({
  index,
  borderRadius,
  onClick,
  id,
  title,
  description,
  date,
  date2,
  style,
  typeIconSearch,
  typeIconTerm,
  workerName
}) => {
  return (
    <CardContainer key={index}>
      <div className="card-date-timiline">
        <h4>{date ? formatDate1(date) : '-'}</h4>
      </div>
      <svg className="svg" style={{ borderRadius: borderRadius }} />
      <CircleIcon
        style={{
          color: '#2A8594',
          transform: 'scale(0.70)',
          marginLeft: '-32px'
        }}
      />
      <CardSalvo
        i={index}
        onClick={onClick}
        title={title}
        description={description}
        id={id}
        style={style}
        date={date}
        workerName={workerName}
        typeIconSearch={typeIconSearch}
        typeIconTerm={typeIconTerm}
      />
    </CardContainer>
  );
};
