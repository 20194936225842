import { yupResolver } from '@hookform/resolvers/yup';
import {
  Backdrop,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import api from 'config/api';
import { AuthContext } from 'context/auth';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as yup from 'yup';
import { Container, ContainerButtons, ContainerDatas, Form } from './styles';

const workerValidation = yup.object().shape({
  idContributor: yup.string().required('Campo obrigatório!'),
  idLider: yup.string().required('Campo obrigatório!'),
  tipoLider: yup.string().required('Campo obrigatório!'),
  dataInicio: yup.string().required('Campo obrigatório!')
});

const MySwal = withReactContent(Swal);

export const CadastroLiderTutor = () => {
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [contributors, setContributors] = useState([]);

  const [model, setModel] = useState({
    idContributor: '',
    idLider: '',
    tipoLider: '',
    observacao: '',
    dataInicio: '',
    dataFim: ''
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(workerValidation)
  });

  const handleSubmitForm = () => {
    if (id) {
      handleUpdate();
    } else {
      handlePost();
    }
  };

  const handlePost = () => {
    setLoading(true);

    api
      .post('/liderTutor', {
        ...model,
        dataInicio: model.dataInicio + 'T03:00:00.000Z',
        dataFim: model.dataFim ? model.dataFim + 'T03:00:00.000Z' : '',
        idUsuarioCad: user.userSystemId,
        ativo: true
      })
      .then(() => {
        MySwal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Liderança criada com sucesso'
        }).then(() => {
          navigate('/liderTutor');
        });
      })
      .catch(error => {
        //TODO: error
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUpdate = () => {
    setLoading(true);

    api
      .put(`/liderTutor/${id}`, {
        ...model,
        dataInicio: model.dataInicio + 'T03:00:00.000Z',
        dataFim: model.dataFim ? model.dataFim + 'T03:00:00.000Z' : '',
        idUsuarioAlt: user.userSystemId,
        ativo: true
      })
      .then(() => {
        MySwal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Liderança atualizada com sucesso'
        }).then(() => {
          navigate('/liderTutor');
        });
      })
      .catch(error => {
        //TODO: error
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getContributors = () => {
    api
      .get('/contributor')
      .then(response => {
        setContributors(response.data);
      })
      .catch(error => {
        //TODO: error
      });
  };

  const getLiderTutor = useCallback(async () => {
    api
      .get(`/liderTutor/${id}`)
      .then(response => {
        setModel({
          idContributor: String(response.data.idContributor),
          idLider: String(response.data.idLider),
          tipoLider: response.data.tipoLider,
          observacao: response.data.observacao,
          dataInicio: response.data.dataInicio.split('T')[0],
          dataFim: response.data.dataFim?.split('T')[0],
          idUsuarioCad: response.data.idUsuarioCad
        });

        setValue('idContributor', response.data.idContributor);
        setValue('idLider', response.data.idLider);
        setValue('tipoLider', response.data.tipoLider);
        setValue('dataInicio', response.data.dataInicio.split('T')[0]);
      })
      .catch(error => {
        //TODO: error
      });
  }, [id, setValue]);

  useEffect(() => {
    getContributors();

    if (id) {
      getLiderTutor();
    }
  }, [id, getLiderTutor]);

  return (
    <Container>
      <Backdrop style={{ zIndex: 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Form onSubmit={handleSubmit(handleSubmitForm)}>
        <Typography variant="h5" gutterBottom>
          {id
            ? 'Edição de liderança e tutoria'
            : 'Cadastro de liderança e tutoria'}
        </Typography>

        <FormControl
          fullWidth
          variant="outlined"
          style={{ backgroundColor: '#fff' }}
        >
          <InputLabel id="demo-simple-select-label">Colaborador</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={model.idContributor}
            label="Colaborador"
            {...register('idContributor')}
            error={errors.idContributor?.message ? true : false}
            onChange={e => {
              setModel({ ...model, idContributor: e.target.value });
              if (e.target.value === model.idLider) {
                setModel({
                  ...model,
                  idLider: '',
                  idContributor: e.target.value
                });
                setValue('idLider', '');
              }
            }}
          >
            {contributors.map(each => (
              <MenuItem key={each.id} value={each.id}>
                {each.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText style={{ color: '#d32f2f' }}>
            {errors.idContributor?.message}
          </FormHelperText>
        </FormControl>

        <FormControl
          fullWidth
          variant="outlined"
          style={{ backgroundColor: '#fff' }}
        >
          <InputLabel id="demo-simple-select-label">Líder</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={model.idLider}
            label="Líder"
            {...register('idLider')}
            error={errors.idLider?.message ? true : false}
            onChange={e => setModel({ ...model, idLider: e.target.value })}
          >
            {contributors
              .filter(item => item.id !== model.idContributor)
              .map(each => (
                <MenuItem key={each.id} value={each.id}>
                  {each.name}
                </MenuItem>
              ))}
          </Select>
          <FormHelperText style={{ color: '#d32f2f' }}>
            {errors.idLider?.message}
          </FormHelperText>
        </FormControl>

        <FormControl
          fullWidth
          variant="outlined"
          style={{ backgroundColor: '#fff' }}
        >
          <InputLabel id="demo-simple-select-label">
            Tipo de liderança
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={model.tipoLider}
            label="Tipo de liderança"
            {...register('tipoLider')}
            error={errors.tipoLider?.message ? true : false}
            onChange={e => setModel({ ...model, tipoLider: e.target.value })}
          >
            <MenuItem value="tecnico">Técnico</MenuItem>
            <MenuItem value="projeto">Projeto</MenuItem>
            <MenuItem value="tutor">Tutor</MenuItem>
          </Select>
          <FormHelperText style={{ color: '#d32f2f' }}>
            {errors.tipoLider?.message}
          </FormHelperText>
        </FormControl>

        <TextField
          name="observacao"
          id="observacao"
          style={{ width: '100%', backgroundColor: '#fff', margin: 0 }}
          label="Observação"
          variant="outlined"
          type="text"
          multiline
          minRows={4}
          inputProps={{ maxLength: 250 }}
          value={model.observacao}
          onChange={e => setModel({ ...model, observacao: e.target.value })}
        />

        <ContainerDatas>
          <FormControl fullWidth>
            <TextField
              name="startDate"
              id="startDate"
              style={{ width: '100%', backgroundColor: '#fff', margin: 0 }}
              variant="outlined"
              type="date"
              label={'Data de início'}
              InputLabelProps={{
                shrink: true
              }}
              value={model.dataInicio}
              {...register('dataInicio')}
              error={errors.dataInicio?.message ? true : false}
              helperText={errors.dataInicio?.message}
              onChange={e => setModel({ ...model, dataInicio: e.target.value })}
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              name="endDate"
              id="endDate"
              style={{ width: '100%', backgroundColor: '#fff', margin: 0 }}
              label="Data de fim"
              type="date"
              variant="outlined"
              InputLabelProps={{
                shrink: true
              }}
              value={model.dataFim ? model.dataFim : ''}
              onChange={e => setModel({ ...model, dataFim: e.target.value })}
            />
          </FormControl>
        </ContainerDatas>

        <ContainerButtons>
          <Button
            color="primary"
            variant="contained"
            onClick={() => navigate('/liderTutor')}
          >
            Cancelar
          </Button>

          <LoadingButton type="submit" variant="contained" loading={loading}>
            Confirmar
          </LoadingButton>
        </ContainerButtons>
      </Form>
    </Container>
  );
};
