import { IconButton, Modal } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import Download from '@mui/icons-material/Download';
import api from 'config/api';
import { Container } from 'global/styles';
import { useCallback, useEffect, useState } from 'react';
import { ContainerModal } from './styles';

export const FullscreenModal = ({ open, onClose, img }) => {
  const [url, setUrl] = useState('');

  const getFileUrl = useCallback(() => {
    api
      .get(
        `/r2d2-arquivo-entrega/download-arquivo-entrega-teste/${
          img.title + '.' + img.format
        }`,
        { responseType: 'blob' }
      )
      .then(response => {
        const file = new Blob([response.data], { type: response.data.type });
        setUrl(URL.createObjectURL(file));
      });
  }, [img?.title, img?.format]);

  useEffect(() => {
    if (open) {
      getFileUrl();
    }
  }, [open, getFileUrl]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      BackdropProps={{
        timeout: 500
      }}
    >
      <ContainerModal>
        <Container justify="flex-end" padding="0 0 10px 0">
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Container>

        <iframe
          src={url && `${url}#toolbar=0`}
          width={600}
          height={400}
          title="iframe"
        />

        <Container justify="flex-end" padding="10px">
          <a href={url} download={img?.title + '.' + img?.format}>
            <IconButton>
              <Download />
            </IconButton>
          </a>
        </Container>
      </ContainerModal>
    </Modal>
  );
};
