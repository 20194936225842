import React, { useState, useEffect, useContext } from 'react';
import { ContainerBox } from 'components/ContainerBox';
import { useNavigate, useParams } from 'react-router-dom';
import * as C from './styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import api from 'config/api';
import { MenuContext } from 'context/menuContext';
import { LoadingButton } from '@mui/lab';
import { DocumentTitle } from 'components/DocumentTitle';

const MySwal = withReactContent(Swal);

const steps = ['E-mail enviado', 'Token', 'Troca de senha'];

export const RedefinirSenha = () => {
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [step, setStep] = useState(0);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordRpt, setPasswordRpt] = useState('');
  const { redirectEmail, redirectToken } = useParams();
  const navigate = useNavigate();
  const { setNoNavbar } = useContext(MenuContext);

  const sweetAlerts = () => {
    MySwal.fire({
      icon: 'success',
      title: 'Sucesso',
      text: 'Sua senha foi alterada',
      timer: 2200
    });
  };

  useEffect(() => {
    setNoNavbar(true);
  }, [setNoNavbar]);

  const sendEmail = async () => {
    const data = { emailUsuario: email };
    setLoading(true);
    try {
      await api.post('/email/recuperacao', data);
      setStep(s => s + 1);
      setError('');
    } catch (error) {
      setError(error.response.data.message);
    }
    setLoading(false);
  };

  const sendToken = async (recoveredEmail, recoveredToken) => {
    const data = { email: recoveredEmail, token: recoveredToken };
    setLoading(true);
    try {
      await api.post('/workers/validatePasswordToken', data);
      setError('');
      setStep(2);
    } catch (error) {
      setError(error.response.data.message);
    }
    setLoading(false);
  };

  const updatePassword = async () => {
    const data = {
      email: email !== '' ? email : redirectEmail,
      token: token !== '' ? token : redirectToken,
      newPassword: password
    };
    setLoading(true);
    try {
      await api.put('/workers/passwordReset', data);
      sweetAlerts();
      setTimeout(() => {
        navigate('/');
      }, 2100);
    } catch (error) {
      setError(error.response.data.message);
    }
    setLoading(false);
  };

  const handleSendEmail = e => {
    e.preventDefault();

    sendEmail();
  };

  const handleSendToken = e => {
    e.preventDefault();

    sendToken(email, token);
  };

  const voltarLogin = () => {
    navigate('/login');
  };

  const handleUpdatePassword = e => {
    e.preventDefault();
    if (password !== passwordRpt) {
      setError('As senhas não podem ser diferentes');
      return;
    } else if (password == '' || passwordRpt == '') {
      setError('Campo não pode estar vazio');
    } else {
      updatePassword();
    }
  };

  function handleVisible() {
    setVisible(!visible);
  }

  function handleVisibleRepetirSenha() {
    setVisible2(!visible2);
  }

  useEffect(() => {
    if (redirectEmail && redirectToken) {
      sendToken(redirectEmail, redirectToken);
    }
  }, [redirectEmail, redirectToken]);

  return (
    <ContainerBox title="Redefinir senha">
      <DocumentTitle>Redefinir senha - R2D2</DocumentTitle>
      <C.Container>
        <Box id="step-box">
          <Stepper activeStep={step} alternativeLabel>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        {step === 0 ? (
          <form id="form-box">
            <TextField
              className="input"
              label="E-mail"
              variant="outlined"
              type="email"
              name="email"
              helperText={
                <p style={{ color: 'red', marginLeft: '-15px' }}>{error}</p>
              }
              onChange={e => {
                setEmail(e.target.value);
              }}
              id="inputEmailForgotPassword"
            />
            <div className="button-container">
              <LoadingButton
                variant="contained"
                className="cancel"
                onClick={voltarLogin}
              >
                Cancelar
              </LoadingButton>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={loading}
                className="submit"
                id="buttonSubmitForgotPassword"
                onClick={handleSendEmail}
              >
                Confirmar
              </LoadingButton>
            </div>
          </form>
        ) : null}
        {step === 1 ? (
          <form id="form-box">
            <div className="info">
              <p>Verifique sua caixa de e-mail e insira o token enviado.</p>
            </div>
            <TextField
              id="outlined-basic"
              className="input"
              label="Token"
              variant="outlined"
              type="text"
              name="token"
              helperText={
                <p style={{ color: 'red', marginLeft: '-15px' }}>{error}</p>
              }
              onChange={e => {
                setToken(e.target.value);
              }}
            />
            <div className="button-container">
              <LoadingButton
                variant="contained"
                className="cancel"
                onClick={voltarLogin}
              >
                Cancelar
              </LoadingButton>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={loading}
                className="submit"
                onClick={handleSendToken}
                id="buttonSubmitForgotPassword"
              >
                Confirmar
              </LoadingButton>
            </div>
          </form>
        ) : null}
        {step === 2 ? (
          <form id="form-box">
            <TextField
              className="input"
              label="Insira sua nova senha"
              type={visible ? 'text' : 'password'}
              helperText={
                <p style={{ color: 'red', marginLeft: '-15px' }}>{error}</p>
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleVisible}
                      edge="end"
                    >
                      {visible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              variant="outlined"
              onChange={e => setPassword(e.target.value)}
            />
            <TextField
              className="input-password"
              label="Repita a senha"
              type={visible2 ? 'text' : 'password'}
              helperText={
                <p style={{ color: 'red', marginLeft: '-15px' }}>{error}</p>
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleVisibleRepetirSenha}
                      edge="end"
                    >
                      {visible2 ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              variant="outlined"
              onChange={e => setPasswordRpt(e.target.value)}
            />
            <div className="button-container">
              <LoadingButton
                variant="contained"
                className="cancel"
                onClick={voltarLogin}
              >
                Cancelar
              </LoadingButton>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={loading}
                className="submit"
                onClick={handleUpdatePassword}
                id="buttonSubmitForgotPassword"
              >
                Confirmar
              </LoadingButton>
            </div>
          </form>
        ) : null}
      </C.Container>
    </ContainerBox>
  );
};
