import {
  Collapse,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from '@material-ui/core';
import ArticleIcon from '@mui/icons-material/Article';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { formatDate4 } from 'util/dateFormat';
import { TableWrapper } from './styles';

const MySwal = withReactContent(Swal);

export const Row = props => {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [checked, setChecked] = useState(props.row.isActive === 'true');
  const navigate = useNavigate();

  const disableEnable = () => {
    MySwal.fire({
      icon: 'warning',
      title: checked ? 'Desativar funcionário?' : 'Ativar funcionário?',
      text: checked
        ? 'Os contratos vinculados a esse funcionário serão finalizados'
        : null,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar'
    }).then(result => {
      if (result.value === true) {
        props.handleDisableWorker(row);
        setChecked(!checked);
      }
    });
  };

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.nome}
        </TableCell>
        <TableCell align="center">{row.login}</TableCell>
        <TableCell align="center">{row.funcao}</TableCell>
        <TableCell align="center">{row.cliente}</TableCell>

        <TableCell
          align="right"
          style={{ display: 'flex', alignItems: 'center', gap: 10 }}
        >
          <Tooltip
            title={row.isActive === 'true' ? 'desativar' : 'ativar'}
            arrow
          >
            <Switch
              color="primary"
              checked={checked}
              onChange={disableEnable}
            />
          </Tooltip>

          <Tooltip title="editar contratos" arrow>
            <ArticleIcon
              style={{
                cursor: 'pointer',
                color: 'white',
                backgroundColor: '#2A8594',
                borderRadius: '50%',
                fontSize: 30,
                padding: 5
              }}
              onClick={() => navigate(`/edit/contratos/${row.id}`)}
            />
          </Tooltip>

          <Tooltip title="editar funcionário" arrow>
            <EditIcon
              style={{
                cursor: 'pointer',
                color: 'white',
                backgroundColor: '#2A8594',
                borderRadius: '50%',
                fontSize: 30,
                padding: 5
              }}
              onClick={() => navigate(`/edit/funcionario/${row.id}`)}
            />
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <TableWrapper sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow style={{ backgroundColor: '#fff' }}>
                    <TableCell style={{ fontWeight: 'bold', width: '50%' }}>
                      Projeto
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold', width: '25%' }}>
                      Data de início
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold', width: '25%' }}>
                      Data de fim
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.contratos.map((contratosRow, index) => (
                    <TableRow
                      key={contratosRow.descricao}
                      style={
                        index % 2
                          ? { background: 'white' }
                          : { background: '#EEE' }
                      }
                    >
                      <TableCell>{contratosRow.descricao}</TableCell>
                      <TableCell>
                        {formatDate4(contratosRow.dataInicio)}
                      </TableCell>
                      <TableCell>
                        {formatDate4(contratosRow.dataFim) || '-'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableWrapper>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
